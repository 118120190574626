import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormDialog } from '@opswat/react-ui'
import { useOrganizationCreateSubOrganizationMutation } from 'myopswat-web/src/api/organization'
import { ICreateSubOrganizationForm } from 'myopswat-web/src/api/organization/types'
import useApiErrorHook from 'myopswat-web/src/hooks/useApiErrorHook'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { INVITE_USER_ERROR_MESSAGES } from '../constants'
import { CreateSubOrganizationSchema } from './CreateSubOrganizationSchema'
import CreateSubOrganizationForm from './CreateSubOrganizationForm'
interface IProps {
  organization: any
}

const CreateSubOrganizationModel = NiceModal.create(({ organization }: IProps) => {
  const { t: translate } = useTranslation()
  const [organizationCreateSubOrganization, { isLoading: isCreating }] = useOrganizationCreateSubOrganizationMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { showErrorMessage } = useApiErrorHook({ errorMap: INVITE_USER_ERROR_MESSAGES })
  const modal = useModal()
  const defaultCreateSubOrganization = {
    parentOrganizationId: organization.id,
    name: ''
  }
  const formRef = useForm<ICreateSubOrganizationForm>({
    defaultValues: defaultCreateSubOrganization,
    mode: 'onSubmit',
    resolver: yupResolver(CreateSubOrganizationSchema)
  })
  const { reset, handleSubmit, formState } = formRef

  const { isValid } = formState

  const onSubmit: SubmitHandler<ICreateSubOrganizationForm> = async (formData: ICreateSubOrganizationForm) => {
    try {
      const res = await organizationCreateSubOrganization({
        ...formData
      }).unwrap()
      if (res.success) {
        enqueueSnackbar(translate('createSubOrganizationSuccess'), { variant: 'success' })
        handleResetForm()
        modal.resolve()
        modal.hide()
      } else {
        showErrorMessage(res.errors)
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }
  const onError = (error: any) => {
    console.error('error: ', error)
  }

  const handleResetForm = () => {
    reset(defaultCreateSubOrganization)
  }

  const onCancel = () => {
    modal.hide()
    handleResetForm()
  }

  const isLoading = useMemo(() => {
    return isCreating
  }, [isCreating])

  return (
    <FormDialog
      isLoading={isLoading}
      isDisableSubmit={!isValid}
      title="Create Sub Organization"
      open={modal.visible}
      onClose={() => modal.hide()}
      onExited={() => modal.remove()}
      onCancel={onCancel}
      onConfirm={handleSubmit(onSubmit, onError)}
      confirmBtnLabel="Create"
      minHeight={300}
      maxWidth={'xs'}
      content={<CreateSubOrganizationForm formRef={formRef} organization={organization} />}
    />
  )
})

export default CreateSubOrganizationModel
