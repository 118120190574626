import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ISupportCaseCreateV2Input } from 'myopswat-web/src/api/support/submit-case/types'

import type { RootState } from '../../store'

type SupportServicesState = {
  currentChatbotTopic: string
  submitCaseSuccessful: boolean
  submitCaseData: ISupportCaseCreateV2Input
  isDirtySubmitCase: boolean
  hasInitializedChatbot: boolean
  hasChattedWithChatbot: boolean
  hasShownSubmitCaseMessage: boolean
}

const initialState: SupportServicesState = {
  currentChatbotTopic: '',
  submitCaseSuccessful: false,
  submitCaseData: {} as ISupportCaseCreateV2Input,
  isDirtySubmitCase: false,
  hasInitializedChatbot: false,
  hasChattedWithChatbot: false,
  hasShownSubmitCaseMessage: false
}

const supportServicesSlice = createSlice({
  name: 'supportServices',
  initialState,
  reducers: {
    setCurrentChatbotTopic: (state, action: PayloadAction<string>) => {
      state.currentChatbotTopic = action.payload || ''
    },
    setSubmitCaseSuccessful: (state, action: PayloadAction<boolean>) => {
      state.submitCaseSuccessful = action.payload || false
    },
    setSubmitCaseData: (state, action: PayloadAction<ISupportCaseCreateV2Input>) => {
      state.submitCaseData = action.payload || {}
    },
    setIsDirtySubmitCase: (state, action: PayloadAction<boolean>) => {
      state.isDirtySubmitCase = action.payload || false
    },
    setHasChattedWithChatbot: (state, action: PayloadAction<boolean>) => {
      state.hasChattedWithChatbot = action.payload || false
    },
    setHasShownSubmitCaseMessage: (state, action: PayloadAction<boolean>) => {
      state.hasShownSubmitCaseMessage = action.payload || false
    },
    setHasInitializedChatbot: (state, action: PayloadAction<boolean>) => {
      state.hasInitializedChatbot = action.payload || false
    }
  }
})

export const {
  setCurrentChatbotTopic,
  setSubmitCaseSuccessful,
  setSubmitCaseData,
  setIsDirtySubmitCase,
  setHasInitializedChatbot,
  setHasChattedWithChatbot,
  setHasShownSubmitCaseMessage
} = supportServicesSlice.actions

export default supportServicesSlice.reducer

export const selectCurrentChatbotTopic = (state: RootState) => state.supportServices.currentChatbotTopic
export const selectSubmitCaseSuccessful = (state: RootState) => state.supportServices.submitCaseSuccessful
export const selectSubmitCaseData = (state: RootState) => state.supportServices.submitCaseData
export const selectIsDirtySubmitCase = (state: RootState) => state.supportServices.isDirtySubmitCase
export const selectHasInitializedChatbot = (state: RootState) => state.supportServices.hasInitializedChatbot
export const selectHasChattedWithChatbot = (state: RootState) => state.supportServices.hasChattedWithChatbot
export const selectHasShownSubmitCaseMessage = (state: RootState) => state.supportServices.hasShownSubmitCaseMessage
