import OrgSubOrgPortalRolesView from './views'
import SingleOrgPortalRoleView from './views/singleView'

import _get from 'lodash/get'
import { useCheckPermission } from 'myopswat-web/src/hooks'
import { PERMISSIONS } from '@myopswat/common'

interface IProps {
  permissions: string[]
  organization: any
}

const Roles = ({ permissions, organization }: IProps) => {
  const { hasPermission } = useCheckPermission()
  const hasSubOrgRolesPerm = hasPermission(PERMISSIONS.VIEW_MY_SUBORG_ROLES)
  const hasSubOrganizations = _get(organization, 'ssoMeta.organizationData.subOrganizations', [])

  const hasSubOrgAccessPerm =
    organization.isManageSubOrganization && hasSubOrganizations.length !== 0 && hasSubOrgRolesPerm

  return hasSubOrgAccessPerm ? (
    <OrgSubOrgPortalRolesView permissions={permissions} organization={organization} />
  ) : (
    <SingleOrgPortalRoleView permissions={permissions} organization={organization} />
  )
}

export default Roles
