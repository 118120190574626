import _get from 'lodash/get'
import { PORTAL_ROLES_DEFAULT } from 'myopswat-web/src/constants'
import { useMemo } from 'react'
import { useTypedSelector } from '../store'

export const useUserRoles = (roleMap: any) => {
  const profile = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const getRoleNames = (portalRoleIds: string[]) => {
    if (!portalRoleIds) return []
    return portalRoleIds.map(roleId => _get(roleMap, roleId, '').name)
  }

  const isAdmin = useMemo(
    () => (portalRoleIds: string[]) => getRoleNames(portalRoleIds).includes(`${PORTAL_ROLES_DEFAULT.ADMIN}`),
    [roleMap]
  )

  const isSuperAdmin = useMemo(
    () => (portalRoleIds: string[]) => getRoleNames(portalRoleIds).includes(`${PORTAL_ROLES_DEFAULT.SUPER_ADMIN}`),
    [roleMap]
  )

  const isProfileSuperAdmin = useMemo(() => {
    const profilePortalRoles = _get(profile, 'portalRoleIds', [])
    return getRoleNames(profilePortalRoles).includes(`${PORTAL_ROLES_DEFAULT.SUPER_ADMIN}`)
  }, [profile, roleMap])

  return { isAdmin, isSuperAdmin, isProfileSuperAdmin }
}
