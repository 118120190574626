import { useContext } from 'react'
import { useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Grid, Typography, TypographyLink } from '@opswat/react-ui'

import { supportServicesPageURL } from 'myopswat-web/src/routes'

import { SupportSubmitCaseContext } from '../interface'
import { CircleCheckIcon } from '@opswat/react-icon'

const SubmitCaseSuccessView = () => {
  const navigate = useNavigate()

  const { control, caseId, caseNumber, hasUploadedPackage, handleOpenDialogUpload } =
    useContext(SupportSubmitCaseContext)

  const attachments = useWatch({
    control,
    name: 'attachments'
  })

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box
        sx={{
          maxWidth: '45vw',
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}
      >
        <Typography variant="h3">We've received your case #{caseNumber}</Typography>
        <Typography variant="body2">
          Our dedicated team is currently reviewing your case, and we will be in touch with you very soon. Click&nbsp;
          <TypographyLink
            color="primary"
            fontWeight="500"
            href={`${process.env.REACT_APP_OPSWAT_GO_URL}/s/case/${caseId}`}
          >
            here
          </TypographyLink>
          &nbsp;to check the latest status or to upload larger files.
        </Typography>
        <Typography variant="body2">
          In the meantime, we encourage you to explore our&nbsp;
          <TypographyLink color="primary" fontWeight="500" href={process.env.REACT_APP_OPSWAT_DOCS_URL}>
            OPSWAT Knowledge Center
          </TypographyLink>
          , where you can find additional resources that might help you find answers or solutions.
        </Typography>
        {hasUploadedPackage ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}
          >
            <Typography variant="body2">Attached Support Package:</Typography>
            <Typography variant="body2" lineHeight="30px">
              {attachments.map((attachment: any) => (
                <Box key={attachment.file.name} sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  {attachment.file.name}
                  <CircleCheckIcon size={16} color="#008A00" />
                </Box>
              ))}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body2">
            Would you like to attach the support package for further investigation?
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Button
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            textTransform: 'none'
          }}
          onClick={() => navigate(supportServicesPageURL, { state: { submitSuccessful: true } })}
        >
          Back to Support Home
        </Button>
        <Button
          disableElevation
          variant="outlined"
          color="inherit"
          disabled={hasUploadedPackage}
          onClick={handleOpenDialogUpload}
        >
          Upload Files
        </Button>
      </Box>
    </Grid>
  )
}

export default SubmitCaseSuccessView
