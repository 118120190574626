import _get from 'lodash/get'

import { FC, useContext } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AutocompleteMultiple, Grid, Typography } from '@opswat/react-ui'

import CloudSubOrgSection from './sections/CloudServicesSection'
import ProductsSubOrgSection from './sections/LicensedProductsSection'

import { SubOrgViewContext } from './interface'

const SubOrgLicenseView: FC<any> = () => {
  const { control, organizationList } = useContext(SubOrgViewContext)
  const { t } = useTranslation('common')

  return (
    <Grid item container xs={12} spacing={2.5}>
      <Grid item container xs={6} sx={{ gap: '8px' }}>
        <Typography variant="subtitle1">{t('selectOrganizations')}</Typography>
        <Controller
          name="organizationIds"
          control={control}
          render={({ field }) => (
            <AutocompleteMultiple
              disableCloseOnSelect={false}
              placeholder={t('selectOrganizations')}
              options={organizationList.map((organization: any) => ({
                label: organization.name,
                value: organization.id,
                level: organization.level,
                styles:
                  organization.level === 0
                    ? {
                        color: '#154FBA'
                      }
                    : {}
              }))}
              limitTags={3}
              filterOptions={(options, state) => {
                const keywords = _get(state, 'inputValue', '')
                const filteredOptions = options.filter((option: any) =>
                  _get(option, 'label').toLowerCase().includes(keywords.toLowerCase())
                )
                if (filteredOptions.length === 0) {
                  return filteredOptions
                } else {
                  return [
                    ...options.filter((option: any) => _get(option, 'level') === 0),
                    ...filteredOptions.filter((option: any) => _get(option, 'level') !== 0)
                  ]
                }
              }}
              {...field}
            />
          )}
        />
      </Grid>
      <CloudSubOrgSection />
      <ProductsSubOrgSection />
    </Grid>
  )
}

export default SubOrgLicenseView
