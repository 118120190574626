import _get from 'lodash/get'

import { enqueueSnackbar } from 'notistack'
import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { CloseIcon, ExternalLinkIcon } from '@opswat/react-icon'
import {
  ActionButton,
  DialogAdvanced,
  Grid,
  Link,
  Typography,
  TypographyDivider,
  TypographyLineClamp
} from '@opswat/react-ui'

import {
  useAddOrganizationLicenseNoteMutation,
  useLazyOrganizationLicensedProductsQuery
} from 'myopswat-web/src/api/license'
import { IProductWithLicenseKeysFiltersInput } from 'myopswat-web/src/api/license/types'
import {
  useDownloadedProductAddMutation,
  useLazyIcapIntegrationsQuery,
  useLazyProductQuery
} from 'myopswat-web/src/api/product'
import { IDownloadedProductInput, ILicenseNoteAddInputV2 } from 'myopswat-web/src/api/product/types'
import DialogDownloadVersion from 'myopswat-web/src/components/Dialog/DialogDownloadVersion'
import { PRODUCT_IDS } from 'myopswat-web/src/constants/product-ids'
import BoxCommonDialogNote from 'myopswat-web/src/pages/LicensedProductsPage/CommonProduct/BoxCommonDialogNote'
import { homePageURL } from 'myopswat-web/src/routes'

import NestedSubOrgTable from '../../../components/NestedSubOrgTable'
import TooltipSubOrgLabel from '../../../components/TooltipSubOrgLabel'
import { ALL_VALUE_OPTION } from '../../../constants'
import DialogLicenseHistory from '../../../dialogs/DialogLicenseHistory'
import LicensedProductsSectionFilter from '../table/LicensedProductsFilter'

interface IProps {
  permissions: any
  setCsvFilter: (data: any) => void
}

const LicensedProductsSectionNew: FC<IProps> = ({ permissions, setCsvFilter }) => {
  const { t } = useTranslation()

  const [getLicensedProducts, { isFetching }] = useLazyOrganizationLicensedProductsQuery()
  const [getIcapIntegrations, { data: icapIntegrationsData }] = useLazyIcapIntegrationsQuery()
  const [getProduct, { data: productDetailData, isFetching: isFetchingProductDetail }] = useLazyProductQuery()
  const [downloadedProductAdd] = useDownloadedProductAddMutation()
  const [licenseNoteAdd, licenseNoteAddResponse] = useAddOrganizationLicenseNoteMutation()
  const searchParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search])

  const [chosenItem, setChosenItem] = useState<any>()
  const [openProductDialog, setOpenProductDialog] = useState<boolean>(false)
  const [openNoteDialog, setOpenNoteDialog] = useState<boolean>(false)
  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false)
  const [searchFilters, setSearchFilters] = useState<IProductWithLicenseKeysFiltersInput>({
    q: searchParams.get('q') ?? '',
    productIds: [],
    licenseType: ALL_VALUE_OPTION,
    licenseStatuses: [],
    organizationIds: []
  })
  const [mappedProductsData, setMappedProductsData] = useState<any>({})
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [isMappingData, setIsMappingData] = useState<boolean>(true)

  const handleEditNote = useCallback((item: any) => {
    setChosenItem(item)
    setOpenNoteDialog(true)
  }, [])

  const handleViewHistory = useCallback((item: any) => {
    setChosenItem(item)
    setOpenHistoryDialog(true)
  }, [])

  const handleDownloadProduct = useCallback((item: any) => {
    if (_get(item, 'slug', '')) {
      getProduct(_get(item, 'slug', ''))
      if (_get(item, 'id') === PRODUCT_IDS.MD_ICAP) {
        getIcapIntegrations()
      }
    }
    setChosenItem(item)
    setOpenProductDialog(true)
  }, [])

  const handleDownloadedProductAdd = useCallback(async (data: IDownloadedProductInput, isICAP?: boolean) => {
    try {
      await downloadedProductAdd(data).unwrap()
      if (isICAP) {
        getIcapIntegrations()
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  const handleLicenseNoteAdd = useCallback(
    async (data: ILicenseNoteAddInputV2) => {
      await licenseNoteAdd({ ...data, id: _get(chosenItem, 'id', '') })
        .unwrap()
        .then((response: any) => {
          if (response.success) {
            setOpenNoteDialog(false)
            handleGetData(searchFilters)
            enqueueSnackbar(t('updateLicensesNoteSuccess'), {
              variant: 'success'
            })
          }
        })
        .catch(() => {
          enqueueSnackbar(t('updateLicensesNoteFail'), {
            variant: 'error'
          })
        })
    },
    [chosenItem, searchFilters]
  )

  const handleDownloadCSV = useCallback((data: any) => {
    const params = {
      id: data.id,
      key: data.activation_key
    }

    setCsvFilter?.(params)
  }, [])

  const handleMapLicense = useCallback((item: any): any => {
    const fullLicenses: any[] = _get(item, 'licenses') ?? []
    let licenses: any[] = [...fullLicenses].map((license: any) => ({ ...license, full_licenses: fullLicenses }))
    const children: any[] = _get(item, 'children') ?? []
    const newItem = {
      ...item,
      licenses,
      children: children.map((child: any) => handleMapLicense(child))
    }

    if (licenses.length === 1) {
      return newItem
    } else if (licenses.length > 1) {
      const activeLicenses = licenses.filter(item => _get(item, 'days_to_expire', 0) > 0)
      // if there are no active licenses, only show the most recent expired license
      if (activeLicenses.length === 0) {
        licenses = [...licenses].sort((a: any, b: any) => {
          const expiredDateA = new Date(_get(a, 'expired_date'))
          const expiredDateB = new Date(_get(b, 'expired_date'))
          return expiredDateA.getTime() - expiredDateB.getTime()
        })
        return { ...newItem, licenses: [licenses.at(-1)] }
      }
      // if there are active licenses, show only active licenses
      if (activeLicenses.length >= 1) {
        return { ...newItem, licenses: activeLicenses }
      }
      return newItem
    }
    return newItem
  }, [])

  const handleMapEmptyData = useCallback((item: any, index: number) => {
    const products = _get(item, 'products') ?? []
    const subOrgName = `SUB-ORGANIZATION ${index + 1}`
    if (products.length === 0) {
      return { name: item.name, subOrgName, activation_key: 'There are no licenses to display.' }
    }
    return {
      ...item,
      subOrgName
    }
  }, [])

  const handleCheckEmptyData = useCallback((item: any) => {
    const products = _get(item, 'products') ?? []
    const children = _get(item, 'children') ?? []
    const checkEmptyChildren = children.every((child: any) => handleCheckEmptyData(child))
    return products.length === 0 && checkEmptyChildren
  }, [])

  const handleGetData = useCallback(
    (filters: any) => {
      setIsMappingData(true)
      getLicensedProducts({
        filters: {
          ...filters,
          firstLoad,
          licenseType: filters.licenseType === ALL_VALUE_OPTION ? '' : filters.licenseType
        }
      })
        .unwrap()
        .then((response: any) => {
          setFirstLoad(false)
          setMappedProductsData({
            ...response,
            products: response.products.map((item: any) => handleMapLicense(item)),
            children: [...response.children]
              .map((item: any, index: number) => handleMapEmptyData(item, index))
              .sort((a: any, b: any) => a.name.localeCompare(b.name))
          })
          setIsMappingData(false)
        })
        .catch((error: any) => {
          console.error(error)
          setIsMappingData(false)
        })
    },
    [firstLoad]
  )

  const renderCell = useCallback((data: any, key: string, sx?: CSSProperties) => {
    const value = data[key]
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        tooltipValue={value}
        sx={sx}
      >
        {value || ''}
      </TypographyLineClamp>
    )
  }, [])

  const renderCellNoEllipsis = useCallback((data: any, key: string) => {
    const value = data[key]
    return <Typography variant="body2">{value}</Typography>
  }, [])

  const renderDateCell = useCallback((data: any, key: string) => {
    const value = formatDatetime(data[key], DATE_FORMATS.DATE)
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {value !== '--' && value}
      </TypographyLineClamp>
    )
  }, [])

  const renderOrgNameCell = useCallback((data: any, sx?: CSSProperties) => {
    const orgName = _get(data, 'name')
    const subOrgName = _get(data, 'subOrgName')

    return <TooltipSubOrgLabel mainLabel={subOrgName} subLabel={orgName} sx={sx} />
  }, [])

  const renderExpireDateCell = useCallback((data: any) => {
    const date = _get(data, 'expired_date', '')
    const daysToExpire = _get(data, 'days_to_expire', 0)
    if (!date) return ''
    const renderDate = formatDatetime(date, DATE_FORMATS.DATE)
    const renderExpireDate = ` - ${daysToExpire} days`

    if (renderDate.includes('2100'))
      return (
        <TypographyLineClamp
          line={1}
          variant="body2"
          tooltipValue={'Perpetual'}
          tooltipPlacement="top"
          tooltipProps={{
            tooltip: {
              sx: {
                color: '#1B273C',
                backgroundColor: '#E9EAEB',
                whiteSpace: 'pre-line'
              }
            },
            arrow: {
              sx: {
                color: '#E9EAEB'
              }
            }
          }}
        >
          Perpetual
        </TypographyLineClamp>
      )
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={`${renderDate} ${daysToExpire > 0 ? renderExpireDate : ''}`}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {renderDate}
        {daysToExpire > 0 ? ` - ${daysToExpire} days` : ''}
      </TypographyLineClamp>
    )
  }, [])

  const renderStatusCell = useCallback((data: any) => {
    const value = _get(data, 'status', '')
    const isEvaluation = _get(data, 'is_evaluation', false)
    const daysToExpire = _get(data, 'days_to_expire', 0)

    let color = '#008A00'
    if (value === 'expired') {
      color = '#D00300'
    }
    if (value === 'expiring') {
      if (isEvaluation) {
        color = '#D00300'
      } else if (daysToExpire < 90 && daysToExpire >= 30) {
        color = '#FDBD0D'
      } else {
        color = '#D00300'
      }
    }

    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        textTransform="capitalize"
        style={{ color }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderTypeCell = useCallback((data: any, key: string) => {
    const value = data[key]
    if (value === undefined) {
      return
    }
    const type = value ? 'Evaluation' : 'Purchased'
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {type}
      </TypographyLineClamp>
    )
  }, [])

  const renderDeployedCell = useCallback((data: any, deployedKey: string, purchasedKey: string) => {
    const deployed = data[deployedKey]
    const purchased = data[purchasedKey]
    const value =
      deployed !== undefined && purchased !== undefined
        ? `${deployed}/${purchased === -1 ? 'Unlimited' : purchased}`
        : ''

    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderActionCell = useCallback(
    (data: any) => {
      const licenses = _get(data, 'licenses') ?? []
      const children = _get(data, 'children') ?? []
      const status = _get(data, 'status', '')

      const isChild = _get(data, 'is_child') ?? false // second level product
      const isParent = children.length > 0 || licenses.length > 0 // first level product
      const isExpired = status === 'expired'

      const parentItems = [
        {
          label: 'Download Product Installer',
          onClick: () => handleDownloadProduct(data)
        }
      ]

      const childItems = [
        {
          label: 'See full License History',
          onClick: () => handleViewHistory(data)
        }
      ]

      if (_get(permissions, 'fullLicensePermission', false)) {
        childItems.push({
          label: 'Edit note',
          onClick: () => handleEditNote(data)
        })
        !isExpired &&
          childItems.push({
            label: 'Download Active Deployment Report',
            onClick: () => handleDownloadCSV(data)
          })
      }

      const actionItems = isParent ? parentItems : childItems

      return (
        !isChild &&
        actionItems.length > 0 && (
          <ActionButton
            items={actionItems}
            paperStyles={{
              minWidth: '150px'
            }}
            buttonStyles={{
              padding: '0px'
            }}
          />
        )
      )
    },
    [permissions]
  )

  const productColumns = useMemo(() => {
    return [
      {
        header: 'Product Name',
        style: { minWidth: '20vw', width: '20vw' },
        body: (data: any) =>
          renderOrgNameCell(data, {
            color: _get(data, 'slug') && !_get(data, 'is_child') ? '#1d6dfc' : undefined
          }),
        highlightLevels: [0],
        linkLevels: (data: any) => ['', `${homePageURL}/${_get(data, 'slug')}`]
      },
      {
        header: 'License Key',
        style: { minWidth: '18vw', width: '18vw' },
        body: (data: any) => renderCellNoEllipsis(data, 'activation_key')
      },
      {
        header: 'Status',
        style: {
          minWidth: '5vw',
          width: '5vw',
          textAlign: 'center',
          sx: {
            justifyContent: 'center'
          }
        },
        body: renderStatusCell,
        hiddenLevels: [0]
      },
      {
        header: 'Start Date',
        style: { minWidth: '6vw', width: '6vw' },
        body: (data: any) => renderDateCell(data, 'activated_date'),
        hiddenLevels: [0]
      },
      {
        header: 'Expiration Date',
        style: { minWidth: '10vw', width: '10vw' },
        body: renderExpireDateCell,
        hiddenLevels: [0]
      },
      {
        header: 'Deployed/ Purchased',
        style: {
          minWidth: '5vw',
          width: '5vw',
          textAlign: 'center',
          sx: {
            justifyContent: 'center'
          }
        },
        body: (data: any) => renderDeployedCell(data, 'deployed_quantity', 'purchased_quantity'),
        hiddenLevels: [0]
      },
      {
        header: 'Type',
        style: { minWidth: '5vw', width: '5vw' },
        body: (data: any) => renderTypeCell(data, 'is_evaluation'),
        hiddenLevels: [0]
      },
      {
        header: 'Note',
        style: { minWidth: '9vw', width: '9vw' },
        body: (data: any) => renderCell(data, 'note'),
        hiddenLevels: [0]
      },
      {
        header: '',
        style: { minWidth: 40, textAlign: 'right' },
        body: renderActionCell,
        hiddenLevels: [0]
      }
    ]
  }, [])

  const childrenColumns = useMemo(() => {
    const columns = [...productColumns]
    columns.shift()
    return [
      {
        header: 'Service Name',
        style: { minWidth: '20vw', width: '20vw' },
        body: renderOrgNameCell,
        highlightLevels: [0]
      },
      ...columns
    ]
  }, [productColumns])

  const TableMemo = useMemo(
    () => (
      <NestedSubOrgTable
        defaultExpandedAll
        data={handleCheckEmptyData(mappedProductsData) ? [] : [mappedProductsData]}
        isLoading={isFetching || isMappingData}
        columns={productColumns}
        childrenColumns={childrenColumns}
        childrenKeys={[['products'], ['licenses', 'children'], ['licenses'], ['']]}
        levelPadding={24}
        noDataText={
          <Typography
            textAlign="center"
            variant="subtitle2"
            color="textSecondary"
            sx={{
              my: 2,
              color: '#8E939B',
              lineHeight: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            You need to be part of an authorized organization to access and view Product Licenses.
            <br />
            If you are already part of an organization but still cannot see any licenses, that means the organization
            has not purchased any licenses yet.
            <br />
            For additional details or to inquire about purchasing licenses, please reach out to us via
            <br />
            <Link
              variant="body2"
              target="_blank"
              href="https://www.opswat.com/support/contact-support"
              sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.25 }}
            >
              OPSWAT Support
              <ExternalLinkIcon color="#1D6BFC" />
            </Link>
          </Typography>
        }
      />
    ),
    [mappedProductsData, productColumns, isFetching, isMappingData]
  )

  useEffect(() => {
    handleGetData({ ...searchFilters })
  }, [searchFilters])

  return (
    <Grid item container xs={12}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <TypographyDivider
          label="Products"
          propsTypo={{
            component: 'label',
            variant: 'body1'
          }}
        />
        <LicensedProductsSectionFilter
          currentFilters={searchFilters}
          setCurrentFilters={setSearchFilters}
          handleSearchKeyword={(keyword: string) => {
            setSearchFilters((prev: any) => ({ ...prev, q: keyword.trim() }))
          }}
        />
        {TableMemo}
      </Grid>
      {chosenItem && (
        <DialogDownloadVersion
          icapIntegrationsData={icapIntegrationsData}
          productDetailData={productDetailData}
          isOpenPVersion={openProductDialog}
          setIsOpenPVersion={setOpenProductDialog}
          handleDownloadedProductAdd={handleDownloadedProductAdd}
          isFetchingProductDetail={isFetchingProductDetail}
        />
      )}

      <DialogAdvanced
        open={openNoteDialog}
        onClose={() => setOpenNoteDialog(false)}
        iconClose={<CloseIcon />}
        title={'Note'}
        content={
          <BoxCommonDialogNote
            productId={_get(chosenItem, 'id')}
            licenseKey={_get(chosenItem, 'activation_key')}
            content={_get(chosenItem, 'note') ?? ''}
            isLoadingLicenseNoteAdd={licenseNoteAddResponse.isLoading}
            handleLicenseNoteAdd={handleLicenseNoteAdd}
            setOpenDialog={setOpenNoteDialog}
          />
        }
        dialogProps={{
          maxWidth: 'xs'
        }}
      />

      <DialogLicenseHistory
        orgId={_get(chosenItem, 'organization_id')}
        productId={_get(chosenItem, 'product_id')}
        openHistoryDialog={openHistoryDialog}
        onCloseHistoryDialog={() => {
          setOpenHistoryDialog(false)
          setChosenItem(undefined)
        }}
      />
    </Grid>
  )
}

export default LicensedProductsSectionNew
