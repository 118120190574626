import { ChangeEvent, useMemo } from 'react'

import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form'

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextFieldSearch,
  Typography,
  TypographyLineClamp
} from '@opswat/react-ui'

import { IRole } from 'myopswat-web/src/api/role/types'
import { useTextField } from 'myopswat-web/src/hooks'
import { groupBy, handleGenericSelect } from 'myopswat-web/src/utils'

import { STATUS_OPTIONS } from '../constants'

interface UserFilterContentProps {
  hasStatusFilter?: boolean
  control: Control<any>
  setValue: UseFormSetValue<any>
  watch: UseFormWatch<any>
  roles: IRole[]
}

const UserFilterContent = ({ hasStatusFilter, control, watch, setValue, roles }: UserFilterContentProps) => {
  const { value: roleKeyword, handleChange: handleChangeRoleKeyword, handleClearText } = useTextField()

  const selectedStatus: string[] = watch('status') ?? []
  const portalRoles: string[] = watch('portalRoles') ?? []
  const selectedOrgIds: string[] = (watch('selectedOrgIds') || watch('organizationIds') || []).map(
    (item: any) => item.value
  )

  const filteredRoles = useMemo(() => {
    const filteredByOrg = roles.filter(
      role =>
        selectedOrgIds.length === 0 || role.organizationId === null || selectedOrgIds.includes(role.organizationId)
    )

    const normalizedKeyword = roleKeyword.trim().toLowerCase()

    return filteredByOrg.filter(role => role.name.toLowerCase().includes(normalizedKeyword))
  }, [roleKeyword, roles, selectedOrgIds])

  const groupedRoles = useMemo(() => {
    return groupBy(filteredRoles, 'name', {
      caseInsensitive: true
    })
  }, [filteredRoles])

  return (
    <>
      {hasStatusFilter && (
        <>
          <Controller
            name={'status'}
            control={control}
            render={({ field }) => (
              <>
                <Typography variant="subtitle1" mb={2}>
                  Status
                </Typography>
                <Box
                  sx={{
                    '& .MuiFormControlLabel-root': {
                      marginLeft: 'unset'
                    }
                  }}
                >
                  <Grid container spacing={1.5}>
                    {STATUS_OPTIONS.map((option, index) => (
                      <Grid item xs={12} sm={6} md={4} key={`user-filter-status-${index}`}>
                        <FormControlLabel
                          sx={{
                            '& .MuiCheckbox-root': {
                              paddingLeft: '0px',
                              marginLeft: '0px'
                            }
                          }}
                          control={
                            <Checkbox
                              checked={selectedStatus?.includes(option.value as never)}
                              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                field.onChange(handleGenericSelect(selectedStatus, option.value, event.target.checked))
                              }
                            />
                          }
                          label={<Typography variant="subtitle2">{option.label}</Typography>}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </>
            )}
          />

          <Divider sx={{ my: 2 }} />
        </>
      )}

      <Controller
        name={'portalRoles'}
        control={control}
        render={({ field }) => (
          <>
            <Grid container spacing={1} mb={2} alignItems="center">
              <Grid item xs={1.5}>
                <Typography variant="subtitle1">Role</Typography>
              </Grid>
              <Grid item xs={6.5} gap={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <TextFieldSearch
                  value={roleKeyword}
                  onChange={handleChangeRoleKeyword}
                  onClearText={handleClearText}
                  placeholder={'Search role...'}
                  sx={{
                    minHeight: 'auto'
                  }}
                />
                <Divider
                  sx={{
                    height: '20px',
                    width: '1px',
                    backgroundColor: '#D2D4D6'
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="text"
                  color="inherit"
                  onClick={() => {
                    setValue('portalRoles', [])
                  }}
                >
                  Clear Selected Roles
                </Button>
              </Grid>
            </Grid>
            <Box
              sx={{
                '& .MuiFormControlLabel-root': { marginLeft: 'unset' },
                maxHeight: '200px',
                overflowY: 'auto'
              }}
            >
              <Grid container spacing={1.5}>
                {Object.entries(groupedRoles).map(([groupName, groupRoles]) => {
                  const allGroupRoleIds = groupRoles.map(role => role.id)
                  const isGroupChecked = allGroupRoleIds.some(id => portalRoles.includes(id))

                  return (
                    <Grid item xs={12} sm={6} md={4} key={groupName}>
                      <FormControlLabel
                        sx={{
                          '& .MuiCheckbox-root': {
                            paddingLeft: '0px',
                            marginLeft: '0px'
                          }
                        }}
                        control={
                          <Checkbox
                            checked={isGroupChecked}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              const updatedRoles = handleGenericSelect(
                                portalRoles,
                                allGroupRoleIds,
                                event.target.checked
                              )
                              const selectedRoles = updatedRoles.flatMap(role => (Array.isArray(role) ? role : [role]))
                              field.onChange(selectedRoles)
                            }}
                          />
                        }
                        label={
                          <TypographyLineClamp variant="subtitle2" tooltipValue={groupName} line={1}>
                            {groupName}
                          </TypographyLineClamp>
                        }
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </>
        )}
      />
    </>
  )
}

export default UserFilterContent
