import { FC, useCallback, useEffect, useMemo } from 'react'

import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _map from 'lodash/map'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { CloseIcon } from '@opswat/react-icon'
import {
  Box,
  CircularLoading,
  DialogAdvanced,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@opswat/react-ui'

import { useLazyOrganizationLicenseHistoryQuery } from 'myopswat-web/src/api/license'
import BoxCollapse from 'myopswat-web/src/components/Box/BoxCollapse'
import { useTypedSelector } from 'myopswat-web/src/store'

interface IProps {
  orgId: string
  productId: string
  isParent?: boolean
  hiddenData?: any[]
  openHistoryDialog: boolean
  onCloseHistoryDialog: () => void
}

const DialogLicenseHistory: FC<IProps> = ({
  orgId,
  productId,
  isParent = false,
  hiddenData = [],
  openHistoryDialog,
  onCloseHistoryDialog
}) => {
  const theme = useTheme()
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data) || {}

  const [getLicenseHistory, { data: licenseHistoryData, isFetching: isFetchingLicenseHistory }] =
    useLazyOrganizationLicenseHistoryQuery()

  const licenseKeyEngineData = useMemo(() => {
    return _get(licenseHistoryData, 'engineInfo', [])
  }, [licenseHistoryData])

  const licenseKeyHistoryData = useMemo(() => {
    const children = _get(licenseHistoryData, ['products', 0, 'children'], [])
    if (children.length > 0) {
      return _get(children, [0, 'licenses'], [])
    } else {
      return _get(licenseHistoryData, ['products', 0, 'licenses'], [])
    }
  }, [licenseHistoryData])

  const filteredLicenseKeyHistoryData = useMemo(() => {
    if (!isParent) {
      return licenseKeyHistoryData
    }
    const filteredHiddenData = hiddenData.filter((item: any) => item.product_id === productId)
    return licenseKeyHistoryData.filter(
      (item: any) =>
        !filteredHiddenData.some((filteredItem: any) => {
          return item.activation_key === filteredItem.activation_key && item.rm_id === filteredItem.rm_id
        })
    )
  }, [licenseKeyHistoryData, hiddenData, isParent])

  const productName = useMemo(() => {
    const children = _get(licenseHistoryData, ['products', 0, 'children'], [])
    if (children.length > 0) {
      return _get(children, [0, 'name'], '')
    } else {
      return _get(licenseHistoryData, ['products', 0, 'name'], '')
    }
  }, [licenseHistoryData, productId])

  useEffect(() => {
    productId && orgId && getLicenseHistory({ productId, orgId })
  }, [productId])

  const licenseKeyEngineMap = useMemo(() => {
    if (licenseKeyEngineData) {
      const results: any = {}
      licenseKeyEngineData.forEach((activationItem: any) => {
        let value: any = []
        if (activationItem.type === 'sku' && activationItem.isAllEngine && activationItem.groupedEngines.length === 1) {
          value.push({
            name: 'All Engines',
            description: 'All Engines', // activationItem.groupedEngines[0].description,
            engines: ''
          })
        } else {
          const engineBundles = activationItem.groupedEngines.map((engineBundle: any) => {
            const value = engineBundle.engines.length
              ? `${engineBundle.engines.length} Engine[s]`
              : 'The license has no description found.'
            return {
              name: engineBundle.name,
              description: activationItem.type === 'engine_package' ? engineBundle.description : value,
              engines: engineBundle.engines
            }
          })
          value = engineBundles
          if (activationItem.addedEngines.length > 0) {
            if (activationItem.type === 'sku' && value.length === 1) {
              value[0].engines = value[0].engines.concat(activationItem.addedEngines)
              value[0].engines = value[0].engines.filter(
                (value: any, index: number, self: any) => index === self.findIndex((t: any) => t.name === value.name)
              )
              value[0].description = `${value[0].engines.length} Engine[s] [Custom]`
            } else {
              const addedEngines = [
                {
                  name: 'Custom Engines',
                  description: 'Custom Engines',
                  engines: activationItem.addedEngines
                }
              ]
              value = value.concat(addedEngines)
            }
          }
          value.forEach((engineBundle: any) => {
            engineBundle.engines = engineBundle.engines.map((engine: any) => engine.name).join('; ')
          })
        }
        results[`${activationItem.activationKey}_${activationItem.platform}_${activationItem.activatedDate}`] = value
      })
      return results
    }
    return {}
  }, [licenseKeyEngineData])

  const handleCheckStatus = useCallback((data: any) => {
    const isEvaluation = _get(data, 'evaluation', false)
    const daysToExpire = _get(data, 'days', 0)

    let color = '#008A00'
    let value = 'active'
    if (isEvaluation) {
      if (daysToExpire >= 0 && daysToExpire < 7) {
        value = 'expiring'
        color = '#D00300'
      } else if (daysToExpire < 0) {
        value = 'expired'
        color = '#D00300'
      }
    } else {
      if (daysToExpire >= 30 && daysToExpire < 90) {
        value = 'expiring'
        color = '#FDBD0D'
      } else if (daysToExpire >= 0 && daysToExpire < 30) {
        value = 'expiring'
        color = '#D00300'
      } else if (daysToExpire < 0) {
        value = 'expired'
        color = '#D00300'
      }
    }

    return (
      <Typography variant="body2" textTransform="capitalize" style={{ color }}>
        {value}
      </Typography>
    )
  }, [])

  const handleDeployedPurchased = (deployed: number | string, purchased: number | string) => {
    return (
      <Box display="flex" justifyContent="center">
        <Typography variant="body2">{deployed}</Typography>
        <Typography>/</Typography>
        <Typography variant="body2">{purchased === -1 ? 'Unlimited' : purchased}</Typography>
      </Box>
    )
  }

  const handleExpireDate = (date: string, days: number) => {
    if (!date) return ''
    const renderDate = formatDatetime(date, DATE_FORMATS.DATE)

    if (_includes(renderDate, '2100')) return <Typography variant="body2">Perpetual</Typography>
    return (
      <Typography variant="body2">
        {renderDate}
        {days > 0 ? ` - ${days} days` : ''}
      </Typography>
    )
  }

  return (
    <DialogAdvanced
      open={openHistoryDialog}
      onClose={onCloseHistoryDialog}
      iconClose={<CloseIcon />}
      title={isFetchingLicenseHistory ? 'License History' : `License History - ${productName}`}
      content={
        isFetchingLicenseHistory ? (
          <Box
            sx={{
              minHeight: '100px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularLoading type="large" color="primary" />
          </Box>
        ) : (
          <TableContainer>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      p: 0.25,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      minWidth: {
                        xs: 165,
                        lg: 360
                      },
                      width: { lg: 360 }
                    }}
                  >
                    <Typography>License Key</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0.25,
                      pl: 2,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      minWidth: {
                        xs: 200,
                        lg: 400
                      },
                      width: { lg: 400 }
                    }}
                  >
                    <Typography>Description</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderBottom: `1px solid ${theme.palette.divider}`, minWidth: 120, width: { lg: 120 } }}
                  >
                    <Typography align="center">Status</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0.25,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      minWidth: 160,
                      width: { lg: 160 }
                    }}
                  >
                    <Typography>Activate Time</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0.25,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      minWidth: 90,
                      width: { lg: 150 }
                    }}
                  >
                    <Typography align="center">Deployed/Purchased</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0.25,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      minWidth: 90,
                      width: { lg: 150 }
                    }}
                  >
                    <Typography align="left">Type</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_map(filteredLicenseKeyHistoryData, (item, idx) => (
                  <TableRow
                    key={idx.toString()}
                    sx={{
                      height: 40,
                      minHeight: 40
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0.25,
                        minWidth: {
                          xs: 165,
                          lg: 360
                        },
                        width: { lg: 360 }
                      }}
                    >
                      <Typography variant="body2">{_get(item, 'activation_key', '--')}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        pr: 0.25,
                        py: 1,
                        pl: 1,

                        minWidth: {
                          xs: 200,
                          lg: 400
                        },
                        width: { lg: 400 }
                      }}
                    >
                      {'activation_key' in item &&
                        'platform' in item &&
                        'activated_date' in item &&
                        `${item['activation_key']}_${item['platform']}_${item['activated_date']}` in
                          licenseKeyEngineMap &&
                        _map(
                          _get(
                            licenseKeyEngineMap,
                            `${item['activation_key']}_${item['platform']}_${item['activated_date']}`
                          ),
                          engineBundle =>
                            engineBundle.engines ? (
                              <BoxCollapse
                                defaultOpen={false}
                                title={
                                  <Typography sx={{ width: { lg: 400, md: 300 } }}>
                                    {engineBundle.description}
                                  </Typography>
                                }
                                boxProps={{ sx: { py: 0.25, gap: 0.5 } }}
                                content={
                                  <Box sx={{ pt: 0.25, pb: 1, pl: 2.5 }}>
                                    <Typography variant="body2" sx={{ width: { lg: 400, md: 300 } }}>
                                      {engineBundle.engines}
                                    </Typography>
                                  </Box>
                                }
                              />
                            ) : (
                              <Typography sx={{ width: { lg: 400, md: 300 }, pl: 2.5, py: 0.5 }}>
                                {engineBundle.description}
                              </Typography>
                            )
                        )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        p: 0.25,

                        minWidth: 120,
                        width: { lg: 120 }
                      }}
                    >
                      {handleCheckStatus(item)}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.25,

                        minWidth: 160,
                        width: { lg: 160 }
                      }}
                    >
                      {handleExpireDate(_get(item, 'expired_date'), _get(item, 'days'))}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.25,

                        minWidth: 90,
                        width: { lg: 150 }
                      }}
                    >
                      {handleDeployedPurchased(_get(item, 'deployed_quantity'), _get(item, 'purchased_quantity'))}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        p: 0.25,

                        minWidth: 90,
                        width: { lg: 150 }
                      }}
                    >
                      {item.evaluation ? 'Evaluation' : 'Purchased'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
      dialogProps={{
        maxWidth: 'lg'
      }}
    />
  )
}

export default DialogLicenseHistory
