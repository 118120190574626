import { Grid } from '@mui/material'

import { Box, TableSortLabel } from '@opswat/react-ui'
import { RecursiveTree } from 'myopswat-web/src/pages/MyInformationPage/General/RecursiveTree'
import { IPortalRole } from '../../../../../api/role/types'
import OrgRow from './OrgRow'

interface IProps {
  orgsPortalRoles: any
  onDelete: (selectedPortalRole: IPortalRole) => void
  onDetail: (selectedPortalRoleId: string, organization?: any) => void
  onEdit: (selectedPortalRoleId: string) => void
  permissions: string[]
  order: 'asc' | 'desc'
  onSortChange: (orderBy: string, order?: string) => void
  initialCollapsedNodes?: string[]
}

const OrganizationPortalRolesTree = ({
  orgsPortalRoles,
  onDelete,
  onDetail,
  onEdit,
  permissions,
  onSortChange,
  order,
  initialCollapsedNodes
}: IProps) => {
  const createSortHandler = (orderBy?: string, order?: string) => {
    if (onSortChange && orderBy) {
      onSortChange(orderBy, order)
    }
  }

  return (
    <Box width="100%">
      <Grid
        container
        sx={{
          padding: '10px',
          fontWeight: 'bold',
          borderBottom: '1px solid #E9EAEB'
        }}
      >
        <Grid item xs={2.5}>
          Role Name
        </Grid>
        <Grid item xs pr={2}>
          Description
        </Grid>
        <Grid item xs={1.5} display="flex" justifyContent="center" alignItems="center">
          Number of Users
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="left" alignItems="center">
          Last Updated By
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="left" alignItems="center">
          <TableSortLabel
            active={true}
            direction={order}
            onClick={() => createSortHandler('updatedAt', order === 'desc' ? 'asc' : 'desc')}
            sx={{
              '& .MuiTableSortLabel-icon': {
                color: '#194CA7 !important',
                fontSize: '16px',
                marginLeft: '5px',
                marginBlock: 'auto',
                height: 'fit-content',
                paddingTop: '2px'
              },
              '& .MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionDesc': {
                paddingTop: 0,
                paddingBottom: '2px'
              }
            }}
          >
            Last Updated
          </TableSortLabel>
        </Grid>
        <Grid item xs={0.5}></Grid>
      </Grid>
      <RecursiveTree
        nodes={[orgsPortalRoles]}
        Row={OrgRow}
        initialCollapsedNodes={initialCollapsedNodes}
        externalProps={{
          onDelete,
          onDetail,
          onEdit,
          permissions
        }}
      />
    </Box>
  )
}

export default OrganizationPortalRolesTree
