import { useModal } from '@ebay/nice-modal-react'
import { TableLoading, TemplateSection } from '@opswat/react-ui'
import _get from 'lodash/get'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyNestedOrganizationQuery, useLazyOrganizationPortalRolesQuery } from '../../../../api/organization'
import {
  INestedOrganizationQuery,
  RawNestedOrganizationRoleFilters,
  RawNestedOrganizationRoleFiltersInput
} from '../../../../api/organization/types'

import { useLazyNestedPortalRoleQuery } from 'myopswat-web/src/api/role'
import { IPortalRole } from 'myopswat-web/src/api/role/types'
import { NestedSelectOption } from 'myopswat-web/src/components/NestedOrganizationSelect'
import CreateUpdateRoleModal from '../CreateUpdateRole/CreateUpdateRoleModal'
import DeleteRoleModal from '../DeleteRole/DeleteRoleModal'
import OrganizationPortalRolesTree from './component/OrganizationPortalRolesTree'
import RoleFilter from './component/RoleFilter'

interface IProps {
  permissions: string[]
  organization: any
}

const OrgSubOrgPortalRolesView: FC<any> = ({ permissions, organization }: IProps) => {
  const defaultQuery: RawNestedOrganizationRoleFiltersInput = {
    filters: {
      q: '',
      portalRoles: [],
      organizationIds: []
    },
    sortInfo: {
      order: 'desc',
      orderBy: 'updatedAt'
    }
  }
  const [query, setQuery] = useState<RawNestedOrganizationRoleFiltersInput>(defaultQuery)

  const [getOrganizationTree, { data: organizationTreeData }] = useLazyNestedOrganizationQuery()

  const [getOrganizationPortalRoles, { data, isFetching: isFetchingRoles }] = useLazyOrganizationPortalRolesQuery()

  const refetchOrganizationPortalRolesQuery = async () => {
    await getOrganizationPortalRoles({
      ...query,
      filters: {
        ...query.filters,
        organizationIds: query.filters.organizationIds.map(item => item.value)
      }
    }).unwrap()
  }

  const refetchOrganizationUsersWithQuery = async (newQuery: RawNestedOrganizationRoleFiltersInput) => {
    await getOrganizationPortalRoles({
      ...newQuery,
      filters: {
        ...newQuery.filters,
        organizationIds: newQuery.filters.organizationIds.map(item => item.value)
      }
    }).unwrap()
  }

  const convertToNestedSelectOptions = (tree?: INestedOrganizationQuery, level = 0): NestedSelectOption[] => {
    if (!tree) return []

    let options: NestedSelectOption[] = []
    options.push({
      value: tree.id,
      label: tree.name,
      level: level,
      styles: level === 0 ? { color: '#154FBA' } : {}
    })

    if (tree.children && tree.children.length > 0) {
      tree.children.forEach(child => {
        options = options.concat(convertToNestedSelectOptions(child, level + 1))
      })
    }

    return options
  }

  const nestedOrgs: NestedSelectOption[] = useMemo(() => {
    return convertToNestedSelectOptions(organizationTreeData)
  }, [organizationTreeData])

  const [getNestedPortalRole, { data: roles = [] }] = useLazyNestedPortalRoleQuery()

  const handleMapNameSubOrgData = (item: any, index: number) => {
    const children = _get(item, 'children') ?? []
    const subOrgName = `SUB-ORGANIZATION ${index + 1} (${item.name})`
    return {
      ...item,
      subOrgName: subOrgName,
      children: children.map((child: any, index: number) => handleMapNameSubOrgData(child, index))
    }
  }

  const createUpdateRoleModal = useModal(CreateUpdateRoleModal)
  const deleteRoleModal = useModal(DeleteRoleModal)

  const handleSearch = (searchData: RawNestedOrganizationRoleFilters) => {
    const selectedOrgIds = searchData.organizationIds.map(item => item.value)

    const availableRoles = roles
      .filter(
        (r: any) =>
          selectedOrgIds.length === 0 || r.organizationId === null || selectedOrgIds.includes(r.organizationId)
      )
      .map(item => item.id)

    const portalRoles = searchData.portalRoles.filter(
      role => selectedOrgIds.length === 0 || availableRoles.includes(role)
    )

    const newQuery = { ...query, filters: { ...searchData, portalRoles } }
    setQuery(newQuery)
    refetchOrganizationUsersWithQuery(newQuery)
  }

  const handleClickNew = useCallback(() => {
    createUpdateRoleModal.show({ organization }).then(() => {
      refetchOrganizationPortalRolesQuery()
      getNestedPortalRole(organization?.id)
    })
  }, [organization])

  const handleClickEdit = useCallback(
    (selectedPortalRoleId: string) => {
      createUpdateRoleModal.show({ selectedPortalRoleId: selectedPortalRoleId, organization }).then(() => {
        refetchOrganizationPortalRolesQuery()
        getNestedPortalRole(organization?.id)
      })
    },
    [organization]
  )

  const handleClickDetail = useCallback((selectedPortalRoleId: string, organization: any) => {
    createUpdateRoleModal
      .show({ selectedPortalRoleId: selectedPortalRoleId, viewOnly: true, organization })
      .then(() => {
        refetchOrganizationPortalRolesQuery()
        getNestedPortalRole(organization?.id)
      })
  }, [])

  const handleClickDelete = useCallback(
    (selectedPortalRole: IPortalRole) => {
      if (data?.organizationPortalRoles) {
        deleteRoleModal.show({ selectedPortalRole }).then(() => {
          refetchOrganizationPortalRolesQuery()
          getNestedPortalRole(organization?.id)
        })
      }
    },
    [data]
  )

  const handleSortChange = (orderBy?: any, order?: any) => {
    setQuery(prevState => ({
      ...prevState,
      sortInfo: { order, orderBy }
    }))
  }

  const orgsPortalRoles = useMemo(() => {
    if (data?.organizationPortalRoles) {
      let children = _get(data.organizationPortalRoles, 'children', [])
      children = children.map((item: any, index: number) => handleMapNameSubOrgData(item, index))
      return {
        ...data.organizationPortalRoles,
        children: children
      }
    }
    return {}
  }, [data])

  useEffect(() => {
    getOrganizationTree(organization?.id)
    getNestedPortalRole(organization?.id)
    refetchOrganizationPortalRolesQuery()
  }, [organization])

  const getRoleIdsWithPortalRoles = (nodes: any[]): string[] => {
    return nodes.flatMap(node => {
      const hasPortalRoles = node.portalRoles && node.portalRoles.length > 0

      const childrenWithPortalRoles = node.children ? getRoleIdsWithPortalRoles(node.children) : []

      return hasPortalRoles || childrenWithPortalRoles.length > 0
        ? [node.id, ...childrenWithPortalRoles]
        : childrenWithPortalRoles
    })
  }

  const initialCollapsedNodes = useMemo(() => {
    const { filters } = query
    const { q, organizationIds, portalRoles } = filters

    if (q || portalRoles.length > 0 || organizationIds.length > 0) {
      return getRoleIdsWithPortalRoles([orgsPortalRoles])
    }

    return [organization.id]
  }, [orgsPortalRoles, query])

  return (
    <TemplateSection spacing={2}>
      <RoleFilter
        filters={query.filters}
        portalRoleList={roles}
        nestedOrgs={nestedOrgs}
        onCreateNewRole={handleClickNew}
        onSearch={handleSearch}
      />
      {isFetchingRoles ? (
        <TableLoading />
      ) : (
        <OrganizationPortalRolesTree
          orgsPortalRoles={orgsPortalRoles}
          onDelete={handleClickDelete}
          onEdit={handleClickEdit}
          onDetail={handleClickDetail}
          permissions={permissions}
          order={query.sortInfo.order}
          onSortChange={handleSortChange}
          initialCollapsedNodes={initialCollapsedNodes}
        />
      )}
    </TemplateSection>
  )
}

export default OrgSubOrgPortalRolesView
