import { useMemo } from 'react'

import { useUserProfile } from './useProfileData'

export const useCheckPermission = () => {
  const { getProfileProperty } = useUserProfile()

  const portalPermissions = getProfileProperty<string[]>('portalPermissions', [])

  const hasPermission = useMemo(() => {
    return (permission: string) => portalPermissions.includes(permission)
  }, [portalPermissions])

  const hasAllPermissions = useMemo(() => {
    return (permissions: string[]) => permissions.every(permission => hasPermission(permission))
  }, [hasPermission])

  const hasAnyPermission = useMemo(() => {
    return (permissions: string[]) => permissions.some(permission => hasPermission(permission))
  }, [hasPermission])

  return {
    hasPermission,
    hasAllPermissions,
    hasAnyPermission
  }
}
