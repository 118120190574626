import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import { Button, Divider, DropdownFilter, Grid, TextFieldSearch } from '@opswat/react-ui'

import _get from 'lodash/get'
import _toLower from 'lodash/toLower'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BLOB_FILE_TYPES, handleCreateFile, PERMISSIONS } from '@myopswat/common'

import { useLazyExportNestedOrganizationUsersQuery } from 'myopswat-web/src/api/organization'
import { RawNestedOrganizationUserFilterInput } from 'myopswat-web/src/api/organization/types'
import { IRole } from 'myopswat-web/src/api/role/types'
import { NestedSelect, NestedSelectOption } from 'myopswat-web/src/components/NestedOrganizationSelect'
import { useCheckPermission, useGenericDebouncedHandler } from 'myopswat-web/src/hooks'

import { FilterActions, FilterContent } from '../Filter'

interface IProps {
  organization: any
  roles: IRole[]
  query: RawNestedOrganizationUserFilterInput
  onSearch: (searchData: RawNestedOrganizationUserFilterInput) => void
  onInviteUser: () => void
  onCreateSubOrganization: () => void
  nestedOrgs: NestedSelectOption[]
  disabledCreateSubOrg: boolean
}

const UserFilter: FC<IProps> = ({
  roles,
  query,
  onSearch,
  onInviteUser,
  nestedOrgs,
  onCreateSubOrganization,
  organization,
  disabledCreateSubOrg
}) => {
  const { t } = useTranslation('common')
  const { hasPermission } = useCheckPermission()
  const { enqueueSnackbar } = useSnackbar()
  const hasViewOrgUsersPerm = hasPermission(PERMISSIONS.VIEW_MY_ORGANIZATIONS_USERS)
  const hasFullOrgUsersPerm = hasPermission(PERMISSIONS.FULL_MY_ORGANIZATIONS_USERS)
  const hasFullSubOrgUsersPerm = hasPermission(PERMISSIONS.FULL_MY_SUBORG_USERS)

  const [key, setKey] = useState(0)

  const { control, reset, setValue, getValues, watch } = useForm<RawNestedOrganizationUserFilterInput>({
    mode: 'onSubmit',
    defaultValues: query,
    values: query
  })

  const [getOrgUsersExcelExport, { isFetching: isFetchingExcelData }] = useLazyExportNestedOrganizationUsersQuery()

  const { handleInputChange, handleClearText } = useGenericDebouncedHandler<RawNestedOrganizationUserFilterInput>(
    onSearch,
    getValues,
    setValue
  )

  const q = watch('q')

  const handleExportCasesToExcel = useCallback(() => {
    getOrgUsersExcelExport({
      ...query,
      selectedOrgIds: query.selectedOrgIds.map((item: any) => item.value),
      currentOrgId: organization?.id
    })
      .unwrap()
      .then((response: any) => {
        enqueueSnackbar('Successfully downloaded excel file.', {
          variant: 'success'
        })
        handleCreateFile(
          _get(response, 'filename', 'My OPSWAT_User Management'),
          'xlsx',
          _get(response, 'content', ''),
          {
            type: BLOB_FILE_TYPES.EXCEL
          }
        )
      })
      .catch((response: any) => {
        const error = _get(response, 'errors.0')
        const errorStatusCode = error ? _get(error, 'statusCode') : null
        const errorCode = error ? _get(error, 'code') : null

        // Not show toast if permission denied as permission denied is handled by A Modal at base-query/graphql
        if (errorStatusCode === 403 && _toLower(errorCode) === 'permission_denied') {
          return
        }

        enqueueSnackbar('Failed to download excel file.', {
          variant: 'error'
        })
      })
  }, [query])

  const handleResetFilter = useCallback(() => {
    setValue('status', [])
    setValue('portalRoles', [])
  }, [setValue])

  const handleResetChange = useCallback(() => {
    reset({ ...query, q })
  }, [reset, query, q])

  const handleApplyFilter = useCallback(() => {
    onSearch(getValues())
  }, [onSearch, getValues])

  const handleChange = (newValue: NestedSelectOption[]) => {
    setValue('portalRoles', query.portalRoles)
    setKey(prevKey => prevKey + 1)

    onSearch({
      ...getValues(),
      selectedOrgIds: newValue,
      portalRoles: query.portalRoles
    })
  }

  return (
    <Grid container spacing={4}>
      <Grid item container xs={12}>
        <Grid item xs={12} md={6}>
          <NestedSelect
            control={control}
            name="selectedOrgIds"
            label={t('selectOrganizations')}
            options={nestedOrgs}
            placeholder={t('selectOrganizations')}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        <Grid item container xs={12} md={6}>
          <Grid item xs={6}>
            <Controller
              name="q"
              control={control}
              render={(cProps: any) => (
                <TextFieldSearch
                  sx={{ minHeight: 'unset' }}
                  placeholder={'Search by Full Name or Email'}
                  value={cProps.field.value}
                  onChange={handleInputChange('q')}
                  onClearText={handleClearText('q')}
                />
              )}
            />
          </Grid>

          <Divider
            orientation="vertical"
            sx={{
              height: '50%',
              borderColor: '#E0E0E0',
              margin: 'auto 8px'
            }}
          />

          <Grid item>
            <DropdownFilter
              key={key}
              propsPopper={{
                placement: 'bottom-start'
              }}
              propsPaper={{
                style: {
                  width: '600px'
                }
              }}
              button={
                <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
                  Filter
                </Button>
              }
              content={
                <FilterContent hasStatusFilter control={control} roles={roles} setValue={setValue} watch={watch} />
              }
              onResetFilter={handleResetFilter}
              onResetChange={handleResetChange}
              onApply={handleApplyFilter}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6} justifyContent="flex-end" spacing={2}>
          <FilterActions
            hasExportPermission
            hasCreateOrganizationPermission={(hasViewOrgUsersPerm || hasFullOrgUsersPerm) && hasFullSubOrgUsersPerm}
            hasInviteUserPermission={hasFullOrgUsersPerm}
            isDisabledCreateSubOrg={disabledCreateSubOrg}
            isFetchingExcelData={isFetchingExcelData}
            onExportCasesToExcel={handleExportCasesToExcel}
            onCreateSubOrganization={onCreateSubOrganization}
            onInviteUser={onInviteUser}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UserFilter
