import { useCallback } from 'react'

import { SolidRightIcon } from '@opswat/react-icon'
import { Box, Radio, TypographyLineClamp } from '@opswat/react-ui'

import { RowProps } from './RecursiveTree'

export const OrgRow = <T,>({ node, mode, onToggle, level, collapsed }: RowProps<T>) => {
  const isRoot = level === 0
  const isEditMode = mode === 'edit'
  const hasChildren = node.children && node.children.length > 0

  const handleToggle = useCallback(() => {
    if (hasChildren) onToggle()
  }, [hasChildren, onToggle])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '0 10px',
        background: isRoot ? '#F1F3F8' : 'unset',
        borderBottom: isRoot ? '1px solid #E9EAEB' : 'unset'
      }}
    >
      <Radio size="small" disabled={!isEditMode} value={node.id} />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          overflow: 'hidden',
          paddingLeft: `${level * 20}px`,
          cursor: hasChildren ? 'pointer' : 'default',
          '&:hover': {
            opacity: hasChildren ? 0.8 : 1
          }
        }}
        onClick={handleToggle}
      >
        <Box sx={{ width: '20px', minWidth: '20px' }}>
          {hasChildren && (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                transform: `rotate(${collapsed ? 0 : 90}deg)`,
                transition: 'transform 0.15s ease'
              }}
            >
              <SolidRightIcon size={20} />
            </Box>
          )}
        </Box>

        <TypographyLineClamp variant="body2" line={1} tooltipValue={node.name} color={'#1B273C'} fontWeight={500}>
          {node.name}
        </TypographyLineClamp>
      </Box>
    </Box>
  )
}
