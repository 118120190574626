import { Autocomplete, Box, Grid, TextField, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import { OrganizationInviteUserForm } from 'myopswat-web/src/api/organization/types'
import { Controller, UseFormReturn } from 'react-hook-form'
interface IProps {
  formRef: UseFormReturn<OrganizationInviteUserForm>
  roleMap: any
  organization: any
}

const InviteUserForm = ({ formRef, roleMap, organization }: IProps) => {
  const {
    control,
    register,
    formState: { errors }
  } = formRef

  return (
    <Grid container spacing={2} marginTop={0}>
      <Grid item xs={12}>
        <Typography variant="body2" marginBottom={0.5}>
          You are inviting user into current organization <strong>{_get(organization, 'name', '--')}</strong>.
        </Typography>
        <Typography variant="body2">
          To invite this user to a different organization, please switch your current organization first.
        </Typography>
      </Grid>

      <Grid item xs={12} marginTop={2}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1" marginBottom={0.5}>
            Organization <span style={{ color: '#D00300' }}>*</span>
          </Typography>
          <TextField fullWidth size="small" value={_get(organization, 'name', '--')} disabled />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1" marginBottom={0.5}>
            Assign Role<span style={{ color: '#D00300' }}>*</span>
          </Typography>
          <Controller
            name={`role`}
            control={control}
            render={({ field }) => (
              <Autocomplete
                disableClearable
                options={Object.keys(roleMap)}
                getOptionLabel={(option: any) => {
                  return _get(roleMap, `${option}.name`, '')
                }}
                value={field.value!}
                onChange={(_event: any, newValue: any) => {
                  field.onChange(newValue)
                }}
                isOptionEqualToValue={(option, value) => option == value}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    error={!!errors?.role}
                    helperText={errors?.role && errors?.role.message}
                    placeholder="Select Role"
                  />
                )}
              />
            )}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1" marginBottom={0.5}>
            User Email<span style={{ color: '#D00300' }}>*</span>
          </Typography>
          <TextField
            required
            fullWidth
            size="small"
            {...register(`email`)}
            error={!!errors?.email}
            helperText={errors?.email && errors?.email?.message}
            placeholder="Enter Email"
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default InviteUserForm
