import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime, humanFileSize } from '@opswat/react-core'
import {
  Box,
  Chip,
  TableAdvanced,
  TemplateSection,
  Tooltip,
  Typography,
  TypographyLinkClamp,
  TypographyTooltipClamp
} from '@opswat/react-ui'
import _isEmpty from 'lodash/isEmpty'
import { useFalseSubmissionsQuery } from 'myopswat-web/src/api/analyzer/falseSubmission'
import {
  FALSE_SUBMISSION_STATUS_STYLE,
  FalseSubmission,
  FalseSubmissionCategoryEnum,
  FalseSubmissionStatusEnum,
  IFalseSubmissionFilter,
  IFalseSubmissionFilterInput
} from 'myopswat-web/src/api/analyzer/falseSubmission/types'
import { PAGE_DEFAULT, PAGE_SIZES_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-web/src/constants'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import FalseSubmissionFilter from './FalseSubmissionFilter'

const FalseSubmissionHistoryTab: FC<unknown> = () => {
  const [query, setQuery] = useState<IFalseSubmissionFilterInput>({
    filters: {
      categories: [],
      validities: [],
      cxStatuses: [],
      inputTypes: []
    },
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })

  const containerRef = useRef<any>()

  const { data: falseSubmissionData, isFetching: isFetchingFalseSubmissions } = useFalseSubmissionsQuery(query, {
    refetchOnMountOrArgChange: true
  })

  const falseSubmissions = useMemo(() => {
    if (falseSubmissionData) {
      return falseSubmissionData.results
    }
    return []
  }, [falseSubmissionData])

  const totalCount = useMemo(() => {
    if (_isEmpty(falseSubmissionData)) {
      return 0
    }
    return falseSubmissionData.totalCount
  }, [falseSubmissionData])

  const getColumnWidth = useCallback(
    (widthPercent: number) => {
      const currentWidth = containerRef.current?.offsetWidth
      return {
        minWidth: `${(widthPercent / 100) * currentWidth}px`,
        width: `${(widthPercent / 100) * currentWidth}px`
      }
    },
    [containerRef.current?.offsetWidth]
  )

  const handleRenderStatus = (data: FalseSubmission) => {
    const statusValue = FalseSubmissionStatusEnum[data.cxStatus]

    return (
      <Box display="flex" justifyContent="center">
        <TypographyTooltipClamp
          line={1}
          tooltipValue={statusValue}
          sx={{
            ...FALSE_SUBMISSION_STATUS_STYLE[data.cxStatus],
            padding: '0px 4px',
            width: 'fit-content',
            fontSize: '12px',
            fontWeight: 500,
            display: 'inline-block'
          }}
        >
          {statusValue}
        </TypographyTooltipClamp>
      </Box>
    )
  }

  const handleRenderSupportCaseNo = (data: FalseSubmission) =>
    data.caseNumber ? (
      <TypographyLinkClamp
        line={2}
        color="primary"
        variant="body2"
        sx={{ cursor: 'pointer', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
        href={`${process.env.REACT_APP_OPSWAT_GO_URL}/s/case/${data.caseId}`}
        typographyProps={{
          tooltipValue: data.caseNumber,
          tooltipProps: {
            tooltip: {
              sx: {
                color: '#1B273C',
                backgroundColor: '#E9EAEB'
              }
            },
            arrow: {
              sx: {
                color: '#E9EAEB'
              }
            }
          },
          tooltipPlacement: 'top'
        }}
      >
        {`#${data.caseNumber}`}
      </TypographyLinkClamp>
    ) : (
      <Typography variant="body2">--</Typography>
    )

  const columns = useMemo(() => {
    return [
      {
        header: 'Category',
        body: (data: FalseSubmission) => (
          <Typography variant="body2">{FalseSubmissionCategoryEnum[data.category]}</Typography>
        ),
        style: getColumnWidth(10)
      },
      {
        header: 'Support Case No.',
        body: handleRenderSupportCaseNo,
        style: getColumnWidth(10)
      },
      {
        header: 'File Name / Hash',
        body: (data: any) => (
          <>
            <Typography
              color="primary"
              sx={{
                overflowWrap: 'anywhere'
              }}
              variant="body2"
            >
              {data.inputType === 'file' ? data.filename : data.hash || '--'}
            </Typography>
            {data.inputType === 'file' && (
              <>
                <Chip
                  label="File"
                  color="default"
                  size="small"
                  sx={{ mt: 0.5, backgroundColor: 'rgba(0, 0, 0, 0.08)', color: '#3D4A68', borderRadius: '16px' }}
                />
                <Chip
                  label={humanFileSize(data.size)}
                  color="default"
                  size="small"
                  sx={{
                    mt: 0.5,
                    ml: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    color: '#3D4A68',
                    borderRadius: '16px'
                  }}
                />
              </>
            )}
            {data.inputType === 'hash' && (
              <Chip
                label="Hash"
                variant="outlined"
                color="error"
                size="small"
                sx={{ mt: 0.5, backgroundColor: 'transparent', color: '#FB211E', borderRadius: '16px' }}
              />
            )}
          </>
        ),
        style: getColumnWidth(10)
      },
      {
        header: 'Hash',
        body: (data: any) => {
          const tooltipTitle = `MD5: ${data.md5}
          SHA1: ${data.sha1}
          SHA256: ${data.sha256}`
          return (
            <>
              {data.inputType === 'file' && (
                <Tooltip
                  title={tooltipTitle}
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: '500px',
                        whiteSpace: 'pre-line'
                      }
                    }
                  }}
                >
                  <Typography display="inline-block" variant="body2">
                    SHA256: {data.sha256.slice(0, 5)}...{data.sha256.slice(data.sha256.length - 5, data.sha256.length)}
                  </Typography>
                </Tooltip>
              )}
              {data.inputType === 'hash' && (
                <Typography sx={{ wordBreak: 'break-all' }} variant="body2">
                  {data.hash}
                </Typography>
              )}
            </>
          )
        },
        style: getColumnWidth(12)
      },
      {
        header: 'Products',
        body: (data: any) => (
          <Typography variant="body2">{data?.productNames ? data?.productNames.join(', ') : '--'}</Typography>
        ),
        style: getColumnWidth(20)
      },
      {
        header: 'Status',
        body: handleRenderStatus,
        style: { ...getColumnWidth(10), textAlign: 'center' }
      },
      {
        header: 'Submitted At',
        body: (data: any) => (
          <Typography variant="body2">{formatDatetime(data?.createdAt, DATE_FORMATS.DATE_TIME)}</Typography>
        ),
        style: getColumnWidth(10)
      },
      {
        header: 'Last Updated',
        body: (data: any) => (
          <Typography variant="body2">{formatDatetime(data?.updatedAt, DATE_FORMATS.DATE_TIME)}</Typography>
        ),
        style: getColumnWidth(10)
      },
      {
        header: '',
        body: (data: any) => <></>,
        style: { width: '100%', minWidth: 'auto' }
      }
    ]
  }, [getColumnWidth])

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery((statePrev: any) => Object.assign({}, statePrev, { pageInfo: { page: page, pageSize: pageSize } }))
  }

  const handleSearch = (searchData: IFalseSubmissionFilter) => {
    setQuery(prev => ({ ...prev, filters: searchData }))
  }

  return (
    <TemplateSection>
      <Box ref={containerRef}>
        <FalseSubmissionFilter filters={query.filters} onSearch={handleSearch} />
        <TableAdvanced
          columns={columns}
          isLoading={isFetchingFalseSubmissions}
          data={falseSubmissions}
          isPagination
          total={totalCount}
          page={query.pageInfo.page}
          pageSize={query.pageInfo.pageSize}
          pageSizes={PAGE_SIZES_DEFAULT}
          onPageChange={handlePaginationOnChange}
        />
      </Box>
    </TemplateSection>
  )
}
export default FalseSubmissionHistoryTab
