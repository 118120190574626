import _find from 'lodash/find'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _startCase from 'lodash/startCase'

import { enqueueSnackbar } from 'notistack'
import { CSSProperties, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'

import { PinIcon, WarningIcon } from '@opswat/react-icon'
import { ActionButton, Box, Grid, Tooltip, Typography, TypographyDivider, TypographyLineClamp } from '@opswat/react-ui'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { IOrganizationCloudServiceFilterInput } from 'myopswat-web/src/api/license/types'

import { useLazyOrganizationCloudProductsQuery, usePinUnpinCloudProductsMutation } from 'myopswat-web/src/api/license'
import { selectOrganizations } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { homePageURL } from 'myopswat-web/src/routes'
import { useTypedSelector } from 'myopswat-web/src/store'

import { PRODUCT_IDS } from 'myopswat-web/src/constants/product-ids'
import CustomerContainerTitle from 'myopswat-web/src/pages/LicensedProductsPage/CustomerLicensesPage/components/CustomerContainerTitle'
import NestedSubOrgTable from '../../../components/NestedSubOrgTable'
import TooltipSubOrgLabel from '../../../components/TooltipSubOrgLabel'
import DialogCloudLicenseDetail from '../../../dialogs/DialogCloudLicenseDetail'
import { SubOrgViewContext } from '../interface'
import CloudServicesFilter from '../table/CloudServicesFilter'

const CloudSubOrgSection: FC<any> = () => {
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data) || {}
  const organizations = useTypedSelector(selectOrganizations)

  const { control, permissions } = useContext(SubOrgViewContext)

  const orgIds = useWatch({ control, name: 'organizationIds' })

  const [getCloudProducts] = useLazyOrganizationCloudProductsQuery()
  const [pinUnpinCloudLicense] = usePinUnpinCloudProductsMutation()

  // dialog states
  const [chosenItem, setChosenItem] = useState<any>()
  const [openDetailDialog, setOpenDetailDialog] = useState<boolean>(false)

  // search & filter states
  const [searchFilters, setSearchFilters] = useState<IOrganizationCloudServiceFilterInput>({
    q: '',
    serviceNames: [],
    orgIds: []
  })
  const [mappedProductsData, setMappedProductsData] = useState<any>({})
  const [mappedPinnedData, setMappedPinnedData] = useState<any>({})
  const [productOptions, setProductOptions] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [isFetchingPinned, setIsFetchingPinned] = useState<boolean>(true)

  // get current main org name, for cloud services default message
  const currentOrganizationName = useMemo(() => {
    if (_isEmpty(organizations)) return ''

    const org: any = _find(organizations, (item: any) => item?.id === _get(profileData, 'currentOrganizationId'))
    if (!org) return ''

    return org.name
  }, [organizations, profileData])

  // check if the current org is current chosen in the filter
  const handleCheckChosenOrgs = useCallback(
    (id: string) => {
      return orgIds.some((organization: any) => organization.value === id)
    },
    [orgIds]
  )

  // map MA and MDC cloud services into 1 type
  // also add additional fields for tree table conditions
  const handleMapCloudLicense = useCallback((item: any): any => {
    const service = _get(item, 'service')
    const children = _get(item, 'children', [])

    switch (service) {
      case 'mdc':
        return {
          ...item,
          name: item.name,
          apiKey: item.apiKey,
          daysToExpire: item.days_to_expire,
          expiredDate: item.expired_date,
          status: item.status,
          type: item.mdCloudLicenseType,
          isParentService: children.length === 0
        }
      case 'ma':
        return {
          ...item,
          name: item.name,
          apiKey: item.apiKey,
          daysToExpire: item.maExpirationDate,
          expiredDate: item.expired_date,
          status: item.status,
          isParentService: children.length === 0
        }
      default:
        return item
    }
  }, [])

  // map empty cloud services in case the current org does not have any
  const handleMapEmptyCloudServices = useCallback(
    (cloudServices: any[], viewMdcPermission: boolean, orgName: string) => {
      // if the user only has view_sub_org permission
      // then map the empty MDC license
      if (!viewMdcPermission) {
        const mdcItem = {
          name: productOptions.find((option: any) => option.id === PRODUCT_IDS.MD_CLOUD)?.name ?? 'MetaDefender Cloud',
          apiKey: (
            <span
              style={{
                display: 'inline'
              }}
            >
              Your license is managed by the organization.
              <Tooltip
                arrow
                placement="top"
                title={`Your license is managed by ${orgName}. You must have Full Access permission on the My Licenses to view this information. Please contact your Organization Admin for support.`}
                componentsProps={{
                  tooltip: {
                    sx: {
                      color: '#1B273C',
                      backgroundColor: '#E9EAEB',
                      whiteSpace: 'pre-line'
                    }
                  },
                  arrow: {
                    sx: {
                      color: '#E9EAEB'
                    }
                  }
                }}
              >
                <span
                  style={{
                    marginLeft: '4px'
                  }}
                >
                  <WarningIcon size={14} />
                </span>
              </Tooltip>
            </span>
          ),
          isParentService: true,
          hideActions: true
        }
        // if there are no services at all
        // then return only MDC
        if (cloudServices.length === 0) {
          return [mdcItem]
        }
        // else return empty MDC along with MA
        return [mdcItem, handleMapCloudLicense(cloudServices[0])]
      }
      // if the user has full_sub_org permission
      // then return both MDC and MA
      return cloudServices.map((service: any) => handleMapCloudLicense(service))
    },
    [productOptions]
  )

  // map different empty cloud services states
  const handleMapEmptyData = useCallback(
    (item: any, index: number, filters: any) => {
      const cloudServices = _get(item, 'cloudServices') ?? []
      const viewMdcPermission = _get(item, 'viewMdcPermission') ?? false
      // default name for sub orgs
      const subOrgName = `SUB-ORGANIZATION ${index + 1}`

      const hasFullSubOrgPermission = _get(permissions, 'fullSubOrgPermission', false)
      const isFilteringKeyword = filters.q
      const isFilteringMAOnly = filters.serviceNames.includes('ma') && filters.serviceNames.length === 1

      // if the user only has view_sub_org permission
      // and the user is filtering by keyword or by MA service only
      // then return no services with the message 'There are no licenses to display.'
      if (!hasFullSubOrgPermission && (isFilteringKeyword || isFilteringMAOnly)) {
        return {
          ...item,
          subOrgName,
          apiKey: 'There are no licenses to display.',
          cloudServices: []
        }
      }

      // if the user only has view_license permission (cannot view MDC license)
      // then return the empty MDC license with the message 'Your license is managed by...'
      if (!viewMdcPermission) {
        return {
          ...item,
          subOrgName,
          cloudServices: handleMapEmptyCloudServices([], viewMdcPermission, item.name)
        }
      }

      // if the user has full_license and full_sub_org permission
      // then return the data normally
      return {
        ...item,
        subOrgName,
        apiKey: cloudServices.length === 0 ? 'There are no licenses to display.' : '',
        cloudServices: cloudServices.map((service: any) => handleMapCloudLicense(service))
      }
    },
    [permissions]
  )

  // map the default sub org names for pinned cloud services
  const handleMapPinnedEmptyData = useCallback((item: any, index: number) => {
    const cloudServices = _get(item, 'cloudServices') ?? []
    const subOrgName = `SUB-ORGANIZATION ${index + 1}`
    if (cloudServices.length === 0) {
      return null
    }
    return {
      ...item,
      subOrgName,
      cloudServices: cloudServices.map((service: any) => handleMapCloudLicense(service))
    }
  }, [])

  // check if both the cloud services and children of the current org are empty
  const handleCheckEmptyData = useCallback((item: any) => {
    const cloudServices = _get(item, 'cloudServices') ?? []
    const children = _get(item, 'children') ?? []
    const checkEmptyChildren = children.every((child: any) => handleCheckEmptyData(child))
    return cloudServices.length === 0 && checkEmptyChildren
  }, [])

  // handle get data from API
  const handleGetData = useCallback(
    async (filters: any) => {
      // set loading state for skeleton
      setIsFetching(true)
      const currentOrgId = _get(profileData, 'currentOrganizationId') ?? ''
      await getCloudProducts(filters)
        .unwrap()
        .then((response: any) => {
          setIsFetching(false)
          const cloudServices = _get(response, 'cloudServices') ?? []
          const children = _get(response, 'children') ?? []
          const serviceNames = _get(response, 'serviceNames') ?? {}
          const viewMdcPermission = _get(response, 'viewMdcPermission') ?? false

          // on the first load, map the service names from the API for filter options
          if (productOptions.length === 0) {
            setProductOptions(Object.entries(serviceNames).map(([key, value]) => ({ id: key, name: value })))
          }

          // map the response
          setMappedProductsData({
            ...response,
            // hide licenses from main org when the main org is not chosen in the filter
            cloudServices:
              orgIds.length === 0 || handleCheckChosenOrgs(currentOrgId)
                ? handleMapEmptyCloudServices(
                    cloudServices,
                    viewMdcPermission,
                    currentOrganizationName || 'your organization'
                  )
                : [],
            // hide licenses from sub orgs that are not chosen in the filter
            // then map the rest to empty states
            children: [...children]
              .filter((item: any) => orgIds.length === 0 || handleCheckChosenOrgs(item.id))
              .map((item: any, index: number) => handleMapEmptyData(item, index, filters))
          })
        })
        .catch((error: any) => {
          console.error(error)
          setIsFetching(false)
        })
    },
    [orgIds, productOptions, currentOrganizationName, profileData]
  )

  // handle get pinned data from API, similar logic as above
  const handleGetPinnedData = useCallback(async () => {
    setIsFetchingPinned(true)
    await getCloudProducts({ q: '', serviceNames: [], orgIds: [], pinnedOnly: true })
      .unwrap()
      .then((response: any) => {
        setIsFetchingPinned(false)
        const cloudServices = _get(response, 'cloudServices') ?? []
        const children = _get(response, 'children') ?? []
        setMappedPinnedData({
          ...response,
          cloudServices: cloudServices.map((item: any) => handleMapCloudLicense(item)),
          children: [...children]
            .filter((item: any) => orgIds.length === 0 || handleCheckChosenOrgs(item.id))
            .map((item: any, index: number) => handleMapPinnedEmptyData(item, index))
            .filter(item => item)
        })
      })
      .catch((error: any) => {
        setIsFetchingPinned(false)
        console.error(error)
      })
  }, [orgIds])

  const handleViewDetails = useCallback((item: any) => {
    setChosenItem(item)
    setOpenDetailDialog(true)
  }, [])

  const handlePinLicense = useCallback(async (data: any, isPinned?: boolean) => {
    await pinUnpinCloudLicense({ orgId: data.organization_id, service: data.service, pin: isPinned ?? true })
      .unwrap()
      .then((response: any) => {
        if (response.success) {
          enqueueSnackbar(`${isPinned ? 'Pinned' : 'Unpinned'} license successfully`, {
            variant: 'success'
          })
          handleGetPinnedData()
        }
      })
      .catch(() => {
        enqueueSnackbar(
          `${isPinned ? 'Pinning' : 'Unpinning'} license has failed. Please give the system a moment then try again.`,
          {
            variant: 'error'
          }
        )
      })
  }, [])

  const renderCell = useCallback((data: any, key: string, sx?: CSSProperties, hideEmpty?: boolean, lines?: number) => {
    const value = data[key]
    const emptyValue = hideEmpty ? '' : '--'
    return (
      <TypographyLineClamp
        line={lines ?? 1}
        variant="body2"
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        tooltipValue={value}
        sx={sx}
      >
        {value || emptyValue}
      </TypographyLineClamp>
    )
  }, [])

  const renderDateCell = useCallback((data: any, key: string) => {
    const value = formatDatetime(data[key], DATE_FORMATS.DATE)
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderOrgNameCell = useCallback((data: any, sx?: CSSProperties) => {
    const orgName = _get(data, 'name')
    const subOrgName = _get(data, 'subOrgName')
    const isGodParent = _get(data, 'id') === mappedProductsData.id

    sx = isGodParent ? { ...sx, color: '#154FBA' } : sx

    return <TooltipSubOrgLabel mainLabel={subOrgName} subLabel={orgName} sx={sx} />
  }, [mappedProductsData])

  const renderTypeCell = useCallback((data: any) => {
    const value = _startCase(_get(data, 'type')) || '--'
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderStatusCell = useCallback((data: any) => {
    const value = _get(data, 'status') ?? '--'
    const daysToExpire = _get(data, 'days_to_expire', 0)

    let color = '#008A00'
    if (value === 'expired') {
      color = '#D00300'
    } else if (value === 'expiring') {
      if (daysToExpire < 90 && daysToExpire >= 30) {
        color = '#FDBD0D'
      } else {
        color = '#D00300'
      }
    }

    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        textTransform="capitalize"
        style={{ color }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderActionCell = useCallback((data: any) => {
    const hideActions = _get(data, 'hideActions', false)
    const childItems = [
      {
        label: 'Pin',
        onClick: () => {
          handlePinLicense(data, true)
        }
      },
      {
        label: 'View Details',
        onClick: () => {
          handleViewDetails(data)
        }
      }
    ]

    return (
      !hideActions && (
        <ActionButton
          items={childItems}
          paperStyles={{
            minWidth: '150px'
          }}
          buttonStyles={{
            padding: '0px'
          }}
        />
      )
    )
  }, [])

  const renderPinActionCell = useCallback((data: any) => {
    const childItems = [
      {
        label: 'Unpin',
        onClick: () => {
          handlePinLicense(data, false)
        }
      },
      {
        label: 'View Details',
        onClick: () => {
          handleViewDetails(data)
        }
      }
    ]

    return (
      <ActionButton
        items={childItems}
        paperStyles={{
          minWidth: '150px'
        }}
        buttonStyles={{
          padding: '0px'
        }}
      />
    )
  }, [])

  const cloudColumns = useMemo(() => {
    return [
      {
        header: 'Service Name',
        style: { minWidth: '20vw', width: '20vw' },
        body: (data: any) =>
          renderOrgNameCell(data, {
            color: _get(data, 'isParentService') ? '#1d6dfc' : undefined
          }),
        highlightLevels: [0],
        linkLevels: (data: any) => {
          return [
            '',
            `${homePageURL}/${_get(data, 'service') === 'ma' ? 'metadefender-it-access' : 'metadefender-cloud'}`
          ]
        }
      },
      {
        header: 'API Key',
        style: { minWidth: '18vw', width: '18vw' },
        body: (data: any) => renderCell(data, 'apiKey', {}, true, 2)
      },
      {
        header: 'Status',
        style: {
          minWidth: '8vw',
          width: '8vw',
          textAlign: 'center',
          sx: {
            justifyContent: 'center'
          }
        },
        body: renderStatusCell,
        hiddenLevels: [0]
      },
      {
        header: 'Expiration Date',
        style: { minWidth: '10vw', width: '10vw' },
        body: (data: any) => renderDateCell(data, 'expired_date'),
        hiddenLevels: [0]
      },
      {
        header: 'Type',
        style: { minWidth: '10vw', width: '10vw' },
        body: renderTypeCell,
        hiddenLevels: [0]
      },
      {
        header: '',
        style: { minWidth: 40, textAlign: 'right' },
        body: renderActionCell,
        hiddenLevels: [0]
      }
    ]
  }, [renderOrgNameCell])

  const pinnedColumns = useMemo(() => {
    const columns = [...cloudColumns]
    columns.pop()
    return [
      ...columns,
      {
        header: '',
        style: { minWidth: 40, textAlign: 'right' },
        body: renderPinActionCell,
        hiddenLevels: [0]
      }
    ]
  }, [cloudColumns])

  const TableMemo = useMemo(
    () => (
      <NestedSubOrgTable
        data={handleCheckEmptyData(mappedProductsData) ? [] : [mappedProductsData]}
        isLoading={isFetching}
        columns={cloudColumns}
        childrenColumns={cloudColumns}
        childrenKeys={[['cloudServices'], ['licenses', 'children'], ['licenses'], ['']]}
        levelPadding={24}
      />
    ),
    [mappedProductsData, isFetching, cloudColumns]
  )

  const PinnedTableMemo = useMemo(
    () => (
      <NestedSubOrgTable
        noHeader
        isLoading={isFetchingPinned}
        columns={pinnedColumns}
        childrenColumns={pinnedColumns}
        data={handleCheckEmptyData(mappedPinnedData) ? [] : [mappedPinnedData]}
        childrenKeys={[['cloudServices'], ['licenses', 'children'], ['licenses'], ['']]}
        levelPadding={24}
        noDataText="There are no licenses to display."
      />
    ),
    [pinnedColumns, mappedPinnedData, isFetchingPinned]
  )

  useEffect(() => {
    handleGetData({ ...searchFilters, orgIds: orgIds.map((item: any) => item.value) })
  }, [searchFilters, orgIds])

  useEffect(() => {
    handleGetPinnedData()
  }, [])

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} sx={{ border: '1px solid #BCBFC3', gap: 4 }}>
        <Grid item xs={12}><CustomerContainerTitle title={'Cloud Services'} /></Grid>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: '15px' }}>
          <TypographyDivider
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, height: '20px' }}>
                <PinIcon size={16} />
                <Typography variant="body1" marginTop="2px">
                  Pinned ({_get(mappedPinnedData, 'totalKeys', 0)})
                </Typography>
              </Box>
            }
            propsTypo={{
              component: 'label',
              variant: 'body1'
            }}
          />
          {PinnedTableMemo}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: '15px'
          }}
        >
          <CloudServicesFilter
            productOptions={productOptions}
            currentFilters={searchFilters}
            setCurrentFilters={setSearchFilters}
            handleSearchKeyword={(keyword: string) => {
              setSearchFilters((prev: any) => ({ ...prev, q: keyword.trim() }))
            }}
          />
          {TableMemo}
        </Grid>
      </Grid>
      {chosenItem && (
        <DialogCloudLicenseDetail
          item={chosenItem}
          type={_get(chosenItem, 'service')}
          openDialog={openDetailDialog}
          onClose={() => setOpenDetailDialog(false)}
        />
      )}
    </Grid>
  )
}

export default CloudSubOrgSection
