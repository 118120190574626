import { Box, Grid } from '@opswat/react-ui'

import { RecursiveTree } from 'myopswat-web/src/pages/MyInformationPage/General/RecursiveTree'

import { NestedOrganizationUser, UserActionProps } from '../type'
import OrgRow from './OrgUser'

interface OrgUserTableProps extends UserActionProps {
  orgs: NestedOrganizationUser[]
  initialCollapsedNodes?: string[]
}

export const OrgUserTable = ({
  orgs,
  onChangeRole,
  onRemoveUser,
  onSetSuperAdmin,
  initialCollapsedNodes
}: OrgUserTableProps) => {
  return (
    <Box width="100%">
      <Grid
        container
        sx={{
          padding: '10px',
          fontWeight: 'bold',
          borderBottom: '1px solid #E9EAEB'
        }}
      >
        <Grid item xs>
          Full Name
        </Grid>
        <Grid item xs={2}>
          Email
        </Grid>
        <Grid item xs={1}>
          Active
        </Grid>
        <Grid item xs={1}>
          Role
        </Grid>
        <Grid item xs={2}>
          Last Login
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <RecursiveTree
        nodes={orgs}
        Row={OrgRow}
        initialCollapsedNodes={initialCollapsedNodes}
        externalProps={{
          onChangeRole,
          onRemoveUser,
          onSetSuperAdmin
        }}
      />
    </Box>
  )
}
