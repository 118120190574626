import { useState } from 'react'

import { Box } from '@opswat/react-ui'
import SupportServiceCaseTable from '../AllCases/components/CasesTableV2'

export const Cases = () => {
  return (
    <Box
      sx={{
        border: '1px solid #E9EAEB',
        padding: '16px 16px 0 16px',
        minHeight: '400px'
      }}
    >
      <SupportServiceCaseTable isMiniTable />
    </Box>
  )
}
