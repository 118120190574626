import { CSSProperties } from 'react'

import { Button } from '@opswat/react-ui'

import './chatbot.css'

interface IProps {
  onClick: () => void
  icon: React.ReactNode
  style?: CSSProperties
}

const CustomChatbotHeaderButton: React.FC<IProps> = ({ onClick, icon, style }) => {
  return (
    <Button
      className="bpChatbotHeaderButton"
      onClick={onClick}
      sx={{
        ...style
      }}
    >
      {icon}
    </Button>
  )
}

export default CustomChatbotHeaderButton
