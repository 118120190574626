import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'
import {
  IPageInfoInput,
  IPortalRole,
  IPortalRolesFiltersInput,
  IPortalRolesWithInformation,
  IRole,
  IRoleFiltersInput,
  IRoleObject,
  ISortInfoInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    roles: builder.query<any, IRoleFiltersInput>({
      query: ({ q }) => ({
        document: gql`
          query ($filters: RoleFiltersInput) {
            roles(filters: $filters) {
              id
              accessLevel
              name
              description
              ssoId
              updatedAt
              createdAt
              creatorId
              creatorFullname
            }
          }
        `,
        variables: {
          filters: {
            q: q
          }
        }
      })
    }),
    applicationRoles: builder.query<any, IRoleFiltersInput>({
      query: () => ({
        document: gql`
          query {
            applicationRoles {
              id
              name
              permissions
              description
              ssoId
              updatedAt
              createdAt
            }
          }
        `
      })
    }),
    applicationRolesWithPermissions: builder.query<any, IRoleFiltersInput>({
      query: ({ q }) => ({
        document: gql`
          query ($filters: RoleFiltersInput) {
            applicationRolesWithPermissions(filters: $filters) {
              roles {
                id
                name
                permissions
                description
                ssoId
                updatedAt
                createdAt
              }
              allPermissions
            }
          }
        `,
        variables: {
          filters: {
            q: q
          }
        }
      })
    }),
    roleCreate: builder.mutation<any, any>({
      query: ({ name, permissionIds }) => ({
        document: gql`
          mutation ($input: RoleInput!) {
            roleCreate(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            name: name,
            permissionIds: permissionIds || []
          }
        }
      })
    }),
    roleDelete: builder.mutation<any, string>({
      query: id => ({
        document: gql`
        mutation {
          roleDelete(id: "${id}") {
              success
              errors
          }
      }
        `
      })
    }),
    portalRoles: builder.query<
      IPortalRolesWithInformation,
      { filters: IPortalRolesFiltersInput; sortInfo?: ISortInfoInput; pageInfo: IPageInfoInput }
    >({
      query: ({ filters, sortInfo, pageInfo }) => ({
        document: gql`
          query ($filters: PortalRoleFiltersInput, $sortInfo: SortInfoType, $pageInfo: PageInfoType) {
            portalRoles(filters: $filters, sortInfo: $sortInfo, pageInfo: $pageInfo) {
              organizationPortalRoles {
                id
                name
                description
                numberOfUsers
                isDefault
                updatedAt
                lastUpdatedBy {
                  id
                  email
                }
              }
              sortableCols
              totalCount
            }
          }
        `,
        variables: {
          filters: filters,
          sortInfo: sortInfo,
          pageInfo: pageInfo
        }
      })
    }),
    portalRole: builder.query<IPortalRole, { id: string; organizationId?: string }>({
      query: ({ id, organizationId }) => ({
        document: gql`
          query ($id: UUID!, $organizationId: UUID!) {
            portalRole(id: $id, organizationId: $organizationId) {
              id
              name
              description
              numberOfUsers
              isDefault
              updatedAt
              lastUpdatedBy {
                id
                email
              }
              roleObjects {
                id
                selectedPermissions
              }
            }
          }
        `,
        variables: {
          id: id,
          organizationId: organizationId
        }
      })
    }),
    roleObjects: builder.query<IRoleObject[], any>({
      query: () => ({
        document: gql`
          query {
            roleObjects {
              id
              code
              name
              permissions {
                id
                name
                codename
                description
                priority
              }
            }
          }
        `
      })
    }),
    portalRoleCreate: builder.mutation<any, any>({
      query: input => ({
        document: gql`
          mutation ($input: PortalRoleInput!) {
            portalRoleCreate(input: $input) {
              success
              errors
              action
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    portalRoleUpdate: builder.mutation<any, any>({
      query: input => ({
        document: gql`
          mutation ($input: PortalRoleInput!) {
            portalRoleUpdate(input: $input) {
              success
              errors
              action
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    portalRoleDelete: builder.mutation<any, { id: string; alternativeRoleId?: string }>({
      query: ({ id, alternativeRoleId }) => ({
        document: gql`
          mutation ($id: UUID!, $alternativeRoleId: UUID) {
            portalRoleDelete(id: $id, alternativeRoleId: $alternativeRoleId) {
              success
              errors
              action
            }
          }
        `,
        variables: {
          id: id,
          alternativeRoleId: alternativeRoleId
        }
      })
    }),
    nestedPortalRole: builder.query<IRole[], string>({
      query: id => ({
        document: gql`
          query ($id: UUID!) {
            nestedPortalRoles(id: $id) {
              id
              name
              organizationId
            }
          }
        `,
        variables: {
          id
        }
      })
    })
  })
})

export const {
  useRolesQuery,
  useLazyRolesQuery,
  useApplicationRolesQuery,
  useApplicationRolesWithPermissionsQuery,
  useRoleCreateMutation,
  useRoleDeleteMutation,
  useLazyPortalRolesQuery,
  useLazyPortalRoleQuery,
  useRoleObjectsQuery,
  usePortalRoleCreateMutation,
  usePortalRoleUpdateMutation,
  usePortalRoleDeleteMutation,
  useLazyNestedPortalRoleQuery
} = api
