import { buildTree } from 'myopswat-web/src/utils'
import { useMemo } from 'react'
import { Nodes, WithTreeProps } from './types'

export const withTree = <T extends Record<string, any>, P extends Nodes, G>(
  WrappedComponent: React.ComponentType<P>
) => {
  return ({ data, ...rest }: WithTreeProps<T, G>) => {
    const nodes = useMemo(() => buildTree(data, 'parentIds'), [data])
    return <WrappedComponent {...(rest as unknown as P)} nodes={nodes} />
  }
}
