import { useCallback } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

import _get from 'lodash/get'

import { AutocompleteMultiple, Grid, Typography } from '@opswat/react-ui'

import { NestedSelectOption, NestedSelectProps } from './types'

export const NestedSelect = <T extends NestedSelectOption, P extends FieldValues>({
  name,
  control,
  label,
  options,
  placeholder = label,
  limitTags = 3,
  onChange
}: NestedSelectProps<T, P>) => {
  const filterOptions = useCallback((options: any, state?: object) => {
    const keywords = _get(state, 'inputValue', '')
    const filteredOptions = options.filter((option: any) =>
      _get(option, 'label').toLowerCase().includes(keywords.toLowerCase())
    )
    if (filteredOptions.length === 0) {
      return filteredOptions
    } else {
      return [
        ...options.filter((option: any) => _get(option, 'level') === 0),
        ...filteredOptions.filter((option: any) => _get(option, 'level') !== 0)
      ]
    }
  }, [])

  return (
    <Grid item container xs={12} sx={{ gap: '8px' }}>
      <Typography variant="subtitle1">{label}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <AutocompleteMultiple
            disableCloseOnSelect={false}
            placeholder={placeholder}
            options={options}
            limitTags={limitTags}
            filterOptions={filterOptions}
            {...field}
            onChange={(...e) => {
              onChange?.(e[0])
              field.onChange(...e)
            }}
          />
        )}
      />
    </Grid>
  )
}
