import React, { useState } from 'react'
import _debounce from 'lodash/debounce'

import { ChevronRightIcon, CopySimpleIcon } from '@opswat/react-icon'
import { Box, Collapse, IconButton, List, ListItem, ListItemText, Tooltip, Typography } from '@opswat/react-ui'

import { PhoneSettings, PhoneRegion, PhoneItem } from './types'

interface PhoneListProps {
  phoneSettings?: PhoneSettings
  supportLevel?: string
}

const filterRegions = (phones: PhoneRegion[], filterFn: (child: PhoneItem) => boolean): PhoneRegion[] => {
  return phones
    .map(region => ({
      ...region,
      children: region.children.filter(filterFn)
    }))
    .filter(region => region.children.length > 0)
}

const PhoneList: React.FC<PhoneListProps> = ({ phoneSettings, supportLevel }) => {
  const [expandedRegion, setExpandedRegion] = useState<string | null>(null)
  const [copiedValue, setCopiedValue] = useState<string | null>(null)

  const filteredRegions = React.useMemo(() => {
    if (!phoneSettings) {
      return []
    }

    const { phones, preferences } = phoneSettings
    const { groupedLevelMapping, singleLevelMapping } = preferences

    if (!supportLevel) {
      return filterRegions(phones, child => !child.supportLevel && !child.groupedSupportLevels)
    }

    if (groupedLevelMapping[supportLevel]) {
      return filterRegions(phones, child =>
        groupedLevelMapping[supportLevel].some(
          level => child.groupedSupportLevels && child.groupedSupportLevels.includes(level)
        )
      )
    }

    if (singleLevelMapping[supportLevel]) {
      return filterRegions(phones, child => child.supportLevel === supportLevel)
    }

    return filterRegions(phones, child => !child.supportLevel && !child.groupedSupportLevels)
  }, [phoneSettings, supportLevel])

  const toggleExpand = (region: string) => {
    setExpandedRegion(prev => (prev === region ? null : region))
  }

  const handleReturnCopy = _debounce(() => {
    setCopiedValue(null)
  }, 10000)

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value)
    setCopiedValue(value)
    handleReturnCopy()
  }

  return (
    <Box
      sx={{
        height: '200px',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      <List sx={{ padding: 0 }}>
        {filteredRegions.map(region => (
          <React.Fragment key={region.location}>
            <ListItem
              sx={{
                borderTop: '1px solid #E9EAEB',
                '&:hover': { backgroundColor: '#f9f9f9', cursor: 'pointer' }
              }}
              onClick={() => toggleExpand(region.location)}
            >
              <ListItemText primary={region.location} sx={{ fontWeight: 500, color: '#485161', margin: '0px' }} />
              <IconButton edge="end" sx={{ padding: '0px 8px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transform: `rotate(${expandedRegion === region.location ? 90 : 0}deg)`,
                    transition: 'all 0.2s ease-in-out'
                  }}
                >
                  <ChevronRightIcon size={20} />
                </Box>
              </IconButton>
            </ListItem>
            <Collapse in={expandedRegion === region.location} timeout="auto" unmountOnExit>
              <Box>
                {region.children.map(child => (
                  <Box
                    key={child.value}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '6px 16px 6px 24px'
                    }}
                  >
                    <Typography variant="body1" color="#485161" fontWeight={400}>
                      {child.label}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" color="#485161" fontWeight={400} sx={{ marginRight: '8px' }}>
                        {child.text}
                      </Typography>
                      <Tooltip title={copiedValue === child.value ? 'Copied' : 'Copy'} placement="top" arrow>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '&:hover': { cursor: 'pointer' }
                          }}
                          onClick={() => handleCopy(child.value)}
                        >
                          <CopySimpleIcon size={16} color="#485161" />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Box>
  )
}

export default PhoneList
