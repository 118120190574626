import { FC, useEffect } from 'react'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'

import { useModal } from '@ebay/nice-modal-react'
import { useNavigate } from 'react-router-dom'

import { DIALOGS } from '@myopswat/common'

import {
  selectDialogs,
  selectIsCloseNPSSurvey,
  selectIsSkipPersonal,
  selectOrganizations,
  selectPopupMessage,
  setDialogMessage,
  toggleDialogs
} from '../../containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import MessageModal from 'myopswat-web/src/components/Dialog/MessageModal'
import { mfaPageURL } from 'myopswat-web/src/routes'
import DialogLicenseActivate from 'myopswat-web/src/components/Dialog/DialogLicenseActivate'
import DialogFeedback from 'myopswat-web/src/components/Dialog/DialogFeedback'
import DialogAboutProduct from 'myopswat-web/src/components/Dialog/DialogAboutProduct'
import DialogPersonalize from 'myopswat-web/src/components/Dialog/DialogPersonalize'
import DialogDefaultOrganization from 'myopswat-web/src/components/Dialog/DialogDefaultOrganization'
import CookieSettingDrawer from 'myopswat-web/src/components/Drawer/CookieSettingDrawer'
import DialogCookieSettings from 'myopswat-web/src/components/Dialog/DialogCookieSettings'
import DialogSessionTimeout from 'myopswat-web/src/components/Dialog/DialogSessionTimeout'
import DialogSOCSurvey from 'myopswat-web/src/components/Dialog/DialogSOCSurvey'
import DialogNPSSurveyProvider from 'myopswat-web/src/components/Dialog/DialogNPSSurvey/context'
import DialogNoOrganizationOCM from 'myopswat-web/src/components/Dialog/DialogNoOrganizationOCM'
import ConfirmationDialog from 'myopswat-web/src/components/Dialog/ConfirmationDialog'

import DialogReferralSource from '../../components/Dialog/DialogReferralSouce'
import { DIALOGS_WEB } from '../../constants/dialogs'
import DialogPermissionsDenied from '../../components/Dialog/DialogPermissionsDenied'

const AllForm: FC<unknown> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)

  const messageModal = useModal(MessageModal)

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const organizationsData = useTypedSelector(selectOrganizations)
  const message = useTypedSelector(selectPopupMessage)
  const isSkipPersonal = useTypedSelector(selectIsSkipPersonal)
  const isCloseNPSurvey = useTypedSelector(selectIsCloseNPSSurvey)

  useEffect(() => {
    if (message) {
      messageModal.show({ message: message })
      dispatch(setDialogMessage(''))
    }
  }, [message])

  const handleForm = async () => {
    if (_get(profileData, 'email') && _get(profileData, 'showPersonalize', false) && !isSkipPersonal) {
      await dispatch(
        toggleDialogs({
          [DIALOGS.PERSONAL_INFORMATION]: true
        })
      )
      return null
    }

    if (
      _get(profileData, 'email') &&
      !_get(dialogType, DIALOGS.PERSONAL_INFORMATION, false) &&
      !_get(profileData, 'usingOrganizationId') &&
      !_isEmpty(organizationsData)
    ) {
      await dispatch(
        toggleDialogs({
          [DIALOGS.DEFAULT_ORGANIZATION]: true
        })
      )
      return null
    }

    if (
      !_get(profileData, 'showPersonalize', false) &&
      _get(profileData, 'organizationForceMfa', false) &&
      !_get(profileData, 'accountInfo.isMfaEnabled', false) &&
      _get(profileData, 'currentOrganizationId') &&
      _get(profileData, 'usingOrganizationId') &&
      _get(profileData, 'currentOrganizationId') === _get(profileData, 'usingOrganizationId')
    ) {
      await navigate(mfaPageURL)
      return null
    }

    if (_get(profileData, 'showSocSurvey')) {
      await dispatch(
        toggleDialogs({
          [DIALOGS.SOC_SURVEY]: true
        })
      )
      return null
    }

    if (_get(profileData, 'showReferralSource')) {
      await dispatch(
        toggleDialogs({
          [DIALOGS_WEB.REFERRAL_SOURCE]: true
        })
      )
      return null
    }
  }
  const survey = !!_get(profileData, 'survey')
  const openPersonalizeDialog = _get(dialogType, DIALOGS.PERSONAL_INFORMATION, false)
  const hideSurveyDialog = _get(dialogType, DIALOGS_WEB.NPS_SURVEY_HIDDEN, false)
  const openNPSSurveyDialog = _get(dialogType, DIALOGS_WEB.NPS_SURVEY, false)
  const isAllowShowReferralSource =
    !openPersonalizeDialog && !hideSurveyDialog && !openNPSSurveyDialog && (!survey || isCloseNPSurvey)

  useEffect(() => {
    handleForm()
  }, [profileData, organizationsData, isSkipPersonal])

  return (
    <>
      <DialogLicenseActivate />

      <DialogFeedback />

      <DialogAboutProduct
        orgName={
          _get(
            _find(organizationsData, (item: any) => item?.id === _get(profileData, 'currentOrganizationId')),
            'name'
          ) || '--'
        }
      />

      {_get(profileData, 'email') && <DialogPersonalize />}

      {!_isEmpty(organizationsData) && _get(profileData, 'email') && (
        <DialogDefaultOrganization profileData={profileData} organizationsData={organizationsData} />
      )}

      <CookieSettingDrawer />

      <DialogCookieSettings />

      <DialogSessionTimeout />

      <DialogSOCSurvey />

      <DialogNPSSurveyProvider />

      <DialogPermissionsDenied />

      {isAllowShowReferralSource && <DialogReferralSource />}

      <DialogNoOrganizationOCM />

      <ConfirmationDialog />
    </>
  )
}

export default AllForm
