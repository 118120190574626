import { UseFormReturn } from 'react-hook-form'
import { ICreateSubOrganizationForm } from '../../../../api/organization/types'
import {Box, Grid, TextField, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'

interface IProps {
  formRef: UseFormReturn<ICreateSubOrganizationForm>
  organization: any
}

const CreateSubOrganizationForm = ({ formRef, organization }: IProps) => {
  const {
    register,
    formState: { errors }
  } = formRef

  return (
    <Grid container spacing={2} marginTop={0}>
      <Grid item xs={12}>
        <Typography variant="body2" marginBottom={0.5}>You are creating new sub organization under current organization <strong>{_get(organization, 'name', '--')}</strong>.</Typography>
        <Typography variant="body2">To create new sub organization under a different organization,
          please switch your current organization first.</Typography>
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1" marginBottom={0.5}>Organization <span  style={{ color: '#D00300' }}>*</span></Typography>
          <TextField fullWidth size="small" value={organization.name} disabled />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1" marginBottom={0.5}>Sub Organization Name <span  style={{ color: '#D00300' }}>*</span></Typography>
          <TextField
            required
            fullWidth
            size="small"
            {...register(`name`)}
            error={!!errors?.name}
            helperText={errors?.name && errors?.name?.message}
            placeholder="New Sub Organization Name"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Your role in this new Sub Organization is <strong>Super Admin</strong> by default.You
          can change this later in Organizations {'>'} Roles.</Typography>
      </Grid>
    </Grid>
  )
}

export default CreateSubOrganizationForm