import { Grid, Skeleton, TableLoading, TemplateSection } from '@opswat/react-ui'

export const UserTabV2Loading = () => {
  return (
    <TemplateSection spacing={2}>
      <Grid container spacing={4}>
        <Grid item container xs={12}>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rectangular" height={32} />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rectangular" height={32} />
          </Grid>

          <Grid item container xs={12} md={8} justifyContent="flex-end" spacing={2}>
            {[...Array(3)].map((_, index) => (
              <Grid item xs={2} key={index}>
                <Skeleton variant="rectangular" height={32} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <TableLoading />
    </TemplateSection>
  )
}
