export const groupBy = <T, K extends keyof T>(
  items: T[],
  key: K,
  options?: { caseInsensitive?: boolean }
): Record<string, T[]> => {
  const { caseInsensitive = false } = options || {}

  const groupKeyMap = new Map<string, string>()

  return items.reduce((acc, item) => {
    const groupKey = String(item[key])
    const normalizedKey = caseInsensitive ? groupKey.toLowerCase() : groupKey

    if (!groupKeyMap.has(normalizedKey)) {
      groupKeyMap.set(normalizedKey, groupKey)
    }

    const finalKey = groupKeyMap.get(normalizedKey)!

    acc[finalKey] = acc[finalKey] || []
    acc[finalKey].push(item)

    return acc
  }, {} as Record<string, T[]>)
}
