import { FC, useCallback, useContext } from 'react'

import _differenceBy from 'lodash/differenceBy'
import _get from 'lodash/get'
import _uniqBy from 'lodash/uniqBy'
import { Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InformationIcon } from '@opswat/react-icon'
import { AutocompleteMultiple, ButtonLoading, Grid, Skeleton, Typography, TypographyLineClamp } from '@opswat/react-ui'

import useGetPortalPermissions from 'myopswat-web/src/hooks/useGetPortalPermissions'
import useHasPermissions from 'myopswat-web/src/hooks/useHasPermissions'

import CustomerLicenseLoading from './components/CustomerLicenseLoading'
import { CustomerLicensesContext } from './interface'
import CustomerChartSection from './sections/ChartSection'
import CustomerCloudServicesSection from './sections/CloudServicesSection'
import CustomerLicensedProductsSection from './sections/LicensedProductsSection'

const CustomerLicensesPage: FC<any> = () => {
  const {
    controlProduct: control,
    organizationList,
    isFetchingCustomerTree,
    isGeneratingReport,
    reportData,
    reportError,
    handleGenerateReport
  } = useContext(CustomerLicensesContext)
  const { t: translate } = useTranslation('common')

  const { permissions } = useGetPortalPermissions()
  const hasViewOnlyPermission = useHasPermissions({
    targetPerms: ['view_customer_organization_license', 'full_customer_organization_license'],
    userPerms: permissions
  })

  const customerOrgIds = useWatch({ control, name: 'customerOrgIds' })

  const handleOnChangeCustomerOrgs = useCallback((currentValues: any[], newValues: any[], onChange: any) => {
    const addedValues = _differenceBy(newValues, currentValues, 'value')
    const newOrg = addedValues[0] // undefined if the option is removed
    const isParentOrg = _get(newOrg, 'children.length', 0) > 0
    const isParentCustomerOrg = _get(newOrg, 'isCustomer') ?? false

    if (isParentOrg) {
      const childrenOrgs = _get(addedValues, '0.children', [])
      let finalValues = [...newValues, ...childrenOrgs]
      // if the current option is a parent but is not a customer, remove from final list
      if (!isParentCustomerOrg) {
        finalValues = finalValues.filter((item: any) => item.value !== newOrg.value)
      }
      // unique to remove duplicates
      onChange(_uniqBy(finalValues, 'value'))
    } else {
      // else handle on change normally
      onChange(newValues)
    }
  }, [])

  return hasViewOnlyPermission ? (
    <Grid container spacing={2.5} sx={{ marginTop: '0px' }}>
      <Grid item xs={12}>
        <Typography variant="body2" component="label">
          This page summarizes all license purchases and evaluations of your customer organizations.
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{translate('selectOrganizations')}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'space-between',
              lg: 'flex-start'
            },
            gap: '10px'
          }}
        >
          {isFetchingCustomerTree ? (
            <Skeleton
              variant="rectangular"
              sx={{
                height: '37px',
                width: {
                  xs: 'auto',
                  md: '60vw',
                  lg: '47vw'
                }
              }}
            />
          ) : (
            <Controller
              name="customerOrgIds"
              control={control}
              render={({ field }) => (
                <AutocompleteMultiple
                  placeholder={translate('selectOrganizations')}
                  disabled={isGeneratingReport}
                  options={organizationList}
                  limitTags={3}
                  sx={{
                    width: {
                      xs: 'auto',
                      md: '60vw',
                      lg: '47vw'
                    }
                  }}
                  {...field}
                  onChange={(newValue: any[]) => {
                    handleOnChangeCustomerOrgs(field.value, newValue, field.onChange)
                  }}
                />
              )}
            />
          )}
          <ButtonLoading
            propsButton={{
              variant: 'contained',
              color: 'primary',
              disableElevation: true,
              disabled: customerOrgIds.length === 0 || isGeneratingReport,
              sx: {
                width: '130px',
                height: '100%',
                padding: '6px 12px'
              },
              onClick: handleGenerateReport
            }}
            isLoading={isGeneratingReport}
            propsLoading={{ color: 'inherit' }}
          >
            <TypographyLineClamp line={1} color="inherit">
              Generate Report
            </TypographyLineClamp>
          </ButtonLoading>
        </Grid>
      </Grid>
      {isGeneratingReport && <CustomerLicenseLoading />}
      {!isGeneratingReport && !reportError && reportData && (
        <>
          <CustomerChartSection />
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5
            }}
          >
            <InformationIcon size={16} color="#707682" />
            <Typography component="label" variant="caption" color="#707682">
              Interact with STATUS chart sections to filter the list below
            </Typography>
          </Grid>
          <CustomerCloudServicesSection />
          <CustomerLicensedProductsSection />
        </>
      )}
    </Grid>
  ) : (
    <></>
  )
}

export default CustomerLicensesPage
