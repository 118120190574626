import { SolidRightIcon } from '@opswat/react-icon'
import { Box, Collapse, Grid, Typography, TypographyLineClamp } from '@opswat/react-ui'

import { RowProps } from 'myopswat-web/src/pages/MyInformationPage/General/RecursiveTree'

import { UserList } from './UserList'

const OrgUser = ({ node, level, collapsed, onToggle, onChangeRole, onRemoveUser, onSetSuperAdmin }: RowProps) => {
  const { name, users, isSelected, hasAccess, children } = node

  const isDisplayCollapseIcon = (children && children.length > 0) || (users && users.length > 0)

  return (
    <>
      <Grid
        container
        sx={{ padding: '4px', alignItems: 'center', borderBottom: '1px solid #E9EAEB', minHeight: '40px' }}
      >
        <Grid
          item
          xs={5}
          onClick={onToggle}
          sx={{
            display: 'flex',
            paddingLeft: `${level * 20}px`,
            cursor: isDisplayCollapseIcon ? 'pointer' : 'unset',
            '&:hover': isDisplayCollapseIcon && {
              opacity: 0.8
            }
          }}
        >
          <Box sx={{ display: 'inline-flex', width: '20px', minWidth: '20px', height: '20px' }}>
            {isDisplayCollapseIcon && (
              <Box
                component="span"
                style={{
                  transform: `rotate(${collapsed ? 0 : 90}deg)`,
                  transition: 'transform 0.15s ease'
                }}
              >
                <SolidRightIcon size={20} />
              </Box>
            )}
          </Box>

          <TypographyLineClamp variant="body2" line={1} tooltipValue={name} color={'#1D6BFC'} fontWeight={500}>
            {name}
          </TypographyLineClamp>
        </Grid>
        <Grid item container xs justifyContent="space-between">
          {isSelected && users && users.length === 0 && (
            <Grid item xs>
              <Typography variant="body2">There are no users to display</Typography>
            </Grid>
          )}
          {/* TODO: Implement join this organization in phase 2 */}
          {!hasAccess && (
            <Grid item xs>
              <Typography variant="body2" sx={{ fontStyle: 'italic', textAlign: 'right' }}>
                no access permission
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {isSelected && (
        <Collapse in={!collapsed} timeout="auto" unmountOnExit>
          <UserList
            users={users}
            level={level}
            onChangeRole={onChangeRole}
            onRemoveUser={onRemoveUser}
            onSetSuperAdmin={onSetSuperAdmin}
          />
        </Collapse>
      )}
    </>
  )
}

export default OrgUser
