export enum SUBMIT_CASE_ISSUE_TYPE {
  CONFIGURATION = 'Configuration',
  INSTALLATION_UPGRADE = 'Installation & Upgrade',
  UNEXPECTED_BEHAVIOR = 'Unexpected Behavior',
  INTEGRATIONS = 'Integrations',
  QUESTION = 'Question',
  PERFORMANCE = 'Performance',
  LICENSE_ACTIVATION = 'License & Activation',
  FEATURE_REQUEST = 'Feature Request',
  OTHER = 'Other'
}

export enum SUBMIT_CASE_SEVERITY {
  SEVERITY_1 = 'Production server or critical system is down with no immediate workaround.',
  SEVERITY_2 = 'Major functionality is severely impaired.',
  SEVERITY_3 = 'Partial, non-critical loss of functionality of the software.',
  SEVERITY_4 = 'General usage question or issue with minor business impact.'
}

export enum SUBMIT_CASE_PLATFORM {
  WINDOWS = 'Windows',
  LINUX = 'Linux',
  MACOS = 'macOS',
  KUBERNETES = 'Kubernetes',
  DOCKER = 'Docker',
  PODMAN = 'Podman',
  NOT_APPLICABLE = 'Not Applicable'
}

export enum SUBMIT_CASE_OPTION {
  OTHER = 'Other',
  MORE = 'More'
}

export const SUBMIT_CASE_OTHER_PRODUCT_OPTION: any = {
  id: SUBMIT_CASE_OPTION.OTHER,
  name: SUBMIT_CASE_OPTION.OTHER,
  supportCasePlatforms: []
}

export const MOCK_SUGGESTION_DATA = [
  {
    title: 'MetaDefender ICAP Server License Guide',
    link: 'https://docs.opswat.com/mdcore/installation/metadefender-core-licensing'
  },
  {
    title: 'MetaDefender ICAP Server License Activation',
    link: 'https://docs.opswat.com/ocm/installation/activating-license'
  },
  {
    title: 'My OPSWAT License Activation',
    link: 'https://docs.opswat.com/mdmft/configuration/license-activation'
  },
  {
    title: 'MetaDefender ICAP Server License Activation',
    link: 'https://docs.opswat.com/mdmft/configuration/license-activation'
  },
  {
    title: 'MetaDefender ICAP Server License Activation',
    link: 'https://docs.opswat.com/mdmft/configuration/license-activation'
  }
]

export const CONTACT_ID_NOT_FOUND_ERROR_CODE = 'USER_CONTACT_ID_NOT_FOUND'
