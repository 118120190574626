import { useModal } from '@ebay/nice-modal-react'
import { TemplateSection } from '@opswat/react-ui'
import { useCallback, useEffect, useState } from 'react'
import { useLazyPortalRolesQuery } from '../../../../../api/role'
import {
  IPageInfoInput,
  IPortalRole,
  IPortalRolesFiltersInput,
  ISortInfoInput,
  Order
} from '../../../../../api/role/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from '../../../../../constants'
import BoxRolesFilter from '../../BoxRolesFilter'
import BoxRolesTable from '../../BoxRolesTable'
import CreateUpdateRoleModal from '../../CreateUpdateRole/CreateUpdateRoleModal'
import DeleteRoleModal from '../../DeleteRole/DeleteRoleModal'

interface IProps {
  permissions: string[]
  organization: any
}

const defaultRolesFilter = { q: '', excludeSuperAdmin: false }
const defaultSortInfo = {
  order: 'desc' as Order,
  orderBy: 'updatedAt'
}
const defaultPageInfo = {
  page: PAGE_DEFAULT,
  pageSize: PAGE_SIZE_DEFAULT
}

const SingleOrgPortalRoleView = ({ permissions, organization }: IProps) => {
  const [rolesFilter, setRolesFilter] = useState<IPortalRolesFiltersInput>(defaultRolesFilter)
  const [sortInfo, setSortInfo] = useState<ISortInfoInput>(defaultSortInfo)
  const [pageInfo, setPageInfo] = useState<IPageInfoInput>(defaultPageInfo)

  const [getPortalRoles, { data, isFetching }] = useLazyPortalRolesQuery()

  const handleGetPortalRoles = (
    rolesFilter?: IPortalRolesFiltersInput,
    sortInfo?: ISortInfoInput,
    pageInfo?: IPageInfoInput
  ) => {
    if (!rolesFilter) rolesFilter = defaultRolesFilter
    if (!sortInfo) sortInfo = defaultSortInfo
    if (!pageInfo) pageInfo = defaultPageInfo
    setRolesFilter(rolesFilter)
    setSortInfo(sortInfo)
    setPageInfo(pageInfo)
    getPortalRoles({ filters: rolesFilter, sortInfo: sortInfo, pageInfo: pageInfo })
  }

  useEffect(() => {
    handleGetPortalRoles(defaultRolesFilter, sortInfo)
  }, [])

  useEffect(() => {
    handleGetPortalRoles(rolesFilter, sortInfo, pageInfo)
  }, [rolesFilter, sortInfo, pageInfo])

  // Modals
  const createUpdateRoleModal = useModal(CreateUpdateRoleModal)
  const deleteRoleModal = useModal(DeleteRoleModal)

  const handleClickNew = useCallback(() => {
    createUpdateRoleModal.show({ organization }).then(() => {
      handleGetPortalRoles()
    })
  }, [organization])

  const handleClickEdit = useCallback(
    (selectedPortalRole: IPortalRole) => {
      createUpdateRoleModal.show({ selectedPortalRoleId: selectedPortalRole.id, organization }).then(() => {
        handleGetPortalRoles()
      })
    },
    [organization]
  )

  const handleClickDetail = useCallback(
    (selectedPortalRole: IPortalRole) => {
      createUpdateRoleModal
        .show({ selectedPortalRoleId: selectedPortalRole.id, viewOnly: true, organization })
        .then(() => {
          handleGetPortalRoles()
        })
    },
    [organization]
  )

  const handleClickDelete = useCallback(
    (selectedPortalRole: IPortalRole) => {
      if (data?.organizationPortalRoles) {
        deleteRoleModal.show({ selectedPortalRole }).then(() => {
          handleGetPortalRoles()
        })
      }
    },
    [data]
  )

  return (
    <TemplateSection spacing={2}>
      <BoxRolesFilter setRolesFilter={setRolesFilter} onClickNew={handleClickNew} />
      <BoxRolesTable
        portalRolesData={data}
        isFetchingRoles={isFetching}
        setSortInfo={setSortInfo}
        sortInfo={sortInfo}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
        onClickDetail={handleClickDetail}
        setPageInfo={setPageInfo}
        pageInfo={pageInfo}
        permissions={permissions}
      />
    </TemplateSection>
  )
}

export default SingleOrgPortalRoleView
