import { Button, Divider, DropdownFilter, Grid, TextFieldSearch } from '@opswat/react-ui'
import _debounce from 'lodash/debounce'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { PERMISSIONS } from '@myopswat/common'
import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'

import { NestedSelect, NestedSelectOption } from 'myopswat-web/src/components/NestedOrganizationSelect'
import { useCheckPermission } from 'myopswat-web/src/hooks'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RawNestedOrganizationRoleFilters } from '../../../../../api/organization/types'
import { FilterContent } from '../../../Users/Filter'

interface IProps {
  portalRoleList: any[]
  filters: RawNestedOrganizationRoleFilters
  onSearch: (searchData: RawNestedOrganizationRoleFilters) => void
  onCreateNewRole: () => void
  nestedOrgs: NestedSelectOption[]
}

const RoleFilter: FC<IProps> = ({ filters, portalRoleList, onSearch, onCreateNewRole, nestedOrgs }) => {
  const { t: translate } = useTranslation(['translation', 'common'])
  const { hasPermission } = useCheckPermission()
  const [key, setKey] = useState(0)

  const {
    control,
    reset,
    setValue,
    getValues,
    watch,
    formState: { dirtyFields }
  } = useForm<RawNestedOrganizationRoleFilters>({
    mode: 'onSubmit',
    defaultValues: filters,
    values: filters
  })

  const q = watch('q')
  const portalRoles = watch('portalRoles')
  const selectedOrgIds = watch('organizationIds')

  const hasFullOrgRolePerm = hasPermission(PERMISSIONS.FULL_MY_ORGANIZATIONS_ROLE)

  const isChangedTextSearch = useMemo(() => {
    return dirtyFields.q
  }, [dirtyFields.q])

  const handleSearch = useCallback(() => {
    const searchData = {
      ...getValues(),
      portalRoles: portalRoles,
      q: q
    }
    onSearch(searchData)
  }, [q, portalRoles, selectedOrgIds])

  useEffect(() => {
    if (isChangedTextSearch) {
      const _search = _debounce(handleSearch, 300)
      _search()
      return () => {
        _search.cancel()
      }
    }
  }, [isChangedTextSearch, handleSearch])

  const handleApplyFilter = useCallback(() => {
    handleSearch()
  }, [handleSearch])

  const handleChange = (newValue: NestedSelectOption[]) => {
    setValue('portalRoles', filters.portalRoles)
    setKey(prevKey => prevKey + 1)
    onSearch({
      ...getValues(),
      organizationIds: newValue,
      portalRoles: filters.portalRoles
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item container xs={6} sx={{ gap: '8px', paddingBottom: 2 }}>
        <NestedSelect
          control={control}
          name="organizationIds"
          label={translate('common:selectOrganizations')}
          options={nestedOrgs}
          placeholder={translate('common:selectOrganizations')}
          onChange={handleChange}
        />
      </Grid>

      <Grid item container xs={12}>
        <Grid item container xs={12} md={6}>
          <Grid item xs={6}>
            <Controller
              name="q"
              control={control}
              render={(cProps: any) => (
                <TextFieldSearch
                  sx={{ minHeight: 'unset' }}
                  placeholder={translate('filterRoleNameDescription') || ''}
                  value={cProps.field.value}
                  onChange={(e: any) => {
                    cProps.field.onChange(e.target.value)
                  }}
                  onClearText={() => cProps.field.onChange('')}
                  onKeyUp={(e: any) => {
                    if (e.target.value === '') {
                      handleSearch()
                    }
                  }}
                />
              )}
            />
          </Grid>

          <Divider
            orientation="vertical"
            sx={{
              height: '50%',
              borderColor: '#E0E0E0',
              margin: 'auto 8px'
            }}
          />

          <Grid item>
            <DropdownFilter
              key={key}
              propsPopper={{
                placement: 'bottom-start'
              }}
              propsPaper={{
                style: {
                  width: '600px'
                }
              }}
              button={
                <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
                  Filter
                </Button>
              }
              content={<FilterContent control={control} roles={portalRoleList} setValue={setValue} watch={watch} />}
              onResetFilter={() => {
                setValue('portalRoles', [])
              }}
              onResetChange={() => {
                reset({ ...filters, q })
              }}
              onApply={handleApplyFilter}
            />
          </Grid>
        </Grid>

        {hasFullOrgRolePerm && (
          <Grid item container xs={12} md={6} justifyContent="flex-end">
            <Button onClick={onCreateNewRole} variant="contained" color="primary">
              Add Role
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default RoleFilter
