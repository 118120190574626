import { OrganizationUser, UserActionProps } from '../type'
import UserRow from './UserRow'

interface UserListProps extends UserActionProps {
  users: OrganizationUser[]
  level: number
}

export const UserList = ({ users, level, onChangeRole, onRemoveUser, onSetSuperAdmin }: UserListProps) => {
  return (
    <>
      {users &&
        users.map((user: OrganizationUser) => (
          <UserRow
            key={user.id}
            user={user}
            level={level}
            onChangeRole={onChangeRole}
            onRemoveUser={onRemoveUser}
            onSetSuperAdmin={user.isAdmin ? onSetSuperAdmin : undefined}
          />
        ))}
    </>
  )
}
