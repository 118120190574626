import _get from 'lodash/get'
import { useMemo } from 'react'

import { useTypedSelector } from '../store'

export const useUserProfile = () => {
  const userProfile = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const getProfileProperty = useMemo(() => {
    return <T>(propertyPath: string, defaultValue: T): T => {
      const value = _get(userProfile, propertyPath, defaultValue)
      return value ? value : defaultValue
    }
  }, [userProfile])

  return useMemo(
    () => ({
      userProfile,
      getProfileProperty
    }),
    [userProfile, getProfileProperty]
  )
}
