import UserTab from './UserTab'
import { UserTabV2WithLoading } from './UserTabV2'

import { PERMISSIONS } from '@myopswat/common'

import _get from 'lodash/get'
import { useCheckPermission } from 'myopswat-web/src/hooks'

interface IProps {
  organization: any
  isFetching: boolean
  permissions: string[]
}

const UserListView = ({ organization, isFetching, permissions }: IProps) => {
  const { hasPermission } = useCheckPermission()
  const hasSubOrgUsersPerm =
    hasPermission(PERMISSIONS.VIEW_MY_SUBORG_USERS) || hasPermission(PERMISSIONS.FULL_MY_SUBORG_USERS)
  const hasSubOrganizations = _get(organization, 'ssoMeta.organizationData.subOrganizations', [])

  const hasSubOrgAccess = organization.isManageSubOrganization && hasSubOrganizations.length !== 0 && hasSubOrgUsersPerm

  return hasSubOrgAccess ? (
    <UserTabV2WithLoading isLoading={isFetching} organization={organization} />
  ) : (
    <UserTab organization={organization} isFetching={isFetching} permissions={permissions} />
  )
}

export default UserListView
