import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { commonResources } from '@myopswat/common'

import translationEn from '../locales/en/translation.json'
import informationEn from '../locales/en/information.json'

import translationVi from '../locales/vi/translation.json'
import informationVi from '../locales/vi/information.json'

const webResources = {
  en: { translation: translationEn, information: informationEn, ...commonResources.en },
  vi: { translation: translationVi, information: informationVi, ...commonResources.vi }
}

export type WebResources = (typeof webResources)['en']

i18n.use(initReactI18next).init({
  resources: webResources,
  lng: 'en', // Default
  fallbackLng: 'en', // Fallback
  interpolation: { escapeValue: false },
  ns: Object.keys(webResources.en),
  defaultNS: 'translation'
})

export default i18n
