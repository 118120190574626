import { Configuration } from '@botpress/webchat'

export enum CHAT_BOT_CUSTOM_EVENT {
  TOPIC_CHANGED = 'topicChanged',
  CONVERSATION = 'conversation',
  CONVERSATION_STARTED = 'conversationStarted',
  LIVE_CHAT_REQUESTED = 'liveChatRequested'
}

export enum CHAT_BOT_ERROR {
  CONVERSATION_CREATING = 'conversation_creating',
  DISCONNECTED = 'disconnected'
}

export const SUPPORT_CHATBOT_CONFIG: Configuration = {
  botName: ' ',
  botAvatar: `${process.env.REACT_APP_S3_ASSETS_URL}/images/chatbot_avatar.gif`,
  composerPlaceholder: 'Message AI Assistant',
  showPoweredBy: false
}

export const SUPPORT_CHATBOT_PLACEHOLDER = 'Need help or get support? Start here'

export const SUPPORT_CHATBOT_HELPER_TEXT = {
  ACTION: 'Try something like',
  KEYWORD_1: '"File scan stuck in processing state in Core"',
  KEYWORD_2: '"Does MetaDefender Kiosk support encrypted devices?"',
  SUBMIT_CASE: {
    template: "Didn't find what you need? <%= submitCase %>",
    value: 'Click here to submit a case'
  },
  SUBMIT_CASE_ERROR: {
    template: 'Our chat service is temporarily unavailable. Please <%= submitCase %> to reach out to us.',
    value: 'submit a case'
  }
}
