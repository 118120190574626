import RoleRow from './RoleRow'
import { RoleActionProps } from './types'

interface RolesViewProps extends RoleActionProps {
  roles: any[]
  level: number
  organization: any
}

export const RolesView = ({ permissions, roles, level, onDelete, onDetail, onEdit, organization }: RolesViewProps) => {
  return (
    <>
      {roles &&
        roles.map((role: any) => (
          <RoleRow
            permissions={permissions}
            key={role.role_id}
            role={role}
            level={level}
            onDelete={onDelete}
            onEdit={onEdit}
            onDetail={onDetail}
            organization={organization}
          />
        ))}
    </>
  )
}
