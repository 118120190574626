import { SolidRightIcon } from '@opswat/react-icon'
import { Box, Collapse, Grid, Typography, TypographyLineClamp } from '@opswat/react-ui'
import { RowProps } from 'myopswat-web/src/pages/MyInformationPage/General/RecursiveTree'

import { RolesView } from './RolesView'

const OrgRow = ({ permissions, node, level, collapsed, onToggle, onDelete, onDetail, onEdit }: RowProps) => {
  const { subOrgName, name, portalRoles, isUserBelonging, children, isSelected, id } = node
  const isDisplayCollapseIcon = (children && children.length > 0) || (portalRoles && portalRoles.length > 0)

  return (
    <>
      <Grid
        container
        sx={{
          padding: '4px',
          alignItems: 'center',
          borderBottom: '1px solid #E9EAEB',
          minHeight: '40px'
        }}
      >
        <Grid
          item
          onClick={onToggle}
          xs={4}
          sx={{
            display: 'flex',
            paddingLeft: `${level * 20}px`,
            cursor: isDisplayCollapseIcon ? 'pointer' : 'unset',
            '&:hover': isDisplayCollapseIcon && {
              opacity: 0.8
            }
          }}
        >
          <Box sx={{ display: 'inline-flex', width: '20px', minWidth: '20px', height: '20px' }}>
            {isDisplayCollapseIcon && (
              <Box
                component="span"
                style={{
                  transform: `rotate(${collapsed ? 0 : 90}deg)`,
                  transition: 'transform 0.15s ease'
                }}
              >
                <SolidRightIcon size={20} />
              </Box>
            )}
          </Box>

          <TypographyLineClamp
            fontWeight={500}
            color={'#1D6BFC'}
            line={1}
            variant="body2"
            tooltipValue={level == 0 ? name : subOrgName}
            tooltipPlacement="top"
            tooltipProps={{
              tooltip: {
                sx: {
                  color: '#1B273C',
                  backgroundColor: '#E9EAEB',
                  whiteSpace: 'pre-line'
                }
              },
              arrow: {
                sx: {
                  color: '#E9EAEB'
                }
              }
            }}
          >
            {level == 0 ? name : subOrgName}
          </TypographyLineClamp>
        </Grid>
        <Grid item container xs justifyContent="space-between">
          {isSelected && portalRoles && portalRoles.length === 0 && (
            <Grid item xs>
              <Typography variant="body2">There are no roles to display</Typography>
            </Grid>
          )}
          {!isUserBelonging && (
            <Grid item xs>
              <Typography variant="body2" sx={{ fontStyle: 'italic', textAlign: 'right' }}>
                no access permission
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        <RolesView
          permissions={permissions}
          roles={portalRoles}
          level={level}
          onDelete={onDelete}
          onDetail={onDetail}
          onEdit={onEdit}
          organization={{
            id: id,
            name: name
          }}
        />
      </Collapse>
    </>
  )
}

export default OrgRow
