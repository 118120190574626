import { useCallback } from 'react'

import { Button, TableCell } from '@opswat/react-ui'

import { PRODUCT_IDS, PRODUCT_LINKS } from 'myopswat-web/src/constants/product-ids'
interface ActionCellProps {
  data: any
  level: number
  onClick: () => void
}

export const ActionCell = ({ data, level, onClick }: ActionCellProps) => {
  const isFendDataDiodes = data.id === PRODUCT_IDS.FEND_DATA_DIODES

  const handleClick = useCallback(() => {
    if (isFendDataDiodes) {
      window.open(PRODUCT_LINKS[PRODUCT_IDS.FEND_DATA_DIODES], '_blank')
    } else {
      onClick()
    }
  }, [isFendDataDiodes, onClick])

  return (
    <TableCell
      sx={{
        padding: 0.25,
        minWidth: 150,
        textAlign: 'right'
      }}
    >
      {level === 0 ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          aria-label={isFendDataDiodes ? 'Open link' : 'Download'}
          sx={{ minWidth: 100 }}
        >
          {isFendDataDiodes ? 'Link' : 'Download'}
        </Button>
      ) : (
        <Button variant="contained" disabled sx={{ visibility: 'hidden' }} aria-hidden="true">
          Hidden
        </Button>
      )}
    </TableCell>
  )
}
