import React from 'react'

import { UserKeyIcon } from '@opswat/react-icon'
import { IconButton, Tooltip } from '@opswat/react-ui'

import { useDialog } from 'myopswat-web/src/hooks/useDialog'
import useGetPortalPermissions from 'myopswat-web/src/hooks/useGetPortalPermissions'
import useHasPermissions from 'myopswat-web/src/hooks/useHasPermissions'
import DialogUpdateCaseAccess from './OrganizationCaseAccess/DialogUpdateCaseAccess'

interface ExtendViewProps {
  orgId?: string
  hasOrg?: boolean
  orgCaseAccessInfo: any[]
  refreshData: (contactId: string) => Promise<void>
}

export const ExtendView: React.FC<ExtendViewProps> = ({ orgCaseAccessInfo, orgId, hasOrg, refreshData }) => {
  const { isOpen, openDialog, closeDialog } = useDialog()
  const { permissions } = useGetPortalPermissions()
  const hasFullSupportServicePerm = useHasPermissions({
    targetPerms: ['full_support_services'],
    userPerms: permissions
  })

  if (!hasOrg || !orgId) {
    return null
  }

  return (
    <>
      <Tooltip
        arrow
        title="Extended Case View"
        placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        <IconButton aria-label="user-key" onClick={openDialog}>
          <UserKeyIcon />
        </IconButton>
      </Tooltip>

      <DialogUpdateCaseAccess
        hasFullSupportServicePerm={hasFullSupportServicePerm}
        orgCaseAccessInfo={orgCaseAccessInfo}
        orgId={orgId}
        openDialog={isOpen}
        handleCloseDialog={closeDialog}
        refreshData={refreshData}
      />
    </>
  )
}
