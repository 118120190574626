import { useSnackbar } from 'notistack'
import React, { useMemo, useState } from 'react'

import {
  Box,
  CircularLoading,
  DataTable,
  Grid,
  RoundedSwitch,
  TextFieldSearch,
  Tooltip,
  TypographyLineClamp
} from '@opswat/react-ui'

import { useUpdateOrganizationCaseAccessMutation } from 'myopswat-web/src/api/support'
import { usePagination } from 'myopswat-web/src/hooks/usePagination'

import NoCaseAccessTable from './NoCaseAccessTable'

interface OrgAccessTableProps {
  orgId: string
  setIsUpdating: (isUpdating: boolean) => void
  data: any[]
  hasFullSupportServicePerm?: boolean
  refreshData: (contactId: string) => Promise<void>
}

export const OrgAccessTable: React.FC<OrgAccessTableProps> = ({
  data = [],
  orgId,
  setIsUpdating,
  refreshData,
  hasFullSupportServicePerm
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const [searchText, setSearchText] = useState<string>('')
  const [updatingContactId, setUpdatingContactId] = useState<string | null>(null)

  const [updateOrganizationCaseAccess] = useUpdateOrganizationCaseAccessMutation()

  const searchedData = useMemo(
    () =>
      data.filter(
        (item: any) =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.email.toLowerCase().includes(searchText.toLowerCase())
      ),
    [data, searchText]
  )

  const { currentPage, paginatedData, setPage, itemsPerPage, setItemsPerPage, totalItems } = usePagination<any>(
    searchedData,
    10
  )

  const handleUpdateCaseAccess = async (rowData: any) => {
    try {
      setIsUpdating(true)
      const contactId = rowData.contactId
      const newCaseAccess = !rowData.orgCaseAccess

      setUpdatingContactId(contactId)

      await updateOrganizationCaseAccess({ orgId, contactId, access: newCaseAccess }).unwrap()

      await refreshData(contactId)

      enqueueSnackbar('Organization case access updated successfully!', { variant: 'success' })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      setIsUpdating(false)
      setUpdatingContactId(null)
    }
  }

  const handleSearchChange = (value: string) => {
    setSearchText(value)
    setPage(0)
  }

  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    setPage(newPage)
    setItemsPerPage(newPageSize)
  }

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Full Name',
        Cell: ({ cell }: { cell: any }) => (
          <TypographyLineClamp line={1} tooltipValue={cell.getValue()}>
            {cell.getValue()}
          </TypographyLineClamp>
        )
      },
      {
        accessorKey: 'email',
        header: 'Email',
        style: { minWidth: 265, width: 265 },
        Cell: ({ cell }: { cell: any }) => (
          <TypographyLineClamp line={1} tooltipValue={cell.getValue()}>
            {cell.getValue()}
          </TypographyLineClamp>
        )
      },
      {
        accessorKey: 'orgCaseAccess',
        header: 'Organization Case Access',
        style: { minWidth: 'auto', width: 'auto' },
        size: 150,
        Cell: ({ cell }: { cell: any }) => {
          const rowData = cell.row.original
          return (
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item>
                <Tooltip
                  title={
                    !hasFullSupportServicePerm ? "You don't have permission to configure Organization Case Access" : ''
                  }
                  disableHoverListener={hasFullSupportServicePerm}
                >
                  <span>
                    <RoundedSwitch
                      checked={cell.getValue()}
                      onClick={() => handleUpdateCaseAccess(rowData)}
                      disabled={!hasFullSupportServicePerm || updatingContactId !== null}
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>{updatingContactId === rowData.contactId && <CircularLoading />}</Grid>
            </Grid>
          )
        }
      }
    ]
  }, [updatingContactId, hasFullSupportServicePerm])

  return (
    <>
      <Box sx={{ paddingTop: '16px', width: '50%' }}>
        <TextFieldSearch
          placeholder={'Search by full name, email'}
          value={searchText}
          onChange={e => handleSearchChange(e.target.value)}
          onClearText={() => handleSearchChange('')}
        />
      </Box>
      <DataTable
        enableStickyHeader
        columns={columns}
        data={paginatedData}
        isPaganization={true}
        onChangePage={handlePaginationChange}
        total={totalItems}
        page={currentPage}
        pageSize={itemsPerPage}
        noDataText={<NoCaseAccessTable columns={columns} />}
        noDataTextProps={{ sx: { my: 0, py: 0 } }}
        muiTableContainerProps={{
          sx: {
            maxHeight: {
              xs: 'calc(100vh - 279px)',
              lg: 'calc(100vh - 376px)'
            }
          }
        }}
      />
    </>
  )
}
