interface withLoadingProps {
  isLoading?: boolean
}

export const withLoading = <P,>(Component: React.ComponentType<P>, LoadingComponent: React.ComponentType) => {
  return (props: P & withLoadingProps) => {
    if (props.isLoading) return <LoadingComponent />
    return <Component {...props} />
  }
}
