import { FC, useEffect, useMemo } from 'react'

import {
  AutocompleteMultiple,
  Box,
  CheckboxWithLabel,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography,
  TypographyDivider,
  TypographyLink
} from '@opswat/react-ui'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _map from 'lodash/map'
import _some from 'lodash/some'

import { enqueueSnackbar } from 'notistack'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  useAccountSettingsUpdateMutation,
  useLazyProfileQuery,
  useLazyUserSettingsQuery
} from 'myopswat-web/src/api/account'
import { useLazySolutionsQuery } from 'myopswat-web/src/api/product'
import {
  saveEditMode,
  saveIsDirtyField,
  saveIsLoadingSubmit,
  saveSubmitMode,
  selectSubmitMode
} from 'myopswat-web/src/containers/MyInformationContainer/myInformationContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

import { EmailSubscriptionContent } from './components'
import { EMAIL_SUBSCRIPTIONS_FIELDS, UNSUBSCRIBED_ALL_CONTENT } from './constants'
import { SubscriptionForm, SubscriptionFormKeys } from './types'

interface IProps {
  userSettingsData: any
  solutionsData: any
  emailSubscriptionContent: any
  profileData: any
  showEmailSubscriptions: boolean
  isCustomer: boolean
  productOptions: any[]
}

const PreferencesEdit: FC<IProps> = ({
  userSettingsData,
  solutionsData,
  emailSubscriptionContent,
  profileData,
  showEmailSubscriptions,
  isCustomer,
  productOptions
}) => {
  const { t: translate } = useTranslation(['translation', 'information'])
  const dispatch = useAppDispatch()
  const submitMode = useTypedSelector(selectSubmitMode)

  const [getProfile] = useLazyProfileQuery()
  const [getUserSettings] = useLazyUserSettingsQuery()
  const [getSolutions] = useLazySolutionsQuery()
  const [accountSettingsUpdate] = useAccountSettingsUpdateMutation()

  const defaultValues = useMemo(() => {
    const subscribed_email = _get(userSettingsData, 'subscribedEmail')
    const solutionIds: number[] = []
    solutionsData.forEach((solution: any) => {
      if (solution.subscribed) {
        solutionIds.push(solution.id)
      }
    })
    return {
      update: _get(subscribed_email, 'update', false),
      newsletter: _get(subscribed_email, 'newsletter', false),
      blog: _get(subscribed_email, 'blog', false),
      productRelease: _get(subscribed_email, 'productRelease', false),
      sendAllPurchasedProducts: String(_get(subscribed_email, 'sendAllPurchasedProducts', true)),
      selectedProductIds: _get(subscribed_email, 'selectedProductIds', []),
      unsubscribedAll: _get(subscribed_email, 'unsubscribedAll', false),
      academyNewsletter: _get(subscribed_email, 'academyNewsletter'),
      consented: _get(subscribed_email, 'consented', false),
      solutionIds: solutionIds,
      enabledAi: _get(profileData, 'enabledAi', false),
      isIdaasConsent: _get(profileData, 'isIdaasConsent', false)
    }
  }, [solutionsData, userSettingsData])

  const { control, setValue, handleSubmit, formState, watch } = useForm<SubscriptionForm>({
    defaultValues,
    mode: 'onChange'
  })

  const { dirtyFields, isDirty, errors } = formState

  const handleAccountSettingsUpdate = async (data: any) => {
    try {
      dispatch(saveIsLoadingSubmit(true))

      const response = await accountSettingsUpdate(data).unwrap()

      if (response?.success) {
        dispatch(saveIsLoadingSubmit(false))

        enqueueSnackbar(translate('updateYourSettingSuccess'), {
          variant: 'success'
        })
        getUserSettings()
        getProfile()
        getSolutions()
        dispatch(saveEditMode(''))
      } else {
        dispatch(saveIsLoadingSubmit(false))

        enqueueSnackbar(translate('updateYourSettingFail'), {
          variant: 'error'
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSuccess = (data: any) => {
    const isCanSubmit =
      (data?.unsubscribedAll && !data?.consented) ||
      (!data?.unsubscribedAll && data?.consented) ||
      (!data?.unsubscribedAll &&
        !data?.consented &&
        !data?.update &&
        !data?.newsletter &&
        !data?.blog &&
        !data?.productRelease)

    // Handle update email subscribed settings
    const subscribed_email_payload: any = {}
    const emailSubscriptionsFields: SubscriptionFormKeys[] = [
      ...EMAIL_SUBSCRIPTIONS_FIELDS,
      'sendAllPurchasedProducts',
      'selectedProductIds',
      'unsubscribedAll',
      'consented'
    ]

    emailSubscriptionsFields.forEach(field => {
      if (field === 'sendAllPurchasedProducts') subscribed_email_payload[field] = eval(data[field])
      else if (field === 'selectedProductIds') {
        subscribed_email_payload[field] = data[field].map((item: any) => item.value)
      } else if (dirtyFields[field]) {
        subscribed_email_payload[field] = data[field]
      }
    })
    if (data['productRelease'] === false) {
      delete subscribed_email_payload['sendAllPurchasedProducts']
      delete subscribed_email_payload['selectedProductIds']
    }

    const accountSettingsPayload: any = {}

    if (showEmailSubscriptions && !_isEmpty(subscribed_email_payload) && isCanSubmit) {
      const payloadKeys = Object.keys(subscribed_email_payload)
      const defaultSubscribedEmail = _get(userSettingsData, 'subscribedEmail')
      const isUpdated = !payloadKeys.every(
        key =>
          Object.prototype.hasOwnProperty.call(defaultSubscribedEmail, key) &&
          defaultSubscribedEmail[key] === subscribed_email_payload[key]
      )
      if (isUpdated) accountSettingsPayload.subscribedEmail = subscribed_email_payload
    }

    if (dirtyFields.solutionIds && !_isEqual(data.solutionIds.sort(), defaultValues.solutionIds.sort())) {
      accountSettingsPayload.solutionIds = data.solutionIds
    }

    if (dirtyFields?.enabledAi) {
      accountSettingsPayload.enabledAi = data?.enabledAi
    }

    if (dirtyFields?.isIdaasConsent) {
      accountSettingsPayload.isIdaasConsent = data?.isIdaasConsent
    }

    if (Object.keys(accountSettingsPayload).length > 0) {
      handleAccountSettingsUpdate(accountSettingsPayload)
    }

    dispatch(saveSubmitMode(''))
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  const handleSelectSolution = (current: number[], solution_id: number, checked: boolean) => {
    let newStatus
    if (checked) {
      newStatus = [...current, solution_id]
    } else {
      newStatus = current.filter(s => s !== solution_id)
    }
    return newStatus
  }

  const emailSubscriptions = useWatch({
    control: control,
    name: EMAIL_SUBSCRIPTIONS_FIELDS
  })
  const isSubscriptionsCheck = _some(emailSubscriptions, item => item === true)
  const isAllSubscriptionsUncheck = emailSubscriptions.every(check => !check)

  const handleUnScribedAll = () => {
    setValue('update', false, { shouldDirty: true })
    setValue('newsletter', false, { shouldDirty: true })
    setValue('blog', false, { shouldDirty: true })
    setValue('productRelease', false, { shouldDirty: true })
    setValue('academyNewsletter', false, { shouldDirty: true })
    setValue('consented', false, { shouldDirty: true })
  }

  useEffect(() => {
    if (isAllSubscriptionsUncheck && !_isEmpty(dirtyFields) && !watch('unsubscribedAll')) {
      setValue('unsubscribedAll', false, { shouldDirty: true })
    }

    if (isSubscriptionsCheck && !_isEmpty(dirtyFields)) {
      setValue('unsubscribedAll', true, { shouldDirty: true })
    }

    if (isSubscriptionsCheck && !_isEmpty(dirtyFields)) {
      setValue('unsubscribedAll', false, { shouldDirty: true })
    }
  }, [emailSubscriptions])

  useEffect(() => {
    if (watch('unsubscribedAll') && !_isEmpty(dirtyFields)) {
      handleUnScribedAll()
    }
  }, [watch('unsubscribedAll')])

  useEffect(() => {
    if (watch('sendAllPurchasedProducts') === 'true') {
      setValue('selectedProductIds', [])
    }
  }, [watch('sendAllPurchasedProducts')])

  useEffect(() => {
    if (submitMode.includes('preferences')) {
      handleSubmit(onSuccess, onFail)()
    }
  }, [submitMode])

  useMemo(() => {
    dispatch(saveIsDirtyField(isDirty))
  }, [isDirty])

  useEffect(() => {
    !isCustomer && setValue('sendAllPurchasedProducts', 'false')
  }, [isCustomer])

  useEffect(() => {
    productOptions &&
      setValue(
        'selectedProductIds',
        productOptions
          .filter((item: any) => watch('selectedProductIds').includes(item.id))
          .map((item: any) => ({ label: item.name, value: item.id }))
      )
  }, [productOptions])

  return (
    <Grid container spacing={6}>
      {showEmailSubscriptions && (
        <Grid item xs={12} md={10} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyDivider label="Email Subscriptions" propsTypo={{ variant: 'body1' }} />
            </Grid>

            <Grid
              item
              xs={12}
              container
              spacing={2}
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)'
              }}
            >
              {_map(Object.entries(emailSubscriptionContent), ([key, value]: any) => (
                <Grid
                  item
                  key={key}
                  sx={{
                    gridRow: `span ${['productRelease', 'blog'].includes(key) ? 2 : 1}`,
                    gridColumn: 'span 1'
                  }}
                >
                  <Controller
                    name={`${key}` as keyof SubscriptionForm}
                    control={control}
                    render={({ field }) => (
                      <CheckboxWithLabel
                        restProps={{ sx: { alignItems: 'flex-start' } }}
                        label={<EmailSubscriptionContent title={value.title!} description={value.description} />}
                        checkboxProps={{
                          checked: !!field.value,
                          onChange: (e: any) => {
                            field.onChange(e.target.checked)
                          }
                        }}
                      />
                    )}
                  />
                  {key === 'productRelease' && watch('productRelease') && (
                    <Box sx={{ padding: '8px 24px' }}>
                      <Controller
                        name="sendAllPurchasedProducts"
                        control={control}
                        render={({ field }) => (
                          <RadioGroup {...field} sx={{ px: 0.5 }}>
                            <FormControlLabel
                              disabled={!isCustomer}
                              value={'true'}
                              control={
                                <Radio
                                  sx={{
                                    padding: '5px'
                                  }}
                                  size="small"
                                />
                              }
                              label={
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={!isCustomer && 'Purchase OPSWAT products to enable this option'}
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        color: '#1B273C',
                                        backgroundColor: '#E9EAEB'
                                      }
                                    },
                                    arrow: {
                                      sx: {
                                        color: '#E9EAEB'
                                      }
                                    }
                                  }}
                                >
                                  <Typography variant="body2" color={isCustomer ? '#1B273C' : '#A4A8AE'}>
                                    Only products for which you hold licenses
                                  </Typography>
                                </Tooltip>
                              }
                            />
                            <FormControlLabel
                              value={'false'}
                              sx={{ mb: 1 }}
                              control={
                                <Radio
                                  sx={{
                                    padding: '5px'
                                  }}
                                  size="small"
                                />
                              }
                              label={<Typography variant="body2">Customize options</Typography>}
                            />
                          </RadioGroup>
                        )}
                      />
                      {watch('sendAllPurchasedProducts') === 'false' && (
                        <Controller
                          name="selectedProductIds"
                          control={control}
                          rules={{
                            required: 'Please select at least 1 product'
                          }}
                          render={({ field }) => (
                            <AutocompleteMultiple
                              placeholder="Select Products"
                              options={
                                productOptions?.map((option: any) => ({
                                  label: option.name,
                                  value: option.id
                                })) ?? []
                              }
                              errors={_get(errors, ['selectedProductIds', 'message'], '')}
                              {...field}
                            />
                          )}
                        />
                      )}
                    </Box>
                  )}
                  {key === 'academyNewsletter' && (
                    <Controller
                      name="unsubscribedAll"
                      control={control}
                      defaultValue={false}
                      render={(cProps: any) => (
                        <CheckboxWithLabel
                          label={
                            <EmailSubscriptionContent
                              title={UNSUBSCRIBED_ALL_CONTENT.title}
                              description={UNSUBSCRIBED_ALL_CONTENT.description}
                            />
                          }
                          checkboxProps={{
                            checked: !!cProps.field.value,
                            onChange: (e: any) => {
                              cProps.field.onChange(e.target.checked)
                            }
                          }}
                          restProps={{ sx: { alignItems: 'flex-start', mt: 2 } }}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} sm={10}>
              <Controller
                name="consented"
                control={control}
                defaultValue={false}
                rules={{
                  validate: () => {
                    if (isSubscriptionsCheck) return watch('consented')
                  }
                }}
                render={(cProps: any) => (
                  <CheckboxWithLabel
                    label={
                      <Typography variant="body2">
                        I consent to receive communications from OPSWAT. I understand that OPSWAT will process data
                        about me as described in the&nbsp;
                        <TypographyLink
                          href={`${process.env.REACT_APP_OPSWAT_URL}/legal/privacy-policy`}
                          target="_blank"
                        >
                          Privacy Policy
                        </TypographyLink>
                        &nbsp;and that I can opt-out at any time by using the unsubscribe link in messages I receive or
                        managing my email preferences here.
                      </Typography>
                    }
                    checkboxProps={{
                      checked: !!cProps.field.value,
                      onChange: (e: any) => {
                        cProps.field.onChange(e.target.checked)
                      }
                    }}
                    restProps={{ sx: { alignItems: 'flex-start' } }}
                  />
                )}
              />
              {!watch('consented') && isSubscriptionsCheck && (
                <Typography variant="caption" color="error" sx={{ ml: 2.5 }}>
                  Please select the checkbox above.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} md={10} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TypographyDivider label="Interested topic" propsTypo={{ variant: 'body1' }} />
            <Typography variant="caption" color="textSecondary">
              Interested topic are used to provide relevant content of Product Updates on Home page.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Controller
                name={'solutionIds'}
                control={control}
                render={({ field }) => (
                  <>
                    {_map(solutionsData, (item, idx) => (
                      <Grid item xs={12} sm={6} key={idx.toString()}>
                        <CheckboxWithLabel
                          label={<Typography variant="body2">{_get(item, 'name', '--')}</Typography>}
                          checkboxProps={{
                            checked: field.value.includes(item.id),
                            onChange: (e: any) =>
                              field.onChange(handleSelectSolution(field.value, item.id, e.target.checked))
                          }}
                        />
                      </Grid>
                    ))}
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={10} lg={8}>
        <Grid container>
          <Grid item xs={12}>
            <TypographyDivider
              label={
                <Box display="flex" alignItems="center">
                  My OPSWAT AI Cyber Adviser&ensp;
                  <Box
                    sx={{
                      backgroundColor: '#3D4A68',
                      color: 'white',
                      borderRadius: '4px',
                      px: 1
                    }}
                  >
                    BETA
                  </Box>
                </Box>
              }
              propsTypo={{ variant: 'body1', component: 'div' }}
            />
            <Typography variant="caption" color="textSecondary">
              My OPSWAT AI Cyber Adviser helps you learn more about OPSWAT Products and industry knowledge. This feature
              is available in Home &rsaquo; Product Details page.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name={'enabledAi'}
              control={control}
              render={(cProps: any) => (
                <Switch
                  checked={!!cProps.field.value}
                  label={
                    cProps.field.value ? 'Enable My OPSWAT AI Cyber Adviser' : 'Disable My OPSWAT AI Cyber Adviser'
                  }
                  handleChange={(e: any) => cProps.field.onChange(e)}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={10} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TypographyDivider label="IDaaS Consent" propsTypo={{ variant: 'body1' }} />
            <Typography variant="caption" color="textSecondary">
              {translate('information:IDaaSConsent')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="isIdaasConsent"
              control={control}
              render={({ field }) => (
                <CheckboxWithLabel
                  label={
                    <Typography variant="body2">
                      I agree to the OPSWAT Inc.
                      <a href="https://www.opswat.com/legal" target="_blank">
                        Terms of Service and Privacy Policy
                      </a>
                      , unless my organization has a separate written agreement with OPSWAT Inc., in which case those
                      separate terms shall apply
                    </Typography>
                  }
                  checkboxProps={{
                    checked: !!field.value,
                    onChange: (e: any) => {
                      field.onChange(e.target.checked)
                    }
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PreferencesEdit
