import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Grid, Typography } from '@opswat/react-ui'

import {
  useLazySupportCaseProductsQuery,
  useLazySupportCaseSuggestionsQuery,
  useSubmitSupportCaseMutation,
  useSupportCasePreferredPartnersQuery
} from 'myopswat-web/src/api/support/submit-case'
import { ISupportCaseCreateV2Input } from 'myopswat-web/src/api/support/submit-case/types'
import {
  setIsDirtySubmitCase,
  setSubmitCaseData,
  setSubmitCaseSuccessful
} from 'myopswat-web/src/containers/SupportServicesContainer/supportServicesContainerSlice'
import { supportServicesPageURL } from 'myopswat-web/src/routes'
import { useAppDispatch } from 'myopswat-web/src/store'

import useGetPortalPermissions from '../../hooks/useGetPortalPermissions'
import useHasPermissions from '../../hooks/useHasPermissions'
import DialogUploadSupportPackage from './dialogs/DialogUploadSupportPackage'

import SupportSubmitCasePage from '.'
import {
  CONTACT_ID_NOT_FOUND_ERROR_CODE,
  SUBMIT_CASE_OPTION,
  SUBMIT_CASE_OTHER_PRODUCT_OPTION,
  SUBMIT_CASE_PLATFORM
} from './constants'
import { SupportSubmitCaseContext } from './interface'

const SupportSubmitCaseProvider: React.FC<any> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { permissions } = useGetPortalPermissions()
  const hasFullMyCustomerCasesPerm = useHasPermissions({
    targetPerms: ['full_my_customer_cases'],
    userPerms: permissions
  })

  const [getSupportCaseProducts, { data: supportCaseProducts, isFetching: isFetchingSupportCaseProducts }] =
    useLazySupportCaseProductsQuery()
  const { data: supportCasePreferredPartners, isFetching: isFetchingSupportCasePreferredPartners } =
    useSupportCasePreferredPartnersQuery()
  const [getSupportCaseSuggestions, { data: supportCaseSuggestions, isFetching: isFetchingSupportCaseSuggestions }] =
    useLazySupportCaseSuggestionsQuery()
  const [submitSupportCase] = useSubmitSupportCaseMutation()

  const validationSchema = yup.object().shape({
    issueType: yup.string().trim().required('Issue Type cannot be blank'),
    severity: yup.string().trim().required('Severity cannot be blank'),
    subject: yup.string().trim().required('Subject cannot be blank'),
    description: yup.string().trim().required('Description cannot be blank'),
    preferredPartnerId: yup.object().required('Organization cannot be blank'),
    productId: yup
      .mixed()
      .nullable()
      .test('licenseKey', 'Product cannot be blank', (value: any) => !_isEmpty(value)),
    licenseKey: yup
      .mixed()
      .nullable()
      .test('licenseKey', 'License Key cannot be blank', (value: any) => !_isEmpty(value)),
    productVersion: yup
      .mixed()
      .nullable()
      .test('productVersion', 'Product Version cannot be blank', (value: any) => !_isEmpty(value)),
    platform: yup.string().when('productId', {
      is: (val: any) => {
        return _get(val, 'value', val) === SUBMIT_CASE_OPTION.OTHER
      },
      then: schema => schema,
      otherwise: schema => schema.required('Platform cannot be blank')
    })
  })

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    trigger,
    formState: { isDirty }
  } = useForm<ISupportCaseCreateV2Input>({
    defaultValues: {
      productId: '',
      platform: '',
      issueType: '',
      severity: '',
      subject: '',
      description: '',
      licenseKey: null,
      productVersion: null,
      preferredPartnerId: null,
      isCancel: false,
      isResolved: false
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })

  const [currentView, setCurrentView] = useState<number>(0)
  const [completedFormSteps, setCompletedFormSteps] = useState<Set<number>>(new Set<number>([]))
  const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false)
  const [hasUploadedPackage, setHasUploadedPackage] = useState<boolean>(false)
  const [caseId, setCaseId] = useState<string>('')
  const [caseNumber, setCaseNumber] = useState<string>('')
  const [showMoreProducts, setShowMoreProducts] = useState<boolean>(false)

  const handleNextView = () => {
    setCurrentView(prev => prev + 1)
  }

  const handleCompleteFormStep = (step: number) => {
    setCompletedFormSteps(prev => new Set(prev.add(step)))
  }

  const handleSelectProduct = (value: any) => {
    let productId = value
    if (typeof value === 'object') {
      productId = value?.value ?? ''
    }

    if (_isEmpty(watch('issueType'))) {
      setCompletedFormSteps(prev => {
        prev.delete(2)
        return new Set(prev)
      })
    }

    if (productId === SUBMIT_CASE_OPTION.OTHER) {
      handleSelectPlatform('')
    }

    const platforms = supportCaseProducts.find((item: any) => item.id === productId)?.supportCasePlatforms ?? []
    if (platforms.includes(SUBMIT_CASE_PLATFORM.NOT_APPLICABLE)) {
      handleSelectPlatform(SUBMIT_CASE_PLATFORM.NOT_APPLICABLE)
    } else {
      setValue('platform', '')
    }

    setValue('productId', value, { shouldDirty: true })
    setValue('licenseKey', '')
    setValue('productVersion', '')
    trigger('platform')
    handleCompleteFormStep(1)
  }

  const handleSelectPlatform = (value: any) => {
    setValue('platform', value, { shouldDirty: true })
    handleCompleteFormStep(2)
  }

  const handleSelectIssueType = (value: any) => {
    setValue('issueType', value, { shouldDirty: true })
    handleCompleteFormStep(3)
  }

  const handleSelectSeverity = (value: any) => {
    setValue('severity', `Severity ${value}`, { shouldDirty: true })
    handleCompleteFormStep(4)
  }

  const handleMapRequest = (values: ISupportCaseCreateV2Input) => {
    const request: any = {
      ...values,
      licenseKey: _get(values, ['licenseKey', 'value'], values.licenseKey),
      productVersion: _get(values, ['productVersion', 'value'], values.productVersion),
      productId: _get(values, ['productId', 'value'], values.productId),
      fromChatbot: location.state?.fromChatbot ?? false
    }

    const preferredPartner = _get(values, ['preferredPartnerId', 'value'], '')
    if (_get(values, ['preferredPartnerId', 'label'], '').includes('My Organization')) {
      request.preferredPartnerId = ''
    } else {
      request.preferredPartnerId = preferredPartner
    }

    if (_isEmpty(request.platform)) {
      delete request['platform']
    }

    return request
  }

  const handleSubmitCase = useCallback(() => {
    const values = watch()
    const request = handleMapRequest(values)

    handleNextView()
    submitSupportCase(request)
      .then(response => {
        if (_get(response, ['data', 'success'])) {
          setCaseId(_get(response, ['data', 'caseId'], ''))
          setCaseNumber(_get(response, ['data', 'caseNumber'], ''))
          handleNextView()
          dispatch(setSubmitCaseSuccessful(true))
        } else {
          const errorCode = _get(response, ['data', 'errors', 0, 'codeName'], null)
          const statusCode = _get(response, ['error', 'statusCode'], '')
          const errorMessage =
            errorCode !== CONTACT_ID_NOT_FOUND_ERROR_CODE
              ? 'Submitting your case has failed. Please give the system a moment then try again.'
              : "Your data is being initialized and will take around 4-5 hours. \nPlease try signing in later to check if it's ready. \nThank you for your patience!"
          if (statusCode != 403) {
            enqueueSnackbar(errorMessage, {
              variant: 'error'
            })
          }
          setCurrentView(0)
          if (
            supportCaseProducts?.length > 11 &&
            !supportCaseProducts.slice(0, 11).some((item: any) => item.id === request.productId)
          ) {
            setShowMoreProducts(true)
          }
        }
      })
      .catch(() => {
        enqueueSnackbar('Submitting your case has failed. Please give the system a moment then try again.', {
          variant: 'error'
        })
        setCurrentView(0)
      })
  }, [watch, supportCaseProducts])

  const handleCancelSubmitCase = () => {
    const values = watch()
    const request = handleMapRequest(values)

    submitSupportCase({ ...request, isCancel: true }).then(() => {
      navigate(supportServicesPageURL)
    })
  }

  const handleResolveSubmitCase = () => {
    const values = watch()
    const request = handleMapRequest(values)

    submitSupportCase({ ...request, isResolved: true }).then(() => {
      navigate(supportServicesPageURL)
    })
  }

  const handleGetKBSuggestions = useCallback((keyword: string, productId: string) => {
    return getSupportCaseSuggestions({ keyword, productId }).unwrap()
  }, [])

  const handleGoBack = () => {
    navigate(supportServicesPageURL)
  }

  const handleOpenDialogUpload = () => {
    setOpenUploadDialog(true)
  }

  const handleCloseDialogUpload = () => {
    setOpenUploadDialog(false)
  }

  useEffect(() => {
    dispatch(setSubmitCaseData(handleMapRequest(watch())))
  }, [
    watch('description'),
    watch('issueType'),
    watch('severity'),
    watch('subject'),
    watch('platform'),
    watch('productId'),
    watch('licenseKey'),
    watch('productVersion'),
    watch('preferredPartnerId')
  ])

  useEffect(() => {
    dispatch(setIsDirtySubmitCase(isDirty))
  }, [isDirty])

  useEffect(() => {
    if (supportCasePreferredPartners?.length === 1) {
      // if the user has no preferred partners and only one organization
      // set the first one as default
      const currentOrg = supportCasePreferredPartners[0]
      setValue('preferredPartnerId', { value: currentOrg.id, label: `My Organization (${currentOrg.name})` } as any)
    } else if (supportCasePreferredPartners === null) {
      // if the user has no preferred partners and no organizations
      setValue('preferredPartnerId', { value: '', label: '' } as any)
    }
  }, [supportCasePreferredPartners])

  useEffect(() => {
    const preferredPartnerId: any = watch('preferredPartnerId')
    if (!_isEmpty(preferredPartnerId)) {
      getSupportCaseProducts(preferredPartnerId.value)
      handleCompleteFormStep(0)
    }
  }, [watch('preferredPartnerId')])

  const contextValue = useMemo(
    () => ({
      control,
      organizationList: supportCasePreferredPartners ?? [],
      isLoadingOrganizationList: isFetchingSupportCasePreferredPartners,
      productList: supportCaseProducts ? [...supportCaseProducts, SUBMIT_CASE_OTHER_PRODUCT_OPTION] : [],
      isLoadingProductList: isFetchingSupportCaseProducts,
      suggestionList: supportCaseSuggestions ?? [],
      isLoadingSuggestionList: isFetchingSupportCaseSuggestions,
      hasFullMyCustomerCasesPerm,
      currentView,
      caseId,
      caseNumber,
      completedFormSteps,
      hasUploadedPackage,
      setHasUploadedPackage,
      setValue,
      trigger,
      showMoreProducts,
      setShowMoreProducts,
      handleSubmit,
      handleSelectProduct,
      handleSelectPlatform,
      handleSelectIssueType,
      handleSelectSeverity,
      handleSubmitCase,
      handleNextView,
      handleOpenDialogUpload,
      handleCancelSubmitCase,
      handleResolveSubmitCase,
      handleGetKBSuggestions,
      handleGoBack
    }),
    [
      control,
      currentView,
      caseId,
      caseNumber,
      completedFormSteps,
      supportCasePreferredPartners,
      isFetchingSupportCasePreferredPartners,
      supportCaseProducts,
      isFetchingSupportCaseProducts,
      supportCaseSuggestions,
      isFetchingSupportCaseSuggestions,
      hasUploadedPackage,
      showMoreProducts,
      setValue,
      handleSubmit
    ]
  )

  return (
    <SupportSubmitCaseContext.Provider value={contextValue}>
      {currentView < 2 && !isFetchingSupportCaseSuggestions && (
        <Grid item xs={12}>
          <Typography variant="body2" color="#1B273C">
            Need help with OPSWAT Products and Services? Get expert assistance with our technical support options.
          </Typography>
        </Grid>
      )}
      <SupportSubmitCasePage />
      {openUploadDialog && (
        <DialogUploadSupportPackage
          caseId={caseId}
          caseNumber={caseNumber}
          control={control}
          openDialog={openUploadDialog}
          setValue={setValue}
          handleCloseDialog={handleCloseDialogUpload}
          setHasUploadedPackage={setHasUploadedPackage}
        />
      )}
    </SupportSubmitCaseContext.Provider>
  )
}
export default SupportSubmitCaseProvider
