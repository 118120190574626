import { FC, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { PERMISSIONS } from '@myopswat/common'
import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import { Button, DropdownFilter, Grid, TextFieldSearch } from '@opswat/react-ui'

import { OrganizationUserFilterInput } from 'myopswat-web/src/api/organization/types'
import { useCheckPermission, useGenericDebouncedHandler } from 'myopswat-web/src/hooks'

import { FilterActions, FilterContent } from './Filter'

interface IProps {
  roles: any[]
  query: OrganizationUserFilterInput
  onSearch: (searchData: OrganizationUserFilterInput) => void
  onInviteUser: () => void
  onCreateSubOrganization: () => void
  disabledCreateSubOrg: boolean
}

const UserFilter: FC<IProps> = ({
  roles,
  query,
  onSearch,
  onInviteUser,
  onCreateSubOrganization,
  disabledCreateSubOrg
}) => {
  const { hasPermission } = useCheckPermission()
  const hasFullOrgUsersPerm = hasPermission(PERMISSIONS.FULL_MY_ORGANIZATIONS_USERS)
  const hasFullSubOrgUsersPerm = hasPermission(PERMISSIONS.FULL_MY_SUBORG_USERS)

  const { control, reset, getValues, watch, setValue } = useForm<OrganizationUserFilterInput>({
    mode: 'onSubmit',
    defaultValues: query,
    values: query
  })

  const q = watch('q')

  const { handleInputChange, handleClearText } = useGenericDebouncedHandler<OrganizationUserFilterInput>(
    onSearch,
    getValues,
    setValue
  )

  const handleResetFilter = useCallback(() => {
    reset({
      q: q,
      status: [],
      portalRoles: []
    })
  }, [reset, q])

  const handleResetChange = useCallback(() => {
    reset({ ...query, q })
  }, [reset, query, q])

  const handleApplyFilter = useCallback(() => {
    onSearch(getValues())
  }, [onSearch, getValues])

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={cProps => (
            <TextFieldSearch
              placeholder={'Search by Full Name or Email'}
              value={cProps.field.value}
              onChange={handleInputChange('q')}
              onClearText={handleClearText('q')}
            />
          )}
        />
      </Grid>

      <Grid item xs="auto">
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <DropdownFilter
              propsPaper={{
                style: {
                  width: '600px'
                }
              }}
              button={
                <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
                  Filter
                </Button>
              }
              content={
                <FilterContent hasStatusFilter control={control} roles={roles} setValue={setValue} watch={watch} />
              }
              onResetFilter={handleResetFilter}
              onResetChange={handleResetChange}
              onApply={handleApplyFilter}
            />
          </Grid>

          <FilterActions
            hasExportPermission={false}
            hasCreateOrganizationPermission={hasFullOrgUsersPerm && hasFullSubOrgUsersPerm}
            hasInviteUserPermission={hasFullOrgUsersPerm}
            isDisabledCreateSubOrg={disabledCreateSubOrg}
            onCreateSubOrganization={onCreateSubOrganization}
            onInviteUser={onInviteUser}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UserFilter
