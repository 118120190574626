import { useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'

import { Box, Grid, TabAdvanced } from '@opswat/react-ui'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import {
  useLazyCheckOrganizationSupportTabQuery,
  useLazySupportExtendCaseViewInfoQuery
} from 'myopswat-web/src/api/support'
import { ISupportCaseV2Filters } from 'myopswat-web/src/api/support/types'
import { useUserProfile } from 'myopswat-web/src/hooks'

import { CASE_TABLE_TABS, MY_CASES_TAB, SupportCaseTypes } from '../constant'
import CasesFilters from './CasesFilters'
import CasesTable from './CaseTable'

interface IProps {
  isMiniTable?: boolean
}

const SupportServiceCaseTable: React.FC<IProps> = ({ isMiniTable }) => {
  const { getProfileProperty } = useUserProfile()

  const userContactId = getProfileProperty('sfdcContactId', '')
  const currentOrganizationId = getProfileProperty('currentOrganizationId', '')
  const usingOrganizationId = getProfileProperty('usingOrganizationId', '')
  const hasOrganization = !_isEmpty(currentOrganizationId || usingOrganizationId)

  const [activeTab, setActiveTab] = useState(0)
  const [pageTitle, setPageTitle] = useState<string>('')
  const [filters, setFilters] = useState<ISupportCaseV2Filters>({
    caseType: _get(CASE_TABLE_TABS, `${activeTab}.value`) ?? SupportCaseTypes.MY_CASES
  })

  const [getOrganizationAccessCaseInfo, { data: orgCaseAccessInfo = [], isLoading: isOrgAccessLoading }] =
    useLazySupportExtendCaseViewInfoQuery()
  const [checkOrganizationSupportTab, { data: supportTab }] = useLazyCheckOrganizationSupportTabQuery()

  useEffect(() => {
    handleRefreshOrgAccessInfo()
    checkOrganizationSupportTab({})
  }, [])

  const handleRefreshOrgAccessInfo = useCallback(async () => {
    await getOrganizationAccessCaseInfo({})
  }, [getOrganizationAccessCaseInfo])

  const forceReloadTable = useRef(false)
  const [tabArrays, setTabArrays] = useState([MY_CASES_TAB])

  useEffect(() => {
    if (!supportTab) {
      return
    }

    const { hasOrganizationTab, hasCustomerTab } = supportTab
    const newTabs = CASE_TABLE_TABS.filter(tab => {
      // Organization Case list will be displayed for the user if the user has extended case view permission and user is in an organization
      if (tab.value === SupportCaseTypes.ORG_CASES && !hasOrganizationTab) {
        return false
      }

      // The Customer Case list will be displayed if my Organization has at least one linked customer with a “Preferred Partner” relationship
      if (tab.value === SupportCaseTypes.CUSTOMER_CASES && !hasCustomerTab) {
        return false
      }

      return true
    })

    if (forceReloadTable.current) {
      const newActiveTab = newTabs.findIndex(
        tab => tabArrays[activeTab]?.value && tab.value === tabArrays[activeTab].value
      )
      const newTab = newActiveTab !== -1 ? newActiveTab : 0

      setActiveTab(newTab)
      setFilters({
        caseType: _get(newTabs, `${newTab}.value`) ?? SupportCaseTypes.MY_CASES
      })

      forceReloadTable.current = false
    }

    setTabArrays(newTabs)
  }, [supportTab])

  const handleTabChange = useCallback(
    (newTab: number) => {
      setActiveTab(newTab)
      setFilters({
        caseType: _get(tabArrays, `${newTab}.value`) ?? SupportCaseTypes.MY_CASES
      })

      if (!isMiniTable) {
        setPageTitle(_get(tabArrays, `${newTab}.pageTitle`, CASE_TABLE_TABS[0].pageTitle))
      }
    },
    [tabArrays, isMiniTable]
  )

  const handleRefreshDataData = async (contactId: string) => {
    if (
      userContactId === contactId &&
      tabArrays[activeTab]?.value &&
      tabArrays[activeTab].value !== SupportCaseTypes.MY_CASES
    ) {
      forceReloadTable.current = true
    }

    await checkOrganizationSupportTab({})
    await handleRefreshOrgAccessInfo()
  }

  useEffect(() => {
    if (!isMiniTable) {
      setPageTitle(CASE_TABLE_TABS[0].pageTitle)
    }
  }, [])

  return (
    <Box>
      {pageTitle && (
        <Helmet encodeSpecialCharacters={true} titleTemplate="%s - My OPSWAT" defer={false}>
          <title itemProp="name" lang="en">
            {pageTitle}
          </title>
        </Helmet>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TabAdvanced
            defaultTab={activeTab}
            borderBottom={'1px solid #E9EAEB'}
            tabs={tabArrays}
            emitActiveTabValue={handleTabChange}
          />

          {userContactId && (
            <CasesFilters
              filters={filters}
              setCaseFilters={setFilters}
              hasOrg={hasOrganization}
              orgId={currentOrganizationId}
              orgCaseAccessInfo={orgCaseAccessInfo}
              isLoading={Boolean(isOrgAccessLoading)}
              refreshData={handleRefreshDataData}
              isDisplayExtendCaseView={isMiniTable}
            />
          )}
        </Grid>

        {/* Cases Table */}
        <CasesTable isMiniTable={isMiniTable} activeTab={activeTab} filters={filters} />
      </Grid>
    </Box>
  )
}

export default SupportServiceCaseTable
