import { FC, useEffect, useMemo, useState } from 'react'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _merge from 'lodash/merge'

import { SCHEMA_YUP, handleHashedPIN } from '@myopswat/common'
import { EyeHideIcon, EyeIcon } from '@opswat/react-icon'
import { Box, ButtonLoading, Grid, TextField, TextGrid, Typography, TypographyDivider } from '@opswat/react-ui'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  useEditPINMutation,
  useForgotPINMutation,
  useLazyConfirmPINQuery,
  useLazyGetUsersProfileQuery,
  useReSetupPINMutation,
  useSetupPINMutation
} from 'myopswat-web/src/api/pin'
import { IEditPINInput, IReSetupPINInput, ISetupPINInput } from 'myopswat-web/src/api/pin/types'
import { myInformationSecurityPageURL } from 'myopswat-web/src/routes'

interface IForm {
  hashedCurrentPin?: string
  hashedNewPin: string
  reHashedNewPin: string
}

const SecurityPIN: FC<unknown> = () => {
  const { t: translate } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const pinParams = searchParams.get('pinResetToken') || ''

  // API
  const [getUsersProfile, { data: getUsersProfileData, isFetching: isFetchGetUsersProfile }] =
    useLazyGetUsersProfileQuery()
  const [setupPIN, { isLoading: isLoadingSetupPIN }] = useSetupPINMutation()
  const [editPIN, { isLoading: isLoadingEditPIN }] = useEditPINMutation()
  const [forgotPIN, { isLoading: isLoadingForgotPIN }] = useForgotPINMutation()
  const [confirmPIN] = useLazyConfirmPINQuery()
  const [reSetupPIN, { isLoading: isLoadingReSetupPIN }] = useReSetupPINMutation()

  const handleSetupPIN = async (payload: ISetupPINInput) => {
    try {
      await setupPIN(payload)
        .unwrap()
        .then(() => {
          enqueueSnackbar(translate('setupPINSuccess'), {
            variant: 'success'
          })
          getUsersProfile()
        })
        .catch(() => {
          enqueueSnackbar(translate('setupPINFail'), {
            variant: 'error'
          })
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditPIN = async (payload: IEditPINInput) => {
    try {
      await editPIN(payload)
        .unwrap()
        .then(() => {
          enqueueSnackbar(translate('editPINSuccess'), {
            variant: 'success'
          })
        })
        .catch((error: any) => {
          enqueueSnackbar(_get(error, 'data.error', translate('editPINFail')), {
            variant: 'error'
          })
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleForgotPIN = async () => {
    try {
      await forgotPIN()
        .unwrap()
        .then(() => {
          enqueueSnackbar(translate('forgotPINSuccess'), {
            variant: 'success'
          })
        })
        .catch(() => {
          enqueueSnackbar(translate('forgotPINFail'), {
            variant: 'error'
          })
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleReSetupPIN = async (payload: IReSetupPINInput) => {
    try {
      await reSetupPIN(payload)
        .unwrap()
        .then(() => {
          enqueueSnackbar(translate('editPINSuccess'), {
            variant: 'success'
          })
          navigate(myInformationSecurityPageURL)
        })
        .catch(() => {
          enqueueSnackbar(translate('editPINFail'), {
            variant: 'error'
          })
        })
    } catch (error) {
      console.error(error)
    }
  }

  // UI
  const [isSetupPIN, setIsSetupPIN] = useState<boolean>(false)

  const [showOldPIN, setShowOldPIN] = useState<boolean>(false)
  const handleClickShowOldPIN = () => setShowOldPIN(showOldPIN => !showOldPIN)

  const [showPIN, setShowPIN] = useState<boolean>(false)
  const handleClickShowPIN = () => setShowPIN(show => !show)

  const [showRePIN, setShowRePIN] = useState<boolean>(false)
  const handleClickShowRePIN = () => setShowRePIN(showRePIN => !showRePIN)

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  // FORM
  const defaultValues = {
    hashedCurrentPin: '',
    hashedNewPin: '',
    reHashedNewPin: ''
  }

  const isCheckYUP = useMemo(() => {
    if (pinParams) return false
    if (isSetupPIN) return false
    return true
  }, [isSetupPIN, pinParams])

  const schema = yup
    .object()
    .shape(
      _merge(
        {},
        ...[SCHEMA_YUP.hashedNewPin, SCHEMA_YUP.reHashedNewPin],
        ...((isCheckYUP && [SCHEMA_YUP.hashedCurrentPin]) || [])
      )
    )

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
    trigger,
    getValues
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onChange'
  })

  const onSuccess = async (data: IForm) => {
    if (pinParams) {
      handleReSetupPIN({
        hashedPin: handleHashedPIN(data.hashedNewPin),
        token: pinParams
      })
      reset()
    }

    if (!pinParams && data.hashedCurrentPin) {
      handleEditPIN({
        hashedCurrentPin: handleHashedPIN(data.hashedCurrentPin),
        hashedNewPin: handleHashedPIN(data.hashedNewPin)
      })
      reset()
    }

    if (!pinParams && !data.hashedCurrentPin) {
      handleSetupPIN({
        hashedPin: handleHashedPIN(data.hashedNewPin)
      })
      reset()
      setIsSetupPIN(false)
    }
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  // LISTEN
  const handleRenderUI = () => {
    if (pinParams || isSetupPIN)
      return (
        <>
          <Grid item xs={12}>
            <TextGrid
              label={<Typography variant="subtitle1">New PIN</Typography>}
              value={
                <Controller
                  name="hashedNewPin"
                  control={control}
                  render={(cProps: any) => (
                    <TextField
                      size="small"
                      fullWidth
                      type={showPIN ? 'text' : 'password'}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <Box
                            onClick={handleClickShowPIN}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                          >
                            {showPIN ? <EyeIcon /> : <EyeHideIcon />}
                          </Box>
                        )
                      }}
                      error={!!_get(errors, 'hashedNewPin', '')}
                      helperText={_get(errors, 'hashedNewPin.message', '')}
                      required
                      value={cProps.field.value}
                      onChange={(e: any) => {
                        cProps.field.onChange(e.target.value)
                        if (!_isEmpty(getValues('reHashedNewPin'))) {
                          trigger()
                        }
                      }}
                      onKeyUp={(e: any) => {
                        if (_get(e, 'keyCode') === 13) {
                          handleSubmit(onSuccess, onFail)()
                        }
                        trigger('hashedNewPin')
                      }}
                    />
                  )}
                />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextGrid
              label={<Typography variant="subtitle1">Confirm PIN</Typography>}
              value={
                <Controller
                  name="reHashedNewPin"
                  control={control}
                  render={(cProps: any) => (
                    <TextField
                      size="small"
                      fullWidth
                      type={showRePIN ? 'text' : 'password'}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <Box
                            onClick={handleClickShowRePIN}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                          >
                            {showRePIN ? <EyeIcon /> : <EyeHideIcon />}
                          </Box>
                        )
                      }}
                      error={!!_get(errors, 'reHashedNewPin', '')}
                      helperText={_get(errors, 'reHashedNewPin.message', '')}
                      required
                      value={cProps.field.value}
                      onChange={(e: any) => cProps.field.onChange(e.target.value)}
                      onKeyUp={(e: any) => {
                        if (_get(e, 'keyCode') === 13) {
                          handleSubmit(onSuccess, onFail)()
                        }
                        trigger('reHashedNewPin')
                      }}
                    />
                  )}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit(onSuccess, onFail),
                disabled: !isDirty || isLoadingSubmitPIN || !isValid
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoadingSubmitPIN}
            >
              Submit
            </ButtonLoading>
          </Grid>
        </>
      )

    return (
      <>
        <Grid item xs={12}>
          <TextGrid
            label={<Typography variant="subtitle1">Current PIN</Typography>}
            value={
              <Controller
                name="hashedCurrentPin"
                control={control}
                render={(cProps: any) => (
                  <TextField
                    size="small"
                    fullWidth
                    type={showOldPIN ? 'text' : 'password'}
                    InputProps={{
                      autoComplete: 'off',
                      endAdornment: (
                        <Box
                          onClick={handleClickShowOldPIN}
                          onMouseDown={handleMouseDownPassword}
                          sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                        >
                          {showOldPIN ? <EyeIcon /> : <EyeHideIcon />}
                        </Box>
                      )
                    }}
                    error={!!_get(errors, 'hashedCurrentPin', '')}
                    helperText={_get(errors, 'hashedCurrentPin.message', '')}
                    required
                    value={cProps.field.value}
                    onChange={(e: any) => {
                      cProps.field.onChange(e.target.value)
                      if (!_isEmpty(getValues('hashedNewPin'))) {
                        trigger('hashedNewPin')
                      }
                    }}
                    onKeyUp={(e: any) => {
                      if (_get(e, 'keyCode') === 13) {
                        handleSubmit(onSuccess, onFail)()
                      }
                      trigger('hashedCurrentPin')
                    }}
                  />
                )}
              />
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextGrid
            label={<Typography variant="subtitle1">New PIN</Typography>}
            value={
              <Controller
                name="hashedNewPin"
                control={control}
                render={(cProps: any) => (
                  <TextField
                    size="small"
                    fullWidth
                    type={showPIN ? 'text' : 'password'}
                    InputProps={{
                      autoComplete: 'off',
                      endAdornment: (
                        <Box
                          onClick={handleClickShowPIN}
                          onMouseDown={handleMouseDownPassword}
                          sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                        >
                          {showPIN ? <EyeIcon /> : <EyeHideIcon />}
                        </Box>
                      )
                    }}
                    error={!!_get(errors, 'hashedNewPin', '')}
                    helperText={_get(errors, 'hashedNewPin.message', '')}
                    required
                    value={cProps.field.value}
                    onChange={(e: any) => {
                      cProps.field.onChange(e.target.value)
                      if (!_isEmpty(getValues('reHashedNewPin')) && !_isEmpty(e.target.value)) {
                        trigger('reHashedNewPin')
                      }
                    }}
                    onKeyUp={(e: any) => {
                      if (_get(e, 'keyCode') === 13) {
                        handleSubmit(onSuccess, onFail)()
                      }
                      trigger('hashedNewPin')
                    }}
                  />
                )}
              />
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextGrid
            label={<Typography variant="subtitle1">Confirm PIN</Typography>}
            value={
              <Controller
                name="reHashedNewPin"
                control={control}
                render={(cProps: any) => (
                  <TextField
                    size="small"
                    fullWidth
                    type={showRePIN ? 'text' : 'password'}
                    InputProps={{
                      autoComplete: 'off',
                      endAdornment: (
                        <Box
                          onClick={handleClickShowRePIN}
                          onMouseDown={handleMouseDownPassword}
                          sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                        >
                          {showRePIN ? <EyeIcon /> : <EyeHideIcon />}
                        </Box>
                      )
                    }}
                    error={!!_get(errors, 'reHashedNewPin', '')}
                    helperText={_get(errors, 'reHashedNewPin.message', '')}
                    required
                    value={cProps.field.value}
                    onChange={(e: any) => cProps.field.onChange(e.target.value)}
                    onKeyUp={(e: any) => {
                      if (_get(e, 'keyCode') === 13) {
                        handleSubmit(onSuccess, onFail)()
                      }
                      trigger('reHashedNewPin')
                    }}
                  />
                )}
              />
            }
          />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <ButtonLoading
            propsButton={{
              variant: isLoadingForgotPIN ? 'contained' : 'text',
              color: 'inherit',
              onClick: () => {
                handleForgotPIN()
              },
              disabled: isLoadingForgotPIN,
              sx: { textDecoration: 'underline', mr: 2 }
            }}
            propsLoading={{ color: 'inherit' }}
            isLoading={isLoadingForgotPIN}
          >
            Forgot PIN
          </ButtonLoading>

          <ButtonLoading
            propsButton={{
              variant: 'contained',
              color: 'primary',
              onClick: handleSubmit(onSuccess, onFail),
              disabled: !isDirty || isLoadingSubmitPIN || !isValid
            }}
            propsLoading={{ color: 'inherit' }}
            isLoading={isLoadingSubmitPIN}
          >
            Submit
          </ButtonLoading>
        </Grid>
      </>
    )
  }

  const isLoadingSubmitPIN = useMemo(() => {
    return isLoadingSetupPIN || isLoadingEditPIN || isLoadingReSetupPIN
  }, [isLoadingSetupPIN, isLoadingEditPIN, isLoadingReSetupPIN])

  useEffect(() => {
    getUsersProfile()
  }, [])

  useEffect(() => {
    if (pinParams) {
      confirmPIN(pinParams)
        .unwrap()
        .then((data: any) => {
          if (data) {
            enqueueSnackbar(translate('confirmPINSuccess'), {
              variant: 'success'
            })
          } else {
            navigate(myInformationSecurityPageURL)
            enqueueSnackbar(translate('confirmPINFail'), {
              variant: 'error'
            })
          }
        })
        .catch(() => {
          navigate(myInformationSecurityPageURL)
          enqueueSnackbar(translate('confirmPINFail'), {
            variant: 'error'
          })
        })
    }
  }, [pinParams])

  if (isFetchGetUsersProfile)
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TypographyDivider label="PIN" propsTypo={{ variant: 'body1' }} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography variant="body2" paragraph>
                  Your PIN hasn't been activated yet.
                </Typography>
                <Typography variant="body2">
                  The PIN is used to confirm changes to sensitive functions. This adds an additional layer of security
                  in-product.
                </Typography>
              </Grid>

              <Grid item xs="auto">
                <ButtonLoading
                  propsButton={{
                    variant: 'contained',
                    color: 'primary',
                    disabled: true
                  }}
                  propsLoading={{ color: 'inherit' }}
                >
                  Setup PIN
                </ButtonLoading>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )

  if (getUsersProfileData?.isPinSet || isSetupPIN)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TypographyDivider label="PIN" propsTypo={{ variant: 'body1' }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            The PIN is used to confirm changes to sensitive functions. This adds an additional layer of security
            in-product.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {handleRenderUI()}
          </Grid>
        </Grid>
      </Grid>
    )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TypographyDivider label="PIN" propsTypo={{ variant: 'body1' }} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography variant="body2" paragraph>
              Your PIN hasn't been activated yet.
            </Typography>
            <Typography variant="body2">
              The PIN is used to confirm changes to sensitive functions. This adds an additional layer of security
              in-product.
            </Typography>
          </Grid>

          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: () => {
                  setIsSetupPIN(true)
                }
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoadingSubmitPIN}
            >
              Setup PIN
            </ButtonLoading>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SecurityPIN
