import _debounce from 'lodash/debounce'
import { useCallback } from 'react'

export const useGenericDebouncedHandler = <T>(
  onSearch: (data: T) => void,
  getValues: () => T,
  setValue: (field: keyof T, value: any) => void,
  debounceDelay = 300
) => {
  const debouncedOnSearch = useCallback(
    _debounce((searchData: T) => onSearch(searchData), debounceDelay),
    [onSearch, debounceDelay]
  )

  const updateAndSearch = useCallback(
    (field: keyof T, value: any) => {
      const updatedValues = { ...getValues(), [field]: value }
      setValue(field, value)
      debouncedOnSearch(updatedValues)
    },
    [debouncedOnSearch, getValues, setValue]
  )

  const handleInputChange = useCallback(
    (field: keyof T) => (e: React.ChangeEvent<HTMLInputElement>) => {
      updateAndSearch(field, e.target.value)
    },
    [updateAndSearch]
  )

  const handleClearText = useCallback(
    (field: keyof T) => () => {
      updateAndSearch(field, '')
    },
    [updateAndSearch]
  )

  return { handleInputChange, handleClearText }
}
