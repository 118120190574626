import React, { FC, useEffect, useState } from 'react'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _range from 'lodash/range'

import { DIALOGS, handleClearCookie } from '@myopswat/common'
import { FeedbackIcon, SolidDownIcon, SolidRightIcon, UserIcon } from '@opswat/react-icon'
import {
  Box,
  BoxAdvanced,
  Button,
  Collapse,
  Divider,
  Dropdown,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Tooltip,
  Typography,
  TypographyLineClamp,
  TypographyLink,
  useTheme
} from '@opswat/react-ui'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { useLazyOrganizationsQuery, useOrganizationUpdateUsingMutation } from 'myopswat-web/src/api/organization'
import { useLazySaml2LogoutQuery } from 'myopswat-web/src/api/sso'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import {
  saveFirstLogin,
  saveOrganizations,
  selectIsFirstLogin,
  toggleDialogs
} from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useSubmitCaseBlocker } from 'myopswat-web/src/hooks/useSubmitCaseBlocker'
import { mfaPageURL, myInformationPersonalInfoPageURL } from 'myopswat-web/src/routes'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

import NotificationRoot from './NotificationRoot'

const RightMenu: FC<unknown> = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const { t: translate } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { handleCheckSubmitCase } = useSubmitCaseBlocker()

  const loadingNotificationArray = _range(3)
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isFirstLogin = useTypedSelector(selectIsFirstLogin)

  // TODO: Organizations
  const [openSwitchOrg, setOpenSwitchOrg] = useState<boolean>(true)
  const [switchOrg, setSwitchOrg] = useState<string>('')
  const [openAccountDropdown, setOpenAccountDropdown] = useState<boolean>(true)

  const [organizationUpdateUsing, { data: organizationUpdateUsingData, error: organizationUpdateUsingError }] =
    useOrganizationUpdateUsingMutation()
  const [getOrganizations, { data: organizationsData, isFetching: isFetchingGetOrganizations }] =
    useLazyOrganizationsQuery()

  useEffect(() => {
    if (_get(organizationUpdateUsingData, 'success', false)) {
      window.location.reload()
    }
    if (
      _get(organizationUpdateUsingData, 'errors.0.message') ||
      (_get(organizationUpdateUsingError, 'message') &&
        _get(organizationUpdateUsingError, 'message') !== 'Network request failed')
    ) {
      const SWITCH_ORGANIZATION_ERRORS = {
        organization_mfa_required: `The organization "${switchOrg}" requires Multi-Factor Authentication (MFA). Please set up MFA to able to switch to that organization`,
        organization_user_not_found: `Switching Organization has failed. Please try to refresh the page and try again.`,
        organization_not_found: `Switching Organization has failed. The organization was removed.`
      }
      const errorCode = _get(organizationUpdateUsingData, 'errors.0.code', '')
      const showMessage = _get(SWITCH_ORGANIZATION_ERRORS, `${errorCode}`, translate('switchOrgFail'))

      enqueueSnackbar(
        <>
          {errorCode === 'organization_mfa_required' ? (
            <>
              {showMessage}. <TypographyLink onClick={() => navigate(mfaPageURL)}>Setup MFA.</TypographyLink>
            </>
          ) : (
            <>{showMessage}</>
          )}
        </>,
        {
          variant: 'error'
        }
      )
    }
  }, [organizationUpdateUsingData, organizationUpdateUsingError])

  const [saml2Logout] = useLazySaml2LogoutQuery()
  const handleLogout = async () => {
    try {
      const response = await saml2Logout().unwrap()
      await handleClearCookie()
      window.location.href = response.url
    } catch (error) {
      console.error(error)
    }
  }

  const handleGetOrganizations = () => {
    getOrganizations()
      .unwrap()
      .then((data: any) => {
        dispatch(saveOrganizations(data))
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    if (!isFirstLogin) {
      dispatch(saveFirstLogin(true))
      handleGetOrganizations()
    }
  }, [])

  return (
    <Grid container>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs />

        <Grid item xs="auto">
          <Tooltip
            arrow
            title={'Provide Feedback'}
            componentsProps={{
              tooltip: {
                sx: {
                  color: '#1B273C',
                  backgroundColor: '#E9EAEB'
                }
              },
              arrow: {
                sx: {
                  color: '#E9EAEB'
                }
              }
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                dispatch(
                  toggleDialogs({
                    [DIALOGS_WEB.NPS_SURVEY]: true,
                    [DIALOGS_WEB.NPS_SURVEY_ORIGIN]: 'left-menu',
                    [DIALOGS_WEB.NPS_NOTIFICATION]: false
                  })
                )
              }}
            >
              <FeedbackIcon size={18} />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs="auto">
          <Tooltip
            arrow
            title={'Notifications'}
            componentsProps={{
              tooltip: {
                sx: {
                  color: '#1B273C',
                  backgroundColor: '#E9EAEB'
                }
              },
              arrow: {
                sx: {
                  color: '#E9EAEB'
                }
              }
            }}
          >
            <span>
              <NotificationRoot />
            </span>
          </Tooltip>
        </Grid>

        <Grid item xs="auto">
          <Tooltip
            arrow
            title={!openAccountDropdown && 'Account'}
            componentsProps={{
              tooltip: {
                sx: {
                  color: '#1B273C',
                  backgroundColor: '#E9EAEB'
                }
              },
              arrow: {
                sx: {
                  color: '#E9EAEB'
                }
              }
            }}
          >
            <span>
              <Dropdown
                handleOnOpenClose={(open: boolean) => {
                  setOpenAccountDropdown(open)
                }}
                button={
                  <Button
                    color="inherit"
                    variant="text"
                    startIcon={<UserIcon />}
                    endIcon={<SolidDownIcon />}
                    sx={{
                      maxWidth: '250px'
                    }}
                    onClick={() => {
                      handleGetOrganizations()
                    }}
                  >
                    <TypographyLineClamp
                      line={1}
                      sx={{
                        wordBreak: 'break-all !important'
                      }}
                    >{`${_get(profileData, 'firstName', '')} ${_get(
                      profileData,
                      'lastName',
                      ''
                    )}`}</TypographyLineClamp>
                  </Button>
                }
                content={
                  <List disablePadding>
                    <ListItem disablePadding disableGutters>
                      <ListItemButton sx={{ display: 'block' }}>
                        {_get(profileData, 'email', '') ? (
                          <>
                            <Tooltip
                              title={`${_get(profileData, 'firstName', '')} ${_get(profileData, 'lastName', '')}`}
                              arrow
                            >
                              <Box>
                                <TypographyLineClamp
                                  line={1}
                                  sx={{
                                    textTransform: 'capitalize !important'
                                  }}
                                >{`${_get(profileData, 'firstName', '')} ${_get(
                                  profileData,
                                  'lastName',
                                  ''
                                )}`}</TypographyLineClamp>
                              </Box>
                            </Tooltip>
                            <Tooltip title={_get(profileData, 'email')} arrow>
                              <Box>
                                <TypographyLineClamp line={1} variant="caption" color="textSecondary">
                                  {_get(profileData, 'email') || ''}
                                </TypographyLineClamp>
                              </Box>
                            </Tooltip>
                          </>
                        ) : (
                          <Skeleton animation="wave" variant="text" width="100%" />
                        )}
                      </ListItemButton>
                    </ListItem>
                    <Divider sx={{ width: '90%', margin: '0 auto' }} />
                    <ListItem
                      disablePadding
                      disableGutters
                      component={Link}
                      to={myInformationPersonalInfoPageURL}
                      onClick={e => {
                        e.preventDefault()
                        handleCheckSubmitCase(() => navigate(myInformationPersonalInfoPageURL))
                      }}
                    >
                      <ListItemButton>
                        <Typography variant="subtitle2">My Information</Typography>
                      </ListItemButton>
                    </ListItem>

                    {!_isEmpty(organizationsData) && (
                      <>
                        <Divider sx={{ width: '90%', margin: '0 auto' }} />

                        <ListItem
                          disablePadding
                          disableGutters
                          onClick={e => {
                            e.stopPropagation()
                            setOpenSwitchOrg(!openSwitchOrg)
                            setSwitchOrg('')
                          }}
                        >
                          <ListItemButton>
                            <ListItemText primary={<Typography variant="subtitle2">Switch Organizations</Typography>} />
                            <Box mb={-1}>{openSwitchOrg ? <SolidDownIcon /> : <SolidRightIcon />}</Box>
                          </ListItemButton>
                        </ListItem>

                        <Collapse in={openSwitchOrg} timeout="auto" unmountOnExit>
                          <BoxAdvanced maxHeight="120px">
                            {isFetchingGetOrganizations ? (
                              <>
                                {_map(loadingNotificationArray, (item, idx) => (
                                  <Box px={2} key={idx.toString()}>
                                    <Skeleton animation="wave" variant="text" width="100%" />
                                  </Box>
                                ))}
                              </>
                            ) : (
                              <>
                                {_map(organizationsData, (item, idx) => (
                                  <ListItem
                                    disablePadding
                                    disableGutters
                                    onClick={e => {
                                      if (item?.id !== _get(profileData, 'currentOrganizationId')) {
                                        organizationUpdateUsing(item?.id)
                                        setSwitchOrg(item?.name || '')
                                        window.history.replaceState({}, '')
                                      }
                                      e.stopPropagation()
                                    }}
                                    key={idx.toString()}
                                    sx={
                                      item?.id === _get(profileData, 'currentOrganizationId')
                                        ? {
                                            borderLeft: `4px solid ${theme.palette.primary.main}`
                                          }
                                        : {}
                                    }
                                  >
                                    <ListItemButton>
                                      <Tooltip title={item?.name} arrow>
                                        <Box>
                                          <TypographyLineClamp line={1} variant="subtitle2">
                                            {item?.name}
                                          </TypographyLineClamp>
                                        </Box>
                                      </Tooltip>
                                    </ListItemButton>
                                  </ListItem>
                                ))}
                              </>
                            )}
                          </BoxAdvanced>
                        </Collapse>
                        <Divider sx={{ width: '90%', margin: '0 auto' }} />
                      </>
                    )}

                    <ListItem disablePadding disableGutters>
                      <ListItemButton
                        onClick={() => {
                          dispatch(
                            toggleDialogs({
                              [DIALOGS.ABOUT_PRODUCT]: true
                            })
                          )
                        }}
                      >
                        <Typography variant="subtitle2">About My OPSWAT</Typography>
                      </ListItemButton>
                    </ListItem>

                    <Divider sx={{ width: '90%', margin: '0 auto' }} />
                    <ListItem disablePadding disableGutters>
                      <ListItemButton onClick={handleLogout}>
                        <Typography variant="subtitle2">Sign out</Typography>
                      </ListItemButton>
                    </ListItem>
                  </List>
                }
                propsPaper={{ sx: { width: '220px' } }}
              />
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(RightMenu)
