import { useCallback, useState } from 'react'

export const useTextField = (initialValue = '') => {
  const [value, setValue] = useState(initialValue)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value)
    },
    [setValue]
  )

  const handleClearText = useCallback(() => {
    setValue('')
  }, [setValue])

  return {
    value,
    handleChange,
    handleClearText
  }
}
